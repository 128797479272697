/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import CajaCuentaService from "../../api/services/cajacuentaService";
import MovimientosService from "../../api/services/movimientosService";
import OperadoresService from "../../api/services/operadoresService";
import CuentasService from "../../api/services/cuentasServices";
import MontosService from "../../api/services/montosService";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import Select from "react-select";
import moment from "moment/moment";
//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Eliminar</strong>
    </Tooltip>
);
/* DATA ESTATICA */
const today = new Date();
const tipos = [
    {
        text: "Inicio de Mes",
        value: 0,
        motivos: [
            {
                text: "Sin Motivos",
                value: 0,
            },
        ],
    },
    {
        text: "Ingreso",
        value: 1,
        motivos: [
            {
                text: "Inyeccion De Capital",
                value: "Inyeccion De Capital",
            },
            {
                text: "Otros",
                value: "Otros",
            },
        ],
    },
    {
        text: "Egreso",
        value: 2,
        motivos: [
            {
                text: "Planilla",
                value: "Planilla",
            },
            {
                text: "Utiles De Oficina",
                value: "Utiles De Oficina",
            },
            {
                text: "Productos De Limpieza",
                value: "Productos De Limpieza",
            },
            {
                text: "Servicio De Internet",
                value: "Servicio De Internet",
            },
            {
                text: "Servicio De Luz",
                value: "Servicio De Luz",
            },
            {
                text: "Servio De Agua",
                value: "Servio De Agua",
            },
            {
                text: "Servicio De Telefonia",
                value: "Servicio De Telefonia",
            },
            {
                text: "Pago De Prestamo",
                value: "Pago De Prestamo",
            },
            {
                text: "Comisiones",
                value: "Comisiones",
            },
            {
                text: "ITF",
                value: "ITF",
            },
            {
                text: "Otros",
                value: "Otros",
            },
        ],
    },
];
/* FIN */
const CajaCuenta = () => {
    /* -----USEFORM----*/
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        setValue: setValue2,
        formState: { errors: errors2 },
    } = useForm();

    /* -----FIN USEFORM----*/

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/caja-cuenta", name: "Caja Diaria", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_cuadrecuentasbancarias",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "fechamovimiento",
            label: "Fecha",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{moment(value).format("DD-MM-yyyy")}</span>;
                },
            },
        },
        {
            name: "ctabancaria",
            label: "Cta Bancario",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "banco",
            label: "Banco",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "Tipo",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{value == 0 ? "Inicio de Mes" : value == 1 ? "Ingreso" : "Egreso"}</span>;
                },
            },
        },
        {
            name: "monto",
            label: "DOLARES / MONEDA LOCAL",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span> {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "monto_cambio",
            label: "CONVERTIDO (DOLARES)",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>$ {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "operador",
            label: "Operador",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "casaapuesta",
            label: "Casa",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "motivo",
            label: "Motivo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "id_movimiento",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            {tableMeta.rowIndex == 0 || value > 0 ? (
                                <></>
                            ) : (
                                <OverlayTrigger placement="top" overlay={tooltipE}>
                                    <button className="btn btn-success btn-sm btn-icon" onClick={() => editMovimiento(tableMeta)}>
                                        <FaPencilAlt></FaPencilAlt>
                                    </button>
                                </OverlayTrigger>
                            )}
                            {(tableMeta.currentTableData.length > 1 && tableMeta.rowIndex == 0) || value > 0 ? (
                                <></>
                            ) : (
                                <OverlayTrigger placement="top" overlay={tooltipP}>
                                    <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleMyState(tableMeta)}>
                                        <FaTrash></FaTrash>
                                    </button>
                                </OverlayTrigger>
                            )}
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const { user } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [cuentasBancarias, setCuentasBancarias] = useState([]);
    const [cuentasUsuario, setCuentasUsuario] = useState([]);
    const [operadores, setOperadores] = useState([]);
    const [motivos, setMotivos] = useState([]);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectType, setSelectType] = useState(0);
    const [selectMot, setSelectMot] = useState(0);
    const [selectMot2, setSelectMot2] = useState(0);
    const [filtros, setFiltros] = useState({
        mes: moment(today).format("MM"),
        anio: moment(today).format("yyyy"),
    });
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const selectData = async () => {
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respCB = await CuentasService.selectCuentasBancarias(body);
        // console.log("respCB", respCB);
        if (respCB.status) {
            if (respCB.data[0] != null) {
                setCuentasBancarias(respCB.data[0]);
            }
        }

        // const respCC = await CuentasService.getCasasCuentas(body);
        // console.log("respCC", respCC);
        // if (respCC.status) {
        //   let datos = respCC.data[0]
        //   datos.unshift({
        //     nom_titular: "Sin Definir",
        //     id_casacuenta: 0
        //   })
        //   setCuentasUsuario(respCC.data[0]);
        // }

        const respOpe = await OperadoresService.getOperadores(body);
        // console.log("respOpe", respOpe);
        if (respOpe.status) {
            if (respOpe.data[0] != null) {
                setOperadores(respOpe.data[0]);
            }
        }
        changeType(0);
    };
    const searchCasas = async (id) => {
        const body = {
            idoperador: id,
            fecha: fechaM,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("body", body);
        const respData = await CuentasService.selectCasasCuentas(body);
        // console.log("respData", respData);
        if (respData.status) {
            if (respData.data[0].length > 0) {
                setCuentasUsuario(respData.data[0]);
            } else {
                setValue("idcasaapuesta", 0);
                setValue2("idcasaapuesta", 0);
                setCuentasUsuario([]);
            }
        }
    };
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            mes: filtros.mes,
            anio: filtros.anio,
        };
        // console.log("body", body);
        const respCajas = await CajaCuentaService.getCajasCuentas(body);
        // console.log("respCajas", respCajas);
        if (respCajas.status) {
            let tot = 0;
            setItems(respCajas.data[0]);
            for (let index = 0; index < respCajas.data[0].length; index++) {
                const element = respCajas.data[0][index];
                tot += parseFloat(element.monto_cambio);
            }
            setTotal(tot);
        }
        // console.log("respCajas", respCajas);
        setLoading(false);
    };
    //EDITAR DATA
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosMov = {
            idcuadrecuentas: data.rowData[0],
        };
        // console.log("datosMov", datosMov);
        Swal.fire({
            icon: "warning",
            title: `¿Seguro que desea eliminar esta caja cuenta?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosMov);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idcuadrecuentas: data.idcuadrecuentas,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await CajaCuentaService.statusCajaCuenta(body);
        // console.log("resp", resp);
        if (resp.status) {
            Swal.fire("Eliminado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    //EDITAR CUENTA
    const editMovimiento = async (data) => {
        setShow2(false);
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        const cuentabank =
            itemSelectE.ctabancaria != null ? cuentasBancarias.filter((e) => e.nom_titular == itemSelectE.ctabancaria) : null;

        // const cuentaCasa = itemSelectE.casaapuesta != null ? cuentasUsuario.filter((e) => e.nom_titular == itemSelectE.casaapuesta) : null;
        // const ope = itemSelectE.operador != null ? operadores.filter((e) => e.nombres + " " + e.apellidos == itemSelectE.operador) : null;
        setSelectType(itemSelectE.tipo);
        setValue2("tipo", itemSelectE.tipo);
        var motivito = null;
        motivito = tipos.filter((e) => e.value == itemSelectE.tipo);
        if (motivito != null) {
            const moti = tipos.filter((e) => e.value == parseInt(itemSelectE.tipo));
            if (moti.length > 0) {
                setMotivos(moti[0].motivos);
                await new Promise((r) => setTimeout(r, 200));
                if (itemSelectE.motivo.includes("Otros")) {
                    const separados = itemSelectE.motivo.split("-");
                    setValue2("motivo1", separados[0].trim());
                    setSelectMot(separados[0].trim());
                    setShow2(true);
                    setValue2("motivo2", separados[1].trim());
                } else {
                    const motivoConver = itemSelectE.motivo.replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase());
                    setValue2("motivo1", motivoConver);
                    setSelectMot(motivoConver);
                }
            }
        }
        setValue2("id_cuadrecuentasbancarias", itemSelectE.id_cuadrecuentasbancarias);

        setValue2("fechaM", itemSelectE.fechamovimiento);
        setValue2("idoperador", itemSelectE.idoperador != null ? itemSelectE.idoperador : 0);
        if (itemSelectE.idoperador != null) {
            searchCasas(itemSelectE.idoperador);
        }
        setValue2("tipomoneda", itemSelectE.tipomoneda);
        setValue2("idcuentabancaria", cuentabank != null ? (cuentabank.length > 0 ? cuentabank[0].id_cuentabancarias : null) : null);
        setValue2("idcasaapuesta", itemSelectE.idcasaapuesta != null ? itemSelectE.idcasaapuesta : 0);
        setValue2("monto", parseFloat(itemSelectE.monto) < 0 ? parseFloat(itemSelectE.monto) * -1 : itemSelectE.monto);

        // setValue2("idmontocuentabancaria", itemSelectE.id_montocuentabancaria);
        // if (itemSelectE.monto < 0) {
        //   setValue2("montoM", itemSelectE.monto * -1);
        // } else {
        //   setValue2("montoM", itemSelectE.monto);
        // }
        $("#modal_movs_edit_mov").modal("show");
    };
    //AGREGAR CUENTA
    const addMovimiento = () => {
        setShow(false);
        setShow2(false);
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
                setValue("idoperador", 0);
                setValue2("idoperador", 0);
                setValue2("idcasaapuesta", 0);
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        setValue("tipo", 0);
        setValue("fechaM", moment(today).format("yyyy-MM-DD"));
        setValue("idoperador", 0);
        setValue("idcuentabancaria", "");
        setValue("idcasaapuesta", 0);
        setValue("monto", "");
        changeType(0);
        $("#modal_movs_add_mov").modal("show");
    };
    //RESETEAR FORMS
    const resetForm = () => {
        setShow(false);
        reset({
            fechaM: moment(today).format("yyyy-MM-DD"),
            idoperador: "",
            idcuentabancaria: "",
            monto: "",
            tipo: 0,
            idoperador: 0,
            motivo: 0,
            idcasaapuesta: 0,
        });
    };
    const resetForm2 = () => {
        setShow2(false);
        reset2({
            fechaM: moment(today).format("yyyy-MM-DD"),
            idoperador: "",
            idcuentabancaria: "",
            monto: "",
            tipo: 0,
            idoperador: 0,
            motivo: 0,
            idcasaapuesta: 0,
        });
    };
    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        // console.log("data", data);
        // console.log("show", show);
        setLoading2(true);
        const datos = data;
        const bodyAdd = {
            idoperador: datos.idoperador,
            idcuentabancaria: datos.idcuentabancaria,
            idcasacuenta: datos.idcasaapuesta,
            tipo: datos.tipo,
            monto: parseFloat(datos.monto),
            tipomoneda: parseInt(datos.tipomoneda),
            tcambio: user.tipocambio,
            fecha: datos.fechaM,
            motivo: show ? "Otros - " + datos.motivo2 : datos.motivo1 == 0 ? "Sin Motivo" : datos.motivo1,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("bodyAdd", bodyAdd);
        const respAdd = await CajaCuentaService.addCajaCuenta(bodyAdd);
        $("#modal_movs_add_mov").modal("hide");
        // console.log("respAdd", respAdd);
        if (respAdd.status == true) {
            Swal.fire({
                title: "Registrado",
                icon: "success",
                text: "Datos registrados con éxito.",
            });
        } else if (respAdd.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: respAdd.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al registrar los datos.",
            });
        }
        setLoading2(false);
        getData();

        // resetForm();
    };
    const onSubmit2 = async (data) => {
        setLoading3(true);
        const datos = data;
        // console.log("data", data);

        const bodyUpd = {
            idcuadrecuentas: datos.id_cuadrecuentasbancarias,
            idoperador: datos.idoperador,
            idcuentabancaria: datos.idcuentabancaria,
            idcasacuenta : datos.idcasaapuesta,
            tipo: datos.tipo,
            monto: parseFloat(datos.monto),
            tipomoneda: parseInt(datos.tipomoneda),
            tcambio: user.tipocambio,
            fecha: datos.fechaM,
            motivo: show2 ? "Otros - " + datos.motivo2 : datos.motivo1 == 0 ? "Sin Motivo" : datos.motivo1,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("bodyUpd", bodyUpd);
        const respAdd = await CajaCuentaService.updateCajaCuenta(bodyUpd);
        $("#modal_movs_edit_mov").modal("hide");
        if (respAdd.status == true) {
            Swal.fire({
                title: "Actualizado!",
                icon: "success",
                text: "Datos actualizados con éxito.",
            });
        } else if (respAdd.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: respAdd.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
        setLoading3(false);
        // resetForm();
    };
    const changeType = (val) => {
        setSelectType(parseInt(val));

        const moti = tipos.filter((e) => e.value == parseInt(val));
        if (moti.length > 0) {
            setMotivos(moti[0].motivos);
        }
        // console.log("moti[0].motivos[0].value", moti[0].motivos[0].value);
        setSelectMot(moti[0].motivos[0].value);
        setValue("motivo1", moti[0].motivos[0].value);
    };
    const changeMotivo = (val) => {
        // console.log("val", val);
        setSelectMot(val);
        if (val == "Otros") {
            setShow(true);
        } else {
            setShow(false);
        }
    };
    const changeMotivo2 = (val) => {
        // console.log("val", val);
        setSelectMot(val);
        if (val == "Otros") {
            setShow2(true);
        } else {
            setShow2(false);
        }
    };
    const changeCuenta = (e) => {
        //console.log("e", e);
        if (e != null && e != "") {
            const filter = cuentasBancarias.filter((el) => el.id_cuentabancarias == e);
            // console.log("filter", filter);
            setValue("tipomoneda", filter[0].tipo);
        }
    };
    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
        resetForm();
    }, []);
    useEffect(() => {
        selectData();
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, mes: moment(dateStr).format("MM"), anio: moment(dateStr).format("yyyy") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
                setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-3 ">
                                                <label htmlFor="" className="form-label">
                                                    Fecha
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                />
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="d-flex align-content-end justify-content-xl-end justify-content-center h-100  w-100">
                                                    <button
                                                        disabled={loading}
                                                        type="button"
                                                        className="btn btn-primary mt-auto me-3"
                                                        onClick={getData}
                                                    >
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span className="indicator-label ">
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                    <button
                                                        disabled={operadores.length == 0}
                                                        type="button"
                                                        className="btn btn-success mt-auto"
                                                        onClick={addMovimiento}
                                                    >
                                                        <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex gap-3 px-4">
                                            <span className="fw-bolder fs-14">Lista de Caja Diaria</span> |{" "}
                                            <span className="fw-bolder">Total: {parseFloat(total).toFixed(2)}</span>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="modal_movs_add_mov" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="modal_movs_add_mov_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="modal_movs_add_mov_header">
                                            <h2 className="fw-bold">Agregar Caja Diaria</h2>
                                            <div
                                                id="modal_movs_add_mov_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-5 px-lg-10">
                                            <div
                                                id="modal_movs_add_mov_scroll"
                                                data-kt-scroll-dependencies="#modal_movs_add_mov_header"
                                                data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
                                            >
                                                <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
                                                    <div className="col-12 mb-1">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Fecha</span>
                                                        </label>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            placeholder="Fecha"
                                                            id="kt_datepicker_8"
                                                            value={fechaM}
                                                            {...register("fechaM", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.fechaM?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Tipo
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            {...register("tipo")}
                                                            onChange={(e) => changeType(e.target.value)}
                                                        >
                                                            {tipos.map((cuenta, index) => (
                                                                <option key={index} value={cuenta.value}>
                                                                    {cuenta.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Motivo
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            {...register("motivo1")}
                                                            onChange={(e) => changeMotivo(e.target.value)}
                                                        >
                                                            {motivos.map((cuenta, index) => (
                                                                <option key={index} value={cuenta.value}>
                                                                    {cuenta.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Cuenta Bancaria
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            {...register("idcuentabancaria", {
                                                                required: true,
                                                            })}
                                                            onClick={(e) => changeCuenta(e.target.value)}
                                                        >
                                                            <option value="" defaultChecked disabled="disabled">
                                                                Seleccionar
                                                            </option>
                                                            {cuentasBancarias.map((cuenta) => (
                                                                <option key={cuenta.id_cuentabancarias} value={cuenta.id_cuentabancarias}>
                                                                    {cuenta.cuenta}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.idcuentabancaria?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    {selectType == 0 ||
                                                    (selectType == 1 && (selectMot == "Inyeccion De Capital" || selectMot == "Otros")) ||
                                                    (selectType == 2 && selectMot != "Falta De Capital") ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div className="col-12">
                                                                <label htmlFor="status" className="form-label">
                                                                    Operador
                                                                </label>
                                                                <select
                                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                                    name="status"
                                                                    id="status"
                                                                    {...register("idoperador")}
                                                                    onChange={(e) => searchCasas(e.target.value)}
                                                                >
                                                                    <option value="0" defaultChecked disabled="disabled">
                                                                        Seleccionar
                                                                    </option>
                                                                    {operadores.map((operador) => (
                                                                        <option key={operador.id_persona} value={operador.id_persona}>
                                                                            {operador.nombres} {operador.apellidos}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.idoperador?.type === "required" && (
                                                                    <p className="text-danger">El campo es requerido</p>
                                                                )}
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <label htmlFor="status" className="form-label">
                                                                    Casa Cuenta
                                                                </label>
                                                                <select
                                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                                    name="status"
                                                                    id="status"
                                                                    {...register("idcasaapuesta")}
                                                                >
                                                                    <option value="0" defaultChecked disabled="disabled">
                                                                        Seleccionar
                                                                    </option>
                                                                    {cuentasUsuario.map((cuenta) => (
                                                                        <option key={cuenta.id_casacuenta} value={cuenta.id_casacuenta}>
                                                                            {cuenta.nombre}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.idcasaapuesta?.type === "required" && (
                                                                    <p className="text-danger">El campo es requerido</p>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    {show ? (
                                                        <div className="col-12 mb-1">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="required">Otro Motivo</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Escriba.."
                                                                name="motivo2"
                                                                {...register("motivo2")}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className="col-12 mb-1">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="required">Monto</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Monto"
                                                            autoComplete="off"
                                                            name="monto"
                                                            onKeyDown={(e) => montoValidate(e)}
                                                            {...register("monto", {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                validate: (value, formValues) => parseFloat(value) > 0,
                                                            })}
                                                        />
                                                        {errors.monto?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                        {errors.monto?.type === "validate" && (
                                                            <p className="text-danger">Debe ser mayor a 0</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="modal_movs_add_mov_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
                                                {loading2 ? (
                                                    <span className="indicator-label">
                                                        Agregando....
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">Agregar</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="modal_movs_edit_mov" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="modal_movs_edit_mov_form"
                                        onSubmit={handleSubmit2(onSubmit2)}
                                    >
                                        <div className="modal-header" id="modal_movs_edit_mov_header">
                                            <h2 className="fw-bold">Editar Movimiento</h2>
                                            <div
                                                id="modal_movs_edit_mov_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm2}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-5 px-lg-10">
                                            <div
                                                id="modal_movs_edit_mov_scroll"
                                                data-kt-scroll-dependencies="#modal_movs_edit_mov_header"
                                                data-kt-scroll-wrappers="#modal_movs_edit_mov_scroll"
                                            >
                                                <div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
                                                    <div className="col-12 mb-1">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Fecha</span>
                                                        </label>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            placeholder="Fecha"
                                                            id="kt_datepicker_8"
                                                            disabled
                                                            value={fechaM}
                                                            {...register2("fechaM", {
                                                                required: true,
                                                            })}
                                                        />
                                                        {errors.fechaM?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Tipo
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            disabled
                                                            {...register2("tipo")}
                                                            onChange={(e) => changeType(e.target.value)}
                                                        >
                                                            {tipos.map((cuenta, index) => (
                                                                <option key={index} value={cuenta.value}>
                                                                    {cuenta.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Motivo
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            disabled
                                                            {...register2("motivo1")}
                                                            onChange={(e) => changeMotivo2(e.target.value)}
                                                        >
                                                            {motivos.map((cuenta) => (
                                                                <option key={cuenta.value} value={cuenta.value}>
                                                                    {cuenta.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12 mb-1">
                                                        <label htmlFor="status" className="form-label">
                                                            Cuenta Bancaria
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            disabled
                                                            {...register2("idcuentabancaria", {
                                                                required: true,
                                                            })}
                                                        >
                                                            <option value="" defaultChecked disabled="disabled">
                                                                Seleccionar
                                                            </option>
                                                            {cuentasBancarias.map((cuenta) => (
                                                                <option key={cuenta.id_cuentabancarias} value={cuenta.id_cuentabancarias}>
                                                                    {cuenta.cuenta}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.idcuentabancaria?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    {selectType == 0 ||
                                                    (selectType == 1 && (selectMot == "Inyeccion De Capital" || selectMot == "Otros")) ||
                                                    (selectType == 2 && selectMot != "Falta De Capital") ? (
                                                        <></>
                                                    ) : (
                                                        <>
                                                            <div className="col-12">
                                                                <label htmlFor="status" className="form-label">
                                                                    Operador
                                                                </label>
                                                                <select
                                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                                    name="status"
                                                                    id="status"
                                                                    {...register2("idoperador")}
                                                                >
                                                                    <option value="0" defaultChecked disabled="disabled">
                                                                        Seleccionar
                                                                    </option>
                                                                    {operadores.map((operador) => (
                                                                        <option key={operador.id_persona} value={operador.id_persona}>
                                                                            {operador.nombres} {operador.apellidos}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.idoperador?.type === "required" && (
                                                                    <p className="text-danger">El campo es requerido</p>
                                                                )}
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <label htmlFor="status" className="form-label">
                                                                    Casa Cuenta
                                                                </label>
                                                                <select
                                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                                    name="status"
                                                                    id="status"
                                                                    {...register2("idcasaapuesta")}
                                                                >
                                                                    {cuentasUsuario.map((cuenta) => (
                                                                        <option key={cuenta.id_casacuenta} value={cuenta.id_casacuenta}>
                                                                            {cuenta.nombre}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.idcasaapuesta?.type === "required" && (
                                                                    <p className="text-danger">El campo es requerido</p>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    {show2 ? (
                                                        <div className="col-12 mb-1">
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="required">Otro Motivo</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                placeholder="Escriba.."
                                                                name="motivo2"
                                                                {...register2("motivo2")}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <div className="col-12 mb-1">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="required">Monto</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Monto"
                                                            autoComplete="off"
                                                            name="monto"
                                                            onKeyDown={(e) => montoValidate(e)}
                                                            {...register2("monto", {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                validate: (value, formValues) => parseFloat(value) > 0,
                                                            })}
                                                        />
                                                        {errors.monto?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                        {errors.monto?.type === "validate" && (
                                                            <p className="text-danger">Debe ser mayor a 0</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="modal_movs_edit_mov_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm2}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="modal_movs_edit_mov_submit" className="btn btn-primary">
                                                {loading3 ? (
                                                    <span className="indicator-label">
                                                        Editando....
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">Editar</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default CajaCuenta;
