/* eslint-disable */
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import moment from "moment/moment";
import "moment/locale/es";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Login,
    Apuestas,
    ApuestasGrafico,
    Surebets,
    SurebetsGrafico,
    Bancarias,
    Casas,
    Movimientos,
    ListarUsuarios,
    ListarUsuariosActivos,
    Programadas,
    Suspendidas,
    Planes,
    Cupones,
    Operadores,
    Franquicias,
    ConexionesOperadores,
    ConexionesUsuarios,
    AdminFranquicias,
    BankDiario,
    Perfil,
    Blog,
    Montos,
    Combinadas,
    ReporteMontos,
    CajaCuenta,
    Confirmados,
    Consolidado,
    Utilidades,
    Resumen,
    Gastos,
    Transacciones,
    Filtros
} from "../Pages";
import { useUserContext } from "../Providers/UserProvider";
const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};
const ControllerSesion = () => {
    const { user, setUser } = useUserContext();
    let timeTotal = moment.unix(user.iat).startOf("hour").fromNow();
    // const [stake, setStake] = useState(user.stake)
    // const [userTc, setUserTc] = useState(user.tipocambio)
    const { setDataPlan } = useUserContext();
    useEffect(() => {
        window.KTComponents.init();
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            toast.warning("Se encuentra operando " + timeTotal + ".Tome descansos periodicamente.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "A",
            });;
        }, 1000 * 60 * 60 * 4);
        return () => clearInterval(interval);
    }, []);
    if (user == {} || !localStorage.userCca) {
        return (
            <Routes>
                <Route path="/" element={<Login userUpLog={(datos) => setUser(datos)} />} />
                <Route path="*" element={<Login userUpLog={(datos) => setUser(datos)} />} />
                
            </Routes>
        );
    }

    return (
        <Wrapper>
            <div className="d-flex flex-column flex-root position-relative">
                <Routes>
                    <Route path="/" element={<Navigate to="/usuarios" />} />
                    <Route path="/usuarios" element={<ListarUsuarios />} />
                    <Route path="/bancarias" element={<Bancarias />}></Route>
                    <Route path="/casas" element={<Casas />}></Route>
                    <Route path="/blog" element={<Blog />}></Route>
                    <Route path="/movimientos" element={<Movimientos />}></Route>
                    <Route path="/perfil" element={<Perfil />} />
                    <Route path="/planes" element={<Planes />}></Route>
                    <Route path="/montos" element={<Montos />}></Route>
                    <Route path="/confirmados" element={<Confirmados />}></Route>
                    <Route path="/consolidado" element={<Consolidado />}></Route>
                    <Route path="/reporte-montos" element={<ReporteMontos />}></Route>
                    <Route path="/operadores" element={<Operadores />}></Route>
                    <Route path="/franquicias" element={<Franquicias />}></Route>
                    <Route path="/caja-cuenta" element={<CajaCuenta />}></Route>
                    <Route path="/bankdiario" element={<BankDiario />}></Route>
                    <Route path="/admin-franquicias" element={<AdminFranquicias />}></Route>
                    <Route path="/cupones" element={<Cupones />}></Route>
                    <Route path="/apuestas" element={<Apuestas />}></Route>
                    <Route path="/apuestas-grafico" element={<ApuestasGrafico />}></Route>
                    <Route path="/surebets" element={<Surebets />}></Route>
                    <Route path="/surebets-grafico" element={<SurebetsGrafico />}></Route>
                    <Route path="/activaciones-programadas" element={<Programadas />}></Route>
                    <Route path="/activaciones-suspendidas" element={<Suspendidas />}></Route>
                    <Route path="/combinadas" element={<Combinadas />}></Route>
                    <Route path="/gastos" element={<Gastos />}></Route>
                    <Route path="/resumen" element={<Resumen />}></Route>
                    <Route path="/utilidades" element={<Utilidades />}></Route>
                    <Route path="/conexiones-operadores" element={<ConexionesOperadores />}></Route>
                    <Route path="/conexiones-usuarios" element={<ConexionesUsuarios />}></Route>
                    <Route path="/transacciones" element={<Transacciones />}></Route>
                    <Route path="/filtros" element={<Filtros />}></Route>
                </Routes>
                <ToastContainer
                    theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                    className="container_surebets "
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.BOTTOM_RIGHT}
                />
            </div>
        </Wrapper>
    );
};

export default ControllerSesion;
