/* eslint-disable */

import UseService from "../UseServices";

const ConexionesService = {
    async getListOperadores(data) {
        var resp = await UseService.post("conexiones/operadores", data);
        return resp;
    },
    async getListUsers(data) {
        var resp = await UseService.post("conexiones/users", data);
        return resp;
    },
};
export default ConexionesService;