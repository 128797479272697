/* eslint-disable */

import UseService from "../UseServices";

const MovimientosService = {
    async getMovimientos(data) {
        var resp = await UseService.post("movimientos/list", data);
        return resp;
    },
    async addMovimiento(data) {
        var resp = await UseService.post("movimientos/add", data);
        return resp;
    },
    async updateMovimiento(data) {
        var resp = await UseService.put("movimientos/update", data);
        return resp;
    },
    async statusMovimiento(data) {
        var resp = await UseService.put("movimientos/status", data);
        return resp;
    },

    //OTRO MODULO
    async getConfirm(data) {
        var resp = await UseService.post("movimientos/listconfirm", data);
        return resp;
    },
    async saveConfirm(data) {
        var resp = await UseService.post("movimientos/confirm", data);
        return resp;
    },
    async restConfirm(data) {
        var resp = await UseService.put("movimientos/restart", data);
        return resp;
    },
};
export default MovimientosService;