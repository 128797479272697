/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import MovimientosService from "../../api/services/movimientosService";
import OperadoresService from "../../api/services/operadoresService";
import CuentasService from "../../api/services/cuentasServices";
import MontosService from "../../api/services/montosService";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import Select from "react-select";
import moment from "moment/moment";
//TOOLTIPS
const tooltipE = (
	<Tooltip id="tooltipE">
		<strong>Editar</strong>
	</Tooltip>
);
const tooltipP = (
	<Tooltip id="tooltipP">
		<strong>Eliminar</strong>
	</Tooltip>
);
/* DATA ESTATICA */
const today = new Date();
const tipos = [
	{
		text: "Todos",
		value: "Todos",
	},
	{
		text: "Recargas",
		value: "Recarga",
	},
	{
		text: "Retiros",
		value: "Retiro",
	},
];
/* FIN */
const Montos = () => {
	/* -----USEFORM----*/
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		control,
		reset,
		formState: { errors },
	} = useForm();

	const {
		register: register2,
		handleSubmit: handleSubmit2,
		reset: reset2,
		setValue: setValue2,
		formState: { errors: errors2 },
	} = useForm();

	const {
		register: register3,
		handleSubmit: handleSubmit3,
		reset: reset3,
		setValue: setValue3,
		formState: { errors: errors3 },
	} = useForm();

	/* -----FIN USEFORM----*/

	/* -----BREADCRUMBS----*/
	const crumbs = [
		{ path: "/perfil", name: "Home", active: true },
		{ path: "/montos", name: "Montos", active: true },
	];
	/* -----FIN BREADCRUMBS----*/

	/* -----MUIDATATABLE----*/
	//COLUMNAS
	const columns1 = [
		{
			name: "id_montocuentabancaria",
			label: "",
			options: {
				display: false,
			},
		},
		{
			name: "id_cuentabancarias",
			label: "",
			options: {
				display: false,
			},
		},
		{
			name: "titular",
			label: "Titular",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
			},
		},
		{
			name: "banco",
			label: "Banco",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
			},
		},
		{
			name: "tipo",
			label: "MONEDA",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<span>
							{value == 1 ? "DOLARES" : "Moneda local"}
						</span>
					);
				},
			},
		},
		{
			name: "monto",
			label: "DOLARES/MONEDA LOCAL",
			options: {
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
				customBodyRender: (value) => {
					return <span>$ {parseFloat(value).toFixed(2)}</span>;
				},
			},
		},
		{
			name: "cambio_monto",
			label: "CONVERTIDO (DOLARES)",
			options: {
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
				customBodyRender: (value) => {
					return <span>$ {parseFloat(value).toFixed(2)}</span>;
				},
			},
		},
		{
			name: "fecha",
			label: "Fecha",
			options: {
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
				customBodyRender: (value) => {
					return <span> {moment(value).format("DD-MM-yyyy")}</span>;
				},
			},
		},
		{
			name: "Acciones",
			label: "ACCIONES",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div className="d-flex gap-3 flex-wrap justify-content-center">
							<OverlayTrigger placement="top" overlay={tooltipE}>
								<button className="btn btn-sm btn-success btn-icon" onClick={() => editMovimiento(tableMeta)}>
									<FaPencilAlt></FaPencilAlt>
								</button>
							</OverlayTrigger>
							<OverlayTrigger placement="top" overlay={tooltipP}>
								<button className="btn btn-sm btn-danger btn-icon" onClick={() => handleMyState(tableMeta)}>
									<FaTrash></FaTrash>
								</button>
							</OverlayTrigger>
						</div>
					);
				},
				setCellProps: () => ({
					align: "center",
				}),
				setCellHeaderProps: (value) => ({
					className: "centeredHeaderCell",
				}),
			},
		},
	];
	const options = {
		print: false,
		viewColumns: false,
		filter: false,
		filterType: "dropdown",
		selectableRows: "none",
		responsive: "standard",
		fixedSelectColumn: false,
		download: true,
		textLabels: {
			body: {
				noMatch: "No se encontraron datos",
				toolTip: "Sort",
				columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
			},
			pagination: {
				next: "Siguiente",
				previous: "Anterior",
				rowsPerPage: "Elementos por página:",
				displayRows: "de",
			},
			toolbar: {
				search: "Buscador",
				downloadCsv: "Descargar CSV",
				print: "Imprimir",
				viewColumns: "Columnas",
				filterTable: "Filtrar Tabla",
			},
			filter: {
				all: "Todos",
				title: "Filtros",
				reset: "Resetear",
			},
			viewColumns: {
				title: "Mostrar Columnas",
				titleAria: "Mostrar/Ocultar Columnas",
			},
			selectedRows: {
				text: "fila(s) seleccionada(s)",
				delete: "Eliminar",
				deleteAria: "Eliminar fila seleccionada",
			},
			centeredTableHead: {
				"& > span": {
					justifyContent: "center",
				},
			},
		},
	};

	/* -----FIN MUIDATATABLE----*/

	/* -----HOOKS----*/
	const { user } = useUserContext();
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [loading4, setLoading4] = useState(false);
	const [cuentasBancarias, setCuentasBancarias] = useState([]);
	const [idOpe, setIdOpe] = useState([]);
	const [items, setItems] = useState([]);
	const [total, setTotal] = useState(0)
	const [cuentasUsuario, setcuentasUsuario] = useState([]);
	const [filtros, setFiltros] = useState({
		f_inicio: moment(today).format("yyyy-MM-DD"),
		f_fin: moment(today).format("yyyy-MM-DD"),
		tipo: "Todos",
	});
	const [isOpe, setIsOpe] = useState(false);
	const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
	const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
	/* -----FIN HOOKS----*/

	/* -----DATOS----*/
	const refreshToken = localStorage.getItem("refreshToken");
	const colourStyles = {
		control: (styles) => ({
			...styles,
			backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
			border: "0px",
			fontWeight: "500",
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: isSelected
					? localStorage.getItem("userTheme") === "dark"
						? "#2B2B3D"
						: "#D0D2D7"
					: isFocused
						? localStorage.getItem("userTheme") === "dark"
							? "#2B2B3D"
							: "#F4F6FA"
						: localStorage.getItem("userTheme") === "dark"
							? "#1b1b29"
							: "#f9f9f9",
				color: isSelected
					? localStorage.getItem("userTheme") === "dark"
						? "white"
						: "black"
					: localStorage.getItem("userTheme") === "dark"
						? "white"
						: "black",
				fontWeight: "500",
				cursor: "pointer",
			};
		},
		placeholder: (styles) => ({ ...styles }),
		singleValue: (styles) => ({
			...styles,
			color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
			fontWeight: "500",
		}),
		valueContainer: (styles) => ({ ...styles, padding: "0.85rem" }),
		menu: (styles) => ({
			...styles,
			backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
		}),
	};
	/* -----FIN DATOS----*/

	/* -----METODOS----*/
	//LISTAR DATA
	const selectData = async () => {
		const body = {
			refreshToken: "Bearer " + refreshToken,
		};
		const respCB = await CuentasService.selectCuentasBancarias(body);
		// console.log("respCB", respCB);
		if (respCB.status) {
			if (respCB.data[0] != null) {
				setCuentasBancarias(respCB.data[0]);
			}

		}
	};
	const getData = async () => {
		setLoading(true);
		const body = {
			refreshToken: "Bearer " + refreshToken,
			fechainicio: filtros.f_inicio,
			fechafin: filtros.f_fin,
		};
		const resMontos = await MontosService.getMontos(body);
		if (resMontos.status) {
			if (resMontos.data[0] != null) {
				let tot = 0
				setItems(resMontos.data[0]);
				for (let index = 0; index < resMontos.data[0].length; index++) {
					const element = resMontos.data[0][index];
					tot += (element.cambio_monto)
				}
				tot = parseFloat(tot).toFixed(2);
				setTotal(tot)
			}

		}
		// console.log("resMontos", resMontos);
		setLoading(false);
	};
	//EDITAR DATA
	//CAMBIAR ESTADO
	const handleMyState = (data) => {
		// console.log("data", data);
		let datosMov = {
			id_montocuentabancaria: data.rowData[0],
		};
		// console.log("datosMov", datosMov);
		Swal.fire({
			icon: "warning",
			title: `¿Seguro que desea eliminar este monto?`,
			showCancelButton: true,
			confirmButtonText: "Confirmar",
		}).then((result) => {
			if (result.isConfirmed) {
				confirmChange(datosMov);
			}
		});
	};
	//CONFIRMACIÓN DE ELIMINACIÓN
	const confirmChange = async (data) => {
		const body = {
			idmontocuenta: data.id_montocuentabancaria,
			refreshToken: "Bearer " + refreshToken,
		};
		//console.log("body", body);
		const resMov = await MontosService.statusMonto(body);
		// console.log("resMov", resMov);
		if (resMov.status) {
			Swal.fire("Eliminado!", "", "success");
		} else {
			Swal.fire({
				title: "Error!",
				icon: "error",
				text: "Ocurrió un error al actualizar los datos.",
			});
		}
		getData();
	};
	//EDITAR CUENTA
	const editMovimiento = (data) => {
		let itemSelectE = {};
		itemSelectE = items[data.rowIndex];
		// console.log('itemSelectE', itemSelectE)
		setValue2("idmontocuentabancaria", itemSelectE.id_montocuentabancaria);
		setValue2("tipo", itemSelectE.tipo)
		if (itemSelectE.monto < 0) {
			setValue2("montoM", itemSelectE.monto * -1);
		} else {
			setValue2("montoM", itemSelectE.monto);
		}
		$("#modal_movs_edit_mov").modal("show");
	};
	//AGREGAR CUENTA
	const addMovimiento = () => {
		$("#kt_datepicker_8").flatpickr({
			altFormat: "Y-m-d",
			dateFormat: "Y-m-d",
			// minDate: "today",
			onChange: function (selectedDates, dateStr, instance) {
				setFechaM(moment(dateStr).format("yyyy-MM-DD"));
			},
			defaultDate: moment(today).format("yyyy-MM-DD"),
		});
		setValue("fechaM", moment(today).format("yyyy-MM-DD"));
		setValue("idcuentabancaria", "");
		setValue("monto", "");
		$("#modal_movs_add_mov").modal("show");
	};

	//AGREGAR CUENTA
	const addAutogenerar = () => {
		$("#kt_datepicker_9").flatpickr({
			altFormat: "Y-m-d",
			dateFormat: "Y-m-d",
			// minDate: "today",
			onChange: function (selectedDates, dateStr, instance) {
				setFechaM(moment(dateStr).format("yyyy-MM-DD"));
			},
			defaultDate: moment(today).format("yyyy-MM-DD"),
		});
		setValue3("fechaM", moment(today).format("yyyy-MM-DD"));
		$("#modal_movs_add_auto").modal("show");
	};
	//RESETEAR FORMS
	const resetForm = () => {
		reset({
			fechaM: moment(today).format("yyyy-MM-DD"),
			casaCuenta: "",
			monto: "",
			observacion: "",
		});
	};
	const resetForm2 = () => {
		reset2({
			montoM: "",
			observacion: "",
		});
	};
	const resetForm3 = () => {
		reset3({
			montoM: "",
		});
	};
	const montoValidate = (e) => {
		if (
			(e.keyCode < 48 || e.keyCode > 57) &&
			e.keyCode != 110 &&
			e.keyCode != 190 &&
			e.keyCode != 8 &&
			e.keyCode != 9 &&
			e.keyCode != 46 &&
			(e.keyCode < 96 || e.keyCode > 105)
		) {
			e.preventDefault();
		}
	};
	//ENVIAR DATA
	const onSubmit = async (data) => {
		setLoading2(true);
		const datos = data;
		const bodyAdd = {
			idcuentabancaria: parseInt(datos.idcuentabancaria),
			monto: parseFloat(datos.monto),
			fecha: datos.fechaM,
			tipo: (datos.tipo),
			tcambio: user.tipocambio,
			refreshToken: "Bearer " + refreshToken,
		};
		// console.log('bodyAdd', bodyAdd)
		const respAdd = await MontosService.addMonto(bodyAdd);
		$("#modal_movs_add_mov").modal("hide");
		// console.log("respAdd", respAdd);
		if (respAdd.status == true) {
			Swal.fire({
				title: "Registrado",
				icon: "success",
				text: "Datos registrados con éxito.",
			});
		} else if (respAdd.status == 202) {
			Swal.fire({
				title: "Error!",
				icon: "warning",
				text: "¡El registro existe previamente o datos incorrectos!",
			});
		} else {
			Swal.fire({
				title: "Error!",
				icon: "error",
				text: "Ocurrió un error al registrar los datos.",
			});
		}
		getData();
		setLoading2(false);

		// resetForm();
	};
	const onSubmit2 = async (data) => {
		setLoading3(true);
		const datos = data;

		const bodyUpd = {
			idmontocuenta: parseInt(datos.idmontocuentabancaria),
			monto: parseFloat(datos.montoM),
			tipo: (datos.tipo),
			tcambio: user.tipocambio,
			refreshToken: "Bearer " + refreshToken,
		};
		const respAdd = await MontosService.updateMonto(bodyUpd);
		$("#modal_movs_edit_mov").modal("hide");
		if (respAdd.status == true) {
			Swal.fire({
				title: "Actualizado!",
				icon: "success",
				text: "Datos actualizados con éxito.",
			});
		} else {
			Swal.fire({
				title: "Error!",
				icon: "error",
				text: "Ocurrió un error al actualizar los datos.",
			});
		}
		getData();
		setLoading3(false);
		// resetForm();
	};

	//ENVIAR DATA
	const onSubmit3 = async (data) => {
		setLoading4(true);
		const datos = data;
		const bodyAuto = {
			fecha: datos.fechaM,
			tcambio: user.tipocambio,
			refreshToken: "Bearer " + refreshToken,
		};
		// console.log('bodyAuto', bodyAuto)
		const respAuto = await MontosService.autoMonto(bodyAuto);
		$("#modal_movs_add_auto").modal("hide");
		// console.log("respAuto", respAuto);
		if (respAuto.status == true) {
			Swal.fire({
				title: "Registrado",
				icon: "success",
				text: "Datos registrados con éxito.",
			});
		} else if (respAuto.status == 202) {
			Swal.fire({
				title: "Error!",
				icon: "warning",
				text: "¡No se encontraron datos para duplicar!",
			});
		} else {
			Swal.fire({
				title: "Error!",
				icon: "error",
				text: "Ocurrió un error al registrar los datos.",
			});
		}
		// getData();
		setLoading4(false);

		// resetForm();
	};

	const changeCuenta = (e) => {
		//console.log("e", e);
		if (e != null && e != "") {
			const filter = cuentasBancarias.filter((el) => el.id_cuentabancarias == e);
			setValue("tipo", filter[0].tipo)
		}
	};
	/* -----FIN METODOS----*/

	/* -----MOUNTED----*/
	useEffect(() => {
		getData();
		resetForm();
	}, []);
	useEffect(() => {
		selectData();
		$("#kt_datepicker_1").flatpickr({
			altFormat: "Y-m-d",
			dateFormat: "Y-m-d",
			// onChange: function (selectedDates, dateStr, instance) {
			//   setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
			// },
			defaultDate: moment(today).format("yyyy-MM-DD"),
		});
		$("#kt_datepicker_2").flatpickr({
			altFormat: "Y-m-d",
			dateFormat: "Y-m-d",
			// onChange: function (selectedDates, dateStr, instance) {
			//   setFiltros({ ...filtros, f_fin: moment(dateStr).format("yyyy-MM-DD") });
			// },
			defaultDate: moment(today).format("yyyy-MM-DD"),
		});
		$("#kt_datepicker_8").flatpickr({
			altFormat: "Y-m-d",
			dateFormat: "Y-m-d",
			// minDate: "today",
			onChange: function (selectedDates, dateStr, instance) {
				setFechaM(moment(dateStr).format("yyyy-MM-DD"));
				setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
			},
			defaultDate: moment(today).format("yyyy-MM-DD"),
		});
	}, []);

	/* -----FIN MOUNTED----*/
	return (
		<Main>
			<div className="container-xxl mw-100">
				<div className="toolbar" id="kt_toolbar">
					<div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
						<Breadcrumbs crumbs={crumbs}></Breadcrumbs>
					</div>
				</div>
				<div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
					<div className="overlay-wrapper">
						<div className="card p-0 shadow">
							<div className="card-header border-0 p-6">
								<div className="card-toolbar w-100">
									<div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
										<div className="row mx-0 w-100">

											<div className="col-md-3">
												<label htmlFor="" className="form-label">
													Inicio
												</label>
												<input
													className="form-control form-control-solid"
													placeholder="Fecha Inicio"
													id="kt_datepicker_1"
													onInput={(e) => setFiltros({ ...filtros, f_inicio: moment(e.target.value).format("yyyy-MM-DD") })}
												/>
											</div>
											<div className="col-md-3">
												<label htmlFor="" className="form-label">
													Fin
												</label>
												<input
													className="form-control form-control-solid"
													placeholder="Fecha Fin"
													id="kt_datepicker_2"
													onInput={(e) => setFiltros({ ...filtros, f_fin: moment(e.target.value).format("yyyy-MM-DD") })}
												/>
											</div>
											{/* <div className=" col-md-3 ">
                                                <label htmlFor="casas-estado" className="form-label">
                                                    Tipo
                                                </label>
                                                <Select
                                                    options={tipos.map((cu) => ({
                                                        label: cu.text,
                                                        value: cu.value,
                                                    }))}
                                                    defaultValue={{ label: "Todos", value: "Todos" }}
                                                    styles={colourStyles}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(e) => setFiltros({ ...filtros, tipo: e.value })}
                                                    placeholder={"Seleccionar"}
                                                    className="select-input"
                                                />
                                            </div> */}
											<div className="col-12 col-md-5">
												<div className="d-flex align-content-start justify-content-xl-start justify-content-center h-100  w-100">
													<button
														disabled={loading}
														type="button"
														className="btn btn-primary mt-auto me-2"
														onClick={getData}
													>
														{loading ? (
															<span className="indicator-label">
																Buscando...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
															</span>
														) : (
															<span className="indicator-label ">
																<i className="bi bi-search" /> Buscar
															</span>
														)}
													</button>
													<button type="button" className="btn btn-warning me-2 mt-auto" onClick={addAutogenerar}>
														<i className="bi bi-plus-circle fs-4 me-2" /> Autogenerar
													</button>
													<button type="button" className="btn btn-success mt-auto" onClick={addMovimiento}>
														<i className="bi bi-plus-circle fs-4 me-2" /> Agregar
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body p-0">
								<MUIDataTable
									title={<div className="d-flex gap-3 px-4"><span className="fw-bolder fs-14">Lista de Montos</span> | <span className="fw-bolder">Total: {total}</span></div>}
									data={items}
									columns={columns1}
									options={options}
								/>
							</div>
						</div>
						<div className="modal fade" id="modal_movs_add_mov" tabIndex="-1" aria-modal="true" role="dialog">
							<div className="modal-dialog modal-dialog-centered mw-650px">
								<div className="modal-content">
									<form
										className="form fv-plugins-bootstrap5 fv-plugins-framework"
										id="modal_movs_add_mov_form"
										onSubmit={handleSubmit(onSubmit)}
									>
										<div className="modal-header" id="modal_movs_add_mov_header">
											<h2 className="fw-bold">Agregar Monto</h2>
											<div
												id="modal_movs_add_mov_close"
												className="btn btn-icon btn-sm btn-active-icon-primary"
												data-bs-dismiss="modal"
												onClick={resetForm}
											>
												<span className="svg-icon svg-icon-1">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect
															opacity="0.5"
															x="6"
															y="17.3137"
															width="16"
															height="2"
															rx="1"
															transform="rotate(-45 6 17.3137)"
															fill="currentColor"
														></rect>
														<rect
															x="7.41422"
															y="6"
															width="16"
															height="2"
															rx="1"
															transform="rotate(45 7.41422 6)"
															fill="currentColor"
														></rect>
													</svg>
												</span>
											</div>
										</div>
										<div className="modal-body py-5 px-lg-10">
											<div
												id="modal_movs_add_mov_scroll"
												data-kt-scroll-dependencies="#modal_movs_add_mov_header"
												data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
											>
												<div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
													<div className="col-12 mb-1">
														<label className="fs-6 fw-semibold my-2" htmlFor="">
															<span className="">Fecha</span>
														</label>
														<input
															className="form-control form-control-solid"
															placeholder="Fecha"
															id="kt_datepicker_8"
															value={fechaM}
															{...register("fechaM", {
																required: true,
															})}
														/>
														{errors.fechaM?.type === "required" && (
															<p className="text-danger">El campo es requerido</p>
														)}
													</div>
													<div className="col-12 mb-1">
														<label htmlFor="status" className="form-label">
															Cuenta Bancaria
														</label>
														<select
															className="select2-selection select2-selection--single form-select form-select-solid "
															name="status"
															id="status"
															{...register("idcuentabancaria", {
																required: true,
															})}
															onClick={(e) => changeCuenta(e.target.value)}
														>
															<option value="">Seleccionar</option>
															{cuentasBancarias.map((cuenta) => (
																<option key={cuenta.id_cuentabancarias} value={cuenta.id_cuentabancarias}>
																	{cuenta.cuenta}
																</option>
															))}
														</select>
														{errors.idcuentabancaria?.type === "required" && (
															<p className="text-danger">El campo es requerido</p>
														)}
													</div>
													<div className="col-12 mb-1">
														<label className="fs-6 fw-semibold my-2" htmlFor="">
															<span className="required">Monto</span>
														</label>
														<input
															type="text"
															className="form-control form-control-solid"
															placeholder="Monto"
															autocomplete="off"
															name="monto"
															onKeyDown={(e) => montoValidate(e)}
															{...register("monto", {
																required: true,
																valueAsNumber: true,
															})}
														/>
														{errors.monto?.type === "required" && (
															<p className="text-danger">El campo es requerido</p>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer flex-center">
											<button
												type="reset"
												id="modal_movs_add_mov_cancel"
												className="btn btn-danger me-3"
												data-bs-dismiss="modal"
												onClick={resetForm}
											>
												Cerrar
											</button>
											<button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
												{loading2 ? (
													<span className="indicator-label">
														Agregando....
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												) : (
													<span className="indicator-label">Agregar</span>
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal fade" id="modal_movs_edit_mov" tabIndex="-1" aria-modal="true" role="dialog">
							<div className="modal-dialog modal-dialog-centered mw-650px">
								<div className="modal-content">
									<form
										className="form fv-plugins-bootstrap5 fv-plugins-framework"
										id="modal_movs_edit_mov_form"
										onSubmit={handleSubmit2(onSubmit2)}
									>
										<div className="modal-header" id="modal_movs_edit_mov_header">
											<h2 className="fw-bold">Editar Movimiento</h2>
											<div
												id="modal_movs_edit_mov_close"
												className="btn btn-icon btn-sm btn-active-icon-primary"
												data-bs-dismiss="modal"
												onClick={resetForm2}
											>
												<span className="svg-icon svg-icon-1">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect
															opacity="0.5"
															x="6"
															y="17.3137"
															width="16"
															height="2"
															rx="1"
															transform="rotate(-45 6 17.3137)"
															fill="currentColor"
														></rect>
														<rect
															x="7.41422"
															y="6"
															width="16"
															height="2"
															rx="1"
															transform="rotate(45 7.41422 6)"
															fill="currentColor"
														></rect>
													</svg>
												</span>
											</div>
										</div>
										<div className="modal-body py-10 px-lg-17">
											<div
												id="modal_movs_edit_mov_scroll"
												data-kt-scroll-dependencies="#modal_movs_edit_mov_header"
												data-kt-scroll-wrappers="#modal_movs_edit_mov_scroll"
											>
												<div className="fv-row mb-7 fv-plugins-icon-container">
													<label className="fs-6 fw-semibold my-2" htmlFor="">
														<span className="required">Monto</span>
													</label>
													<input
														type="text"
														className="form-control form-control-solid"
														placeholder="Monto"
														autocomplete="off"
														name="montoM"
														id="montoM"
														onKeyDown={(e) => montoValidate(e)}
														{...register2("montoM", {
															required: true,
															valueAsNumber: true,
														})}
													/>
													{errors.montoM?.type === "required" && (
														<p className="text-danger">El campo es requerido</p>
													)}
												</div>
											</div>
										</div>
										<div className="modal-footer flex-center">
											<button
												type="reset"
												id="modal_movs_edit_mov_cancel"
												className="btn btn-light me-3"
												data-bs-dismiss="modal"
												onClick={resetForm2}
											>
												Cerrar
											</button>
											<button type="submit" id="modal_movs_edit_mov_submit" className="btn btn-primary">
												{loading3 ? (
													<span className="indicator-label">
														Editando....
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												) : (
													<span className="indicator-label">Editar</span>
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="modal fade" id="modal_movs_add_auto" tabIndex="-1" aria-modal="true" role="dialog">
							<div className="modal-dialog modal-dialog-centered mw-650px">
								<div className="modal-content">
									<form
										className="form fv-plugins-bootstrap5 fv-plugins-framework"
										id="modal_movs_add_mov_form"
										onSubmit={handleSubmit3(onSubmit3)}
									>
										<div className="modal-header" id="modal_movs_add_mov_header">
											<h2 className="fw-bold">Autogenerar montos</h2>
											<div
												id="modal_movs_add_mov_close"
												className="btn btn-icon btn-sm btn-active-icon-primary"
												data-bs-dismiss="modal"
												onClick={resetForm3}
											>
												<span className="svg-icon svg-icon-1">
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect
															opacity="0.5"
															x="6"
															y="17.3137"
															width="16"
															height="2"
															rx="1"
															transform="rotate(-45 6 17.3137)"
															fill="currentColor"
														></rect>
														<rect
															x="7.41422"
															y="6"
															width="16"
															height="2"
															rx="1"
															transform="rotate(45 7.41422 6)"
															fill="currentColor"
														></rect>
													</svg>
												</span>
											</div>
										</div>
										<div className="modal-body py-5 px-lg-10">
											<div
												id="modal_movs_add_mov_scroll"
												data-kt-scroll-dependencies="#modal_movs_add_mov_header"
												data-kt-scroll-wrappers="#modal_movs_add_mov_scroll"
											>
												<div className="row mx-0 w-100 mb-7 fv-plugins-icon-container">
													<div className="col-12 mb-1">
														<label className="fs-6 fw-semibold my-2" htmlFor="">
															<span className="">Fecha</span>
														</label>
														<input
															className="form-control form-control-solid"
															placeholder="Fecha"
															id="kt_datepicker_9"
															value={fechaM}
															{...register3("fechaM", {
																required: true,
															})}
														/>
														{errors3.fechaM?.type === "required" && (
															<p className="text-danger">El campo es requerido</p>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="modal-footer flex-center">
											<button
												type="reset"
												id="modal_movs_add_mov_cancel"
												className="btn btn-danger me-3"
												data-bs-dismiss="modal"
												onClick={resetForm3}
											>
												Cerrar
											</button>
											<button type="submit" id="modal_movs_add_mov_submit" className="btn btn-primary">
												{loading4 ? (
													<span className="indicator-label">
														Generando....
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												) : (
													<span className="indicator-label">Generar</span>
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					{loading ? (
						<div className="overlay-custom card-rounded bg-black bg-opacity-50">
							<div className="d-flex flex-column align-items-center mt-10">
								<div className="spinner-border text-white" role="status"></div>
								<span>Buscando datos...</span>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
		</Main>
	);
};
export default Montos;
