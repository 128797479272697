/* eslint-disable */
import React, { useState } from "react";
import { useUserContext } from "../../Providers/UserProvider";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import avatars from "../../Assets/avatars.json";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useEffect } from "react";
import ProfileService from "../../api/services/profileService";
import moment from "moment/moment";
import 'moment/locale/es'
import Main from "../../layout/main";
moment.locale('es')
const schema = yup.object({
    password: yup.string().required("Este campo es requerido"),
    newpassword: yup.string().required("Este campo es requerido"),
    confirmPassword: yup
        .string()
        .required("Este campo es requerido")
        .test("passwords-match", "Las contraseñas deben coincidir", function (value) {
            return this.parent.newpassword === value;
        }),
});

import "./perfil.css";
const Perfil = () => {
    //CONTEXT
    const { user, setLocalStorage } = useUserContext();
    const urlBase = window.location.origin + "/assets/media/avatars/";

    //REFRESH TOKEN
    const navigate = useNavigate();
    const refreshToken = localStorage.getItem("refreshToken");

    //HOOKS
    const [viewPass, setViewPass] = useState(false);
    const [viewPass2, setViewPass2] = useState(false);
    const [viewPass3, setViewPass3] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [items, setItems] = useState(avatars);
    const [avatarSelect, setAvatarSelect] = useState("");
    const [form, setForm] = useState(user);

    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        formState: { errors: errors2 },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    //MOUNTED
    useEffect(() => {
        setForm(user);
        setValue("nombre", user.nombres);
        setValue("apellido", user.apellidos);
        setValue("celular", user.celular);
        setValue("pais", user.pais);
        setValue("ciudad", user.ciudad);
        setAvatarSelect(user.avatar);
    }, []);

    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading(true)
        const body = {
            apellidos: data.apellido,
            nombres: data.nombre,
            telefono: data.celular,
            codtelefono: user.codcelular,
            img: avatarSelect,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await ProfileService.updateUser(body);
        if (resp.status) {
            user.apellidos = data.apellido;
            user.nombres = data.nombre;
            user.celular = data.celular;
            user.avatar = avatarSelect;
            setLocalStorage("userCca", user);
            Swal.fire({
                title: "Actualizado",
                icon: "success",
                text: "Datos actualizado con éxito.",
            });

            // localStorage.setItem("userCca", JSON.stringify(user));
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        setLoading(false)
    };
    const resetForm = () => {
        reset({
            oldpassword: "",
            newpassword: "",
            confirmPassword: "",
        });
    };
    //ENVIAR DATA DE CONTRASEÑA
    const onSubmit2 = async (data) => {
        setLoading2(true)
        const body = {
            oldpassword: data.password,
            newpassword: data.newpassword,
            refreshToken: "Bearer " + refreshToken,
        };
        const respPass = await ProfileService.updatePassword(body);
        if (respPass.status) {
            resetForm();
            Swal.fire({
                title: "Actualizado",
                icon: "success",
                text: "Contraseña actualizada con éxito.",
            });
            changePassword();
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar la contraseña.",
            });
        }
        setLoading2(false)
    };
    //TOGGLE PASSWORD
    const changePassword = () => {
        document.getElementById("kt_signin_password_edit").classList.toggle("d-none");
        document.getElementById("kt_signin_password").classList.toggle("d-none");
        document.getElementById("kt_signin_password_button").classList.toggle("d-none");
    };

    //MODAL DE AVATARES
    const addAvatar = () => {
        $("#kt_modal_add_avatar").modal("show");
    };
    //SELECCIONAR AVATAR
    const choseAvatar = (name) => {
        setAvatarSelect(name);
    };

    //CAMBIAR AVATAR Y CONSUMIR SERVICIO
    const changeAvatar = async () => {
        $("#kt_modal_add_avatar").modal("hide");
    };
    //REGEX
    const onlyNumber = (e) => {
        if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 190 && e.keyCode != 8 && e.keyCode != 9 && e.keyCode != 46 && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    };
    const onlyText = (e) => {
        if ((e.keyCode < 65 || e.keyCode > 90) && e.keyCode != 32 && e.keyCode != 8 && e.keyCode != 9 && e.keyCode != 13) {
            e.preventDefault();
        }
    };
    //ELIMINAR AVATAR Y CONSUMIR SERVICIO
    const deleteAvatar = () => {
        setAvatarSelect("");
    };
    //POS NO CAMBIA DE AVATAR JEJE - SE PUEDE ADJUNTAR A OTRA VARIABLE PARA QUE NO HAYA ESE CAMBIO AL FONDO DEL AVATAR PERO ÑE
    const notAvatar = () => {
        setAvatarSelect(user.avatar);
    };

    //cambiar PLAN
    const changePlan = () => {
        navigate("/planes");
    };
    const discardForm = () => {
        setIsEdit(false);
        setValue("nombre", user.nombres);
        setValue("apellido", user.apellidos);
        setValue("celular", user.celular);
        setAvatarSelect(user.avatar);
    };
    return (
        <Main>
            <div className="container-xxl">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-column flex-md-row-auto w-100 w-lg-250px w-xxl-275px">
                        <div
                            className="card mb-6 mb-xl-9"
                            data-kt-sticky="true"
                            data-kt-sticky-name="account-settings"
                            data-kt-sticky-offset="{default: false, lg: 300}"
                            data-kt-sticky-width="{lg: '250px', xxl: '275px'}"
                            data-kt-sticky-left="auto"
                            data-kt-sticky-top="100px"
                            data-kt-sticky-zindex="95"
                        >
                            <div className="card-body py-10 px-6">
                                <ul
                                    id="kt_account_settings"
                                    className="nav nav-flush menu menu-column menu-rounded menu-title-gray-600 menu-bullet-gray-300 menu-state-bg menu-state-bullet-primary fw-semibold fs-6 mb-2"
                                >
                                    <li className="menu-item px-3 pt-0 pb-1">
                                        <a href="#data-personal" data-kt-scroll-toggle="true" className="menu-link px-3 nav-link active">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Perfil</span>
                                        </a>
                                    </li>
                                    {/* <li className="menu-item px-3 pt-0 pb-1">
                                        <a href="#change-password" data-kt-scroll-toggle="true" className="menu-link px-3 nav-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Cambiar Contraseña</span>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex-md-row-fluid ms-lg-12">
                        <div
                            className="card mb-5 mb-xl-10"
                            id="data-personal"
                            data-kt-scroll-offset="{default: 100, md: 125}"
                            data-kt-sticky-left="auto"
                            data-kt-sticky-top="100px"
                            data-kt-sticky-zindex="95"
                        >
                            <div
                                className="card-header border-0 cursor-pointer collapse show "
                                role="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#kt_account_profile_details"
                                aria-expanded="true"
                                aria-controls="kt_account_profile_details"
                            >
                                <div className="card-title m-0 justify-content-between w-100">
                                    <h3 className="fw-bold m-0">Datos Personales</h3>
                                    <button className="btn btn-primary" onClick={() => setIsEdit(true)}>
                                        Editar
                                    </button>
                                </div>
                            </div>
                            <div className="">
                                <form id="kt_account_profile_details_submit" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Foto</label>
                                            <div className="col-lg-8">
                                                <div
                                                    className="image-input image-input-outline"
                                                    data-kt-image-input="true"
                                                    style={{
                                                        backgroundImage: "url(../assets/media/svg/avatars/blank.svg)",
                                                    }}
                                                >
                                                    <div
                                                        className="image-input-wrapper w-125px h-125px"
                                                        style={{
                                                            backgroundImage:
                                                                "url(../assets/media/avatars/" + (avatarSelect != "" ? avatarSelect : "default.png") + ")",
                                                        }}
                                                    ></div>
                                                    {isEdit ? (
                                                        <label
                                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                            data-kt-image-input-action="change"
                                                            data-bs-toggle="tooltip"
                                                            title="Cambiar foto"
                                                            onClick={addAvatar}
                                                        >
                                                            <i className="bi bi-pencil-fill fs-7"></i>
                                                            <input type="hidden" name="avatar_remove" />
                                                        </label>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <span
                                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                        data-kt-image-input-action="cancel"
                                                        data-bs-toggle="tooltip"
                                                        title="Cancel avatar"
                                                    >
                                                        <i className="bi bi-x fs-2"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Documento</span>
                                            </label>
                                            <div className="col-lg-8 fv-row d-flex align-items-center">
                                                <span className="">{form.documento}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Nombres Completos</label>
                                            <div className="col-lg-8">
                                                <div className="row">
                                                    <div className="col-lg-6 fv-row">
                                                        <input
                                                            type="text"
                                                            name="fname"
                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                            placeholder="First name"
                                                            readOnly={!isEdit}
                                                            onKeyDown={onlyText}
                                                            // defaultValue={form.nombre}
                                                            // value={form.nombre}
                                                            {...register("nombre", { required: true })}
                                                        />
                                                        {errors.nombre?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                                                    </div>
                                                    <div className="col-lg-6 fv-row">
                                                        <input
                                                            type="text"
                                                            name="lname"
                                                            className="form-control form-control-lg form-control-solid"
                                                            placeholder="Last name"
                                                            readOnly={!isEdit}
                                                            onKeyDown={onlyText}
                                                            {...register("apellido", { required: true })}
                                                        />
                                                        {errors.apellido?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="required">Celular</span>
                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="El número debe estar activo"></i>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <input
                                                    type="tel"
                                                    name="phone"
                                                    className="form-control form-control-lg form-control-solid"
                                                    placeholder="Phone number"
                                                    readOnly={!isEdit}
                                                    onKeyDown={onlyNumber}
                                                    {...register("celular", { required: true })}
                                                />
                                                {errors.celular?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Pais</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span>{form.pais}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Ciudad</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span>{form.ciudad}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <h3 className="fw-bold my-10">Contacto</h3>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Correo</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span className="">{form.correo}</span>
                                            </div>
                                        </div>
                                        {/* <hr />
                                        <div className="my-10 d-flex justify-content-between">
                                            <h3 className="fw-bold">Planes</h3>
                                            <button className="btn btn-success" type="button" onClick={changePlan}>
                                                Cambiar Plan
                                            </button>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="requied">Paquete</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span>{form.nomplan}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Fecha de Inicio</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span>{moment(form.fecha_inicio).format("DD-MM-yyyy")}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-6">
                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                <span className="">Fecha de Expiración</span>
                                            </label>
                                            <div className="col-lg-8 fv-row">
                                                <span>{moment(form.fecha_fin).format("DD-MM-yyyy")}</span>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        {isEdit ? (
                                            <>
                                                <button type="button" className="btn btn-light btn-active-light-primary me-2" onClick={discardForm}>
                                                    Descatar
                                                </button>
                                                <button disabled={loading} type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">
                                                    {loading ? (
                                                        <span className="indicator-label">
                                                            Editando...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label ">Guardar</span>
                                                    )}

                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                     
                    </div>
                </div>
                <div className="modal fade" tabIndex="-1" id="kt_modal_add_avatar">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Seleccionar Avatar</h3>
                                <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={notAvatar}>
                                    <span className="svg-icon svg-icon-1">X</span>
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className="row g-5 p-2 justify-content-center">
                                    {items.map((avatar) => {
                                        return (
                                            <div onClick={() => choseAvatar(avatar.name)} className="col-3 col-md-4 text-center" key={avatar.name}>
                                                <div
                                                    className={
                                                        avatarSelect == avatar.name
                                                            ? "symbol symbol-75px symbol-circle mb-7 card_avatar cursor-pointer active"
                                                            : "symbol symbol-75px symbol-circle mb-7 card_avatar cursor-pointer"
                                                    }
                                                >
                                                    <img loading="lazy" src={urlBase + avatar.name} alt="image" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={notAvatar}>
                                    Cerrar
                                </button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={changeAvatar}>
                                    Cambiar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};
export default Perfil;
