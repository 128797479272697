/* eslint-disable */
import React, { useEffect, useState } from "react";
import Main from "../../layout/main";
import MUIDataTable from "mui-datatables";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import EstadisticaService from "../../api/services/estadisticaService";
import { FaSearch } from "react-icons/fa";
import moment from "moment/moment";
import ReactApexChart from "react-apexcharts";
import { useUserContext } from "../../Providers/UserProvider";
const today = new Date();
var dataItem = "";
moment.locale("es");
var start = moment().subtract(29, "days");
var end = moment();
function cb(start, end) {
    $("#kt_daterangepicker_4").html(" Desde: " + start.format("MMMM D, YYYY") + " - Hasta: " + end.format("MMMM D, YYYY"));
}
function cb1(start, end) {
    $("#kt_daterangepicker_5").html(" Desde: " + start.format("MMMM D, YYYY") + " - Hasta: " + end.format("MMMM D, YYYY"));
}
function cb2(start, end) {
    $("#kt_daterangepicker_6").html(" Desde: " + start.format("MMMM D, YYYY") + " - Hasta: " + end.format("MMMM D, YYYY"));
}
function cb3(start, end) {
    $("#kt_daterangepicker_7").html(" Desde: " + start.format("MMMM D, YYYY") + " - Hasta: " + end.format("MMMM D, YYYY"));
}

const Resumen = () => {
    const [loading, setLoading] = useState(false);
    const [checkView, setCheckView] = useState(1);
    const [items, setItems] = useState("");
    const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
    const refreshToken = localStorage.getItem("refreshToken");
    const { user } = useUserContext();
    const [prodD, setProdD] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Producción Diaria",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [prodA, setProdA] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "linea",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Producción Acumulada",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [jugaD, setJugaD] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Jugadas Diarias",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [jugaA, setJugaA] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Jugadas Acumuladas",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [rentaD, setRentaD] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Rentabilidad Diaria",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " %";
                    },
                },
            },
        },
    });
    const [rentaA, setRentaA] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Rentabilidad Acumulada",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " %";
                    },
                },
            },
        },
    });
    const [capitalD, setCapitalD] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Crecimiento de Capital",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [prodCostosP, setProdCostosP] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Acumulado",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            // annotations: {
            //     yaxis: [
            //         {
            //             y: 0,
            //             borderColor: "#00E396",
            //             label: {
            //                 borderColor: "#00E396",
            //                 style: {
            //                     color: "#fff",
            //                     background: "#1C8E55",
            //                 },
            //                 text: "",
            //             },
            //         },
            //     ],
            // },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const [prodCostosC, setProdCostosC] = useState({
        series: [],
        options: {
            chart: {
                height: 250,
                type: "bar",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: true,
                        customIcons: [],
                    },
                },
            },
            colors: ["#009B1C", "#FF3E00", "#00FFD4", "#FFF000", "#8F502A", "#751075", "#BCE505"],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 5,
            },
            title: {
                text: "Diario",
                align: "left",
                style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#1C8E55",
                },
            },
            grid: {
                borderColor: "#0000005c",
                strokeDashArray: 0,
                row: {
                    colors: ["#b5b5c3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                },
            },
            // markers: {
            //     size: 1,
            // },
            xaxis: {
                categories: [],
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            yaxis: {
                title: {
                    text: "",
                },
                lines: {
                    show: false,
                },
            },
            annotations: {
                yaxis: [
                    {
                        y: 0,
                        borderColor: "#151521",
                        label: {
                            borderColor: "#151521",
                            style: {
                                color: "#fff",
                                background: "#151521",
                            },
                            text: "",
                        },
                    },
                ],
            },
            legend: {
                position: "top",
                horizontalAlign: "right",
                floating: true,
                offsetY: -25,
                offsetX: -5,
            },
        },
    });
    const getData = async () => {
        setLoading(true);
        const body = {
            fecha: fecha,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await EstadisticaService.getResumen(body);
        // console.log("resp resumne", resp);
        if (resp.status) {
            setItems(resp.data[0]);
            dataItem = resp.data[0];
        }
        setLoading(false);
    };
    const getProduccion = async (time1, time2) => {
        setLoading(true);
        const body = {
            fechainicio: time1,
            fechafin: time2,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        //PRODUCCION
        const respPro = await EstadisticaService.getProduccion(body);
        // console.log("respPro", respPro);
        if (respPro.status) {
            let arrayCategoriesD = [];
            let arraySeriesD = [];
            let arrayCategoriesA = [];
            let arraySeriesA = [];
            for (let index = 0; index < respPro.data[0].diario.length; index++) {
                const element = respPro.data[0].diario[index];
                arrayCategoriesD.push(moment(element.fecha).format("MM/DD"));
                arraySeriesD.push(parseFloat(element.produccion));
            }
            setProdD({
                ...prodD,
                options: {
                    xaxis: {
                        categories: arrayCategoriesD,
                    },
                },
                series: [
                    {
                        name: "Producción",
                        data: arraySeriesD,
                    },
                ],
            });

            for (let index = 0; index < respPro.data[0].acumulado.length; index++) {
                const element = respPro.data[0].acumulado[index];
                arrayCategoriesA.push(moment(element.fecha).format("MM/DD"));
                arraySeriesA.push(parseFloat(element.produccion));
            }
            setProdA({
                ...prodA,
                options: {
                    xaxis: {
                        categories: arrayCategoriesA,
                    },
                },
                series: [
                    {
                        name: "Producción",
                        data: arraySeriesA,
                    },
                ],
            });
        }
        setLoading(false);
    };
    const getJugadas = async (time1, time2) => {
        setLoading(true);
        const body = {
            fechainicio: time1,
            fechafin: time2,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };

        //JUGADAS
        const respJugadas = await EstadisticaService.getJugadas(body);
        // console.log("respJugadas", respJugadas);
        if (respJugadas.status) {
            let arrayCategoriesD = [];
            let arraySeriesD = [];
            let arrayCategoriesA = [];
            let arraySeriesA = [];
            for (let index = 0; index < respJugadas.data[0].diario.length; index++) {
                const element = respJugadas.data[0].diario[index];
                arrayCategoriesD.push(moment(element.fecha).format("MM/DD"));
                arraySeriesD.push(parseFloat(element.cantidad));
            }
            // console.log("arrayCategoriesD", arrayCategoriesD);
            // console.log("arraySeriesD", arraySeriesD);
            setJugaD({
                ...jugaD,
                options: {
                    xaxis: {
                        categories: arrayCategoriesD,
                    },
                },
                series: [
                    {
                        name: "Jugadas",
                        data: arraySeriesD,
                    },
                ],
            });

            for (let index = 0; index < respJugadas.data[0].acumulado.length; index++) {
                const element = respJugadas.data[0].acumulado[index];
                arrayCategoriesA.push(moment(element.fecha).format("MM/DD"));
                arraySeriesA.push(parseFloat(element.produccion));
            }
            setJugaA({
                ...jugaA,
                options: {
                    xaxis: {
                        categories: arrayCategoriesA,
                    },
                },
                series: [
                    {
                        name: "Jugadas",
                        data: arraySeriesA,
                    },
                ],
            });
        }
        setLoading(false);
    };
    const getRentabilidad = async (time1, time2) => {
        setLoading(true);
        const body = {
            fechainicio: time1,
            fechafin: time2,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };

        //RENTABILIDAD
        const respRenta = await EstadisticaService.getRentabilidad(body);
        // console.log("respRenta", respRenta);
        if (respRenta.status) {
            let arrayCategoriesD = [];
            let arraySeriesD = [];
            let arrayCategoriesA = [];
            let arraySeriesA = [];
            for (let index = 0; index < respRenta.data[0].diario.length; index++) {
                const element = respRenta.data[0].diario[index];
                arrayCategoriesD.push(moment(element.fecha).format("MM/DD"));
                arraySeriesD.push(parseFloat(element.monto));
            }
            setRentaD({
                ...rentaD,
                options: {
                    xaxis: {
                        categories: arrayCategoriesD,
                    },
                },
                series: [
                    {
                        name: "Rentabilidad",
                        data: arraySeriesD,
                    },
                ],
            });

            for (let index = 0; index < respRenta.data[0].acumulado.length; index++) {
                const element = respRenta.data[0].acumulado[index];
                arrayCategoriesA.push(moment(element.fecha).format("MM/DD"));
                arraySeriesA.push(parseFloat(element.ganancia));
            }
            setRentaA({
                ...rentaA,
                options: {
                    xaxis: {
                        categories: arrayCategoriesA,
                    },
                },
                series: [
                    {
                        name: "Rentabilidad",
                        data: arraySeriesA,
                    },
                ],
            });
        }

        setLoading(false);
    };
    const getCapital = async (time1, time2) => {
        setLoading(true);
        const body = {
            fechainicio: time1,
            fechafin: time2,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };

        //capital
        const respCapi = await EstadisticaService.getCapital(body);
        // console.log("respCapi", respCapi);
        if (respCapi.status) {
            let arrayCategoriesD = [];
            let arraySeriesD = [];
            let arrayCategoriesA = [];
            let arraySeriesA = [];
            if (respCapi.data[0].length > 0) {
                for (let index = 0; index < respCapi.data[0].length; index++) {
                    const element = respCapi.data[0][index];
                    arrayCategoriesD.push(moment(element.fecha).format("MM/DD"));
                    arraySeriesD.push(parseFloat(element.total));
                }
                setCapitalD({
                    ...capitalD,
                    options: {
                        xaxis: {
                            categories: arrayCategoriesD,
                        },
                    },
                    series: [
                        {
                            name: "Capital",
                            data: arraySeriesD,
                        },
                    ],
                });
            }
        }
        setLoading(false);
    };
    const getProdvsCos = async (time1, time2) => {
        setLoading(true);
        const body = {
            fechainicio: time1,
            fechafin: time2,
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };

        //PRODUCCION VS COSTOS
        const resVersus = await EstadisticaService.getProduccionVsGastos(body);
        // console.log("resVersus", resVersus);
        if (resVersus.status) {
            let arrayCategoriesD = [];
            let arraySeriesD = [];
            let arrayCategoriesA = [];
            let arraySeriesA = [];
            for (let index = 0; index < resVersus.data[0].produccion.length; index++) {
                const element = resVersus.data[0].produccion[index];
                arrayCategoriesD.push(moment(element.fecha).format("MM/DD"));
                arraySeriesD.push(parseFloat(element.monto));
            }
            for (let index = 0; index < resVersus.data[0].gastos.length; index++) {
                const element = resVersus.data[0].gastos[index];
                arrayCategoriesA.push(moment(element.fecha).format("MM/DD"));
                arraySeriesA.push(parseFloat(element.monto));
            }
            // console.log("arrayCategoriesD", arrayCategoriesD);
            // console.log("arraySeriesD", arraySeriesD);
            // console.log("dataItem", dataItem);
            setProdCostosP({
                ...prodCostosP,
                options: {
                    xaxis: {
                        categories: arrayCategoriesD,
                    },
                    // annotations: {
                    //     yaxis: [
                    //         {
                    //             y: parseFloat(dataItem != "" ? dataItem.costostotales : 0).toLocaleString("en"),
                    //             borderColor: "#00E396",
                    //             strokeDashArray: 2,
                    //             label: {
                    //                 borderColor: "#00E396",
                    //                 style: {
                    //                     color: "#fff",
                    //                     background: "#1C8E55",
                    //                 },
                    //                 text:
                    //                     "Costo Total en " +
                    //                     parseFloat(dataItem != "" ? dataItem.costostotales : 0).toLocaleString("en"),
                    //             },
                    //         },
                    //     ],
                    // },
                },

                series: [
                    {
                        name: "Producción",
                        data: arraySeriesD,
                    },
                    {
                        name: "Gastos",
                        type: "line",
                        data: arraySeriesA,
                    },
                ],
            });

            // console.log("arrayCategoriesA", arrayCategoriesA);
            // console.log("arraySeriesA", arraySeriesA);
            // console.log('resVersus.data[0].minima[0].minima', resVersus.data[0].minima[0].minima)
            setProdCostosC({
                ...prodCostosC,
                options: {
                    xaxis: {
                        categories: arrayCategoriesA,
                    },
                    annotations: {
                        yaxis: [
                            {
                                y: parseFloat(resVersus.data[0].minima.length > 0 ? resVersus.data[0].minima[0].minima : 0).toLocaleString("en"),
                                strokeDashArray: 2,
                                borderColor: "#FF3E00",
                                borderWidth: 4,
                                label: {
                                    borderColor: "#FF3E00",
                                    style: {
                                        color: "#fff",
                                        background: "#FF3E00",
                                    },
                                    text: "Meta Minima en " + parseFloat(resVersus.data[0].minima.length > 0 ? resVersus.data[0].minima[0].minima : 0).toLocaleString("en"),
                                },
                            },
                        ],
                    },
                },

                series: [
                    {
                        name: "Producción",
                        type: "bar",
                        data: arraySeriesD,
                    },
                    // {
                    //     name: "Gastos",
                    //     type: "line",
                    //     data: arraySeriesA,
                    // },
                ],
            });
        }
        setLoading(false);
    };
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/utilidades", name: "Utilidades", active: false },
    ];
    useEffect(() => {
        // getData()
    }, []);
    useEffect(() => {
        $("#kt_datepicker_30").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFecha(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        var rangos = {};
        rangos["Hoy"] = [moment(), moment()];
        rangos["Ayer"] = [moment().subtract(1, "days"), moment().subtract(1, "days")];
        rangos["Ultimos 7 Dias"] = [moment().subtract(6, "days"), moment()]; // Year 2017
        rangos["Ultimos 15 Dias"] = [moment().subtract(14, "days"), moment()]; // Year 2017
        rangos["Ultimos 30 Dias"] = [moment().subtract(29, "days"), moment()]; // Year 2016
        rangos["Este Mes"] = [moment().startOf("month"), moment().endOf("month")]; // Year 2015
        rangos["Ultimo Mes"] = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
        $("#kt_daterangepicker_4").daterangepicker(
            {
                startDate: start,
                endDate: end,
                opens: "left",
                ranges: rangos,
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Aplicar",
                    cancelLabel: "Cancelar",
                    fromLabel: "Desde",
                    toLabel: "Hasta",
                    customRangeLabel: "Personalizado",
                    daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
                    monthNames: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    firstDay: 1,
                },
            },
            cb
        );
        $("#kt_daterangepicker_4").on("apply.daterangepicker", function (ev, picker) {
            getProduccion(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
            // console.log(picker.startDate.format("YYYY-MM-DD"));
            // console.log(picker.endDate.format("YYYY-MM-DD"));
        });

        $("#kt_daterangepicker_5").daterangepicker(
            {
                startDate: start,
                endDate: end,
                opens: "left",
                ranges: rangos,
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Aplicar",
                    cancelLabel: "Cancelar",
                    fromLabel: "Desde",
                    toLabel: "Hasta",
                    customRangeLabel: "Personalizado",
                    daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
                    monthNames: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    firstDay: 1,
                },
            },
            cb1
        );
        $("#kt_daterangepicker_5").on("apply.daterangepicker", function (ev, picker) {
            getJugadas(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
            // console.log(picker.startDate.format("YYYY-MM-DD"));
            // console.log(picker.endDate.format("YYYY-MM-DD"));
        });

        $("#kt_daterangepicker_6").daterangepicker(
            {
                startDate: start,
                endDate: end,
                opens: "left",
                ranges: rangos,
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Aplicar",
                    cancelLabel: "Cancelar",
                    fromLabel: "Desde",
                    toLabel: "Hasta",
                    customRangeLabel: "Personalizado",
                    daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
                    monthNames: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    firstDay: 1,
                },
            },
            cb2
        );
        $("#kt_daterangepicker_6").on("apply.daterangepicker", function (ev, picker) {
            getRentabilidad(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
            // console.log(picker.startDate.format("YYYY-MM-DD"));
            // console.log(picker.endDate.format("YYYY-MM-DD"));
        });

        $("#kt_daterangepicker_7").daterangepicker(
            {
                startDate: start,
                endDate: end,
                opens: "left",
                ranges: rangos,
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Aplicar",
                    cancelLabel: "Cancelar",
                    fromLabel: "Desde",
                    toLabel: "Hasta",
                    customRangeLabel: "Personalizado",
                    daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
                    monthNames: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    firstDay: 1,
                },
            },
            cb3
        );
        $("#kt_daterangepicker_7").on("apply.daterangepicker", function (ev, picker) {
            getCapital(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
            // console.log(picker.startDate.format("YYYY-MM-DD"));
            // console.log(picker.endDate.format("YYYY-MM-DD"));
        });

        $("#kt_daterangepicker_8").daterangepicker(
            {
                startDate: start,
                endDate: end,
                opens: "left",
                ranges: rangos,
                locale: {
                    format: "DD/MM/YYYY",
                    separator: " - ",
                    applyLabel: "Aplicar",
                    cancelLabel: "Cancelar",
                    fromLabel: "Desde",
                    toLabel: "Hasta",
                    customRangeLabel: "Personalizado",
                    daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
                    monthNames: [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre",
                    ],
                    firstDay: 1,
                },
            },
            cb3
        );
        $("#kt_daterangepicker_8").on("apply.daterangepicker", function (ev, picker) {
            getProdvsCos(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"), dataItem);
            // console.log(picker.startDate.format("YYYY-MM-DD"));
            // console.log(picker.endDate.format("YYYY-MM-DD"));
        });
    }, []);
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="row mx-0 mb-10">
                            <div className="col-12 d-flex align-items-center">
                                <h1 className="text-success font-bold "><strong>Utilidad</strong></h1>
                            </div>
                            <div className="col-8 d-flex align-items-center mb-4">
                                <h3 className="text-success font-bold">
                                    $ {parseFloat(items != "" ? items.utilidad : 0).toLocaleString("en", { minimumFractionDigits: 2 })}
                                </h3>
                            </div>
                            <div className="col-4 d-flex justify-content-end align-items-center gap-2 px-4">
                                <div className="d-flex gap-2 align-items-center justify-content-end">
                                    <span>Fecha: </span>
                                    <input className="form-control form-control-solid w-auto" placeholder="Fecha" id="kt_datepicker_30" />
                                </div>
                                <button onClick={() => getData()} className="btn btn-primary btn-icon">
                                    <FaSearch></FaSearch>
                                </button>
                            </div>
                            <div className="col-12 d-flex flex-column px-10 gap-4">
                                <span className="fs-2 font-bold mb-2">Estadísticas</span>
                                <div className="row mx-0 w-100 d-flex gap-xl-6">
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0 ">
                                        <span className="fs-2">Producción acumulada</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            $ {parseFloat(items != "" ? items.produccionacumulada : 0).toLocaleString("en")}
                                        </button>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Costos totales</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            $ {parseFloat(items != "" ? items.costostotales : 0).toLocaleString("en")}
                                        </button>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 d-flex gap-xl-6">
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Producción promedio</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            $ {parseFloat(items != "" ? items.produccionpromedio : 0).toLocaleString("en")}
                                        </button>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Meta Minima</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            $ {parseFloat(items != "" ? items.metaminima : 0).toLocaleString("en")}
                                        </button>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 d-flex gap-xl-6">
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Jugadas acumuladas</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            {items != "" ? items.jugadasacumuladas : 0}
                                        </button>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Rentabilidad acumulada</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            {parseFloat(items != "" ? items.rentabacumulada : 0).toLocaleString("en")} %
                                        </button>
                                    </div>
                                </div>
                                <div className="row mx-0 w-100 d-flex gap-xl-6">
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Jugadas en promedio</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            {items != "" ? items.jugadaspromedio : 0}
                                        </button>
                                    </div>
                                    <div className="col-xl-4 col-md-6 col-12 d-flex gap-4 align-items-center justify-content-between mb-1 mb-md-0">
                                        <span className="fs-2">Rentabilidad promedio</span>
                                        <button className="btn btn-outline border border-2 border-success font-bold btn-outline-success btn-active-light-success pe-none px-4 py-1 rounded-4">
                                            {parseFloat(items != "" ? items.rentabpromedio : 0).toLocaleString("en")} %
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 mb-10">
                            <div className="col-12 d-flex justify-content-start">
                                <div className="">
                                    <button
                                        onClick={() => setCheckView(1)}
                                        type="button"
                                        className={`btn rounded-4 font-bold fs-4 py-2 px-10 ${checkView == 1 ? "btn-primary" : "btn_second"
                                            } me-3`}
                                    >
                                        Producción
                                    </button>
                                    <button
                                        onClick={() => setCheckView(2)}
                                        type="button"
                                        className={`btn rounded-4 font-bold fs-4 py-2 px-10 ${checkView == 2 ? "btn-primary" : "btn_second"
                                            } me-3`}
                                    >
                                        Jugadas
                                    </button>
                                    <button
                                        onClick={() => setCheckView(3)}
                                        type="button"
                                        className={`btn rounded-4 font-bold fs-4 py-2 px-10 ${checkView == 3 ? "btn-primary" : "btn_second"
                                            } me-3`}
                                    >
                                        Rentabilidad
                                    </button>
                                    <button
                                        onClick={() => setCheckView(4)}
                                        type="button"
                                        className={`btn rounded-4 font-bold fs-4 py-2 px-10 ${checkView == 4 ? "btn-primary" : "btn_second"
                                            } me-3`}
                                    >
                                        Capital
                                    </button>
                                    <button
                                        onClick={() => setCheckView(5)}
                                        type="button"
                                        className={`btn rounded-4 font-bold fs-4 py-2 px-10 ${checkView == 5 ? "btn-primary" : "btn_second"
                                            } me-3`}
                                    >
                                        Producción VS Costos
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 mb-5" style={{ display: checkView == 1 ? "flex" : "none" }}>
                            <div className="col-6"></div>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end">
                                <label htmlFor="">Fecha:</label>
                                <input
                                    className="form-control form-control-solid w-auto"
                                    placeholder="Pick date rage"
                                    id="kt_daterangepicker_4"
                                />
                            </div>

                            <div className="col-12">
                                <ReactApexChart options={prodA.options} series={prodA.series} type="line" height={400} />
                            </div>
                            <div className="col-12">
                                <ReactApexChart options={prodD.options} series={prodD.series} type="bar" height={400} />
                            </div>
                        </div>
                        <div className="row mx-0 mb-5" style={{ display: checkView == 2 ? "flex" : "none" }}>
                            <div className="col-6"></div>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end">
                                <label htmlFor="">Fecha:</label>
                                <input
                                    className="form-control form-control-solid w-auto"
                                    placeholder="Pick date rage"
                                    id="kt_daterangepicker_5"
                                />
                            </div>

                            <div className="col-12">
                                <ReactApexChart options={jugaA.options} series={jugaA.series} type="line" height={400} />
                            </div>
                            <div className="col-12">
                                <ReactApexChart options={jugaD.options} series={jugaD.series} type="bar" height={400} />
                            </div>
                        </div>
                        <div className="row mx-0 mb-5" style={{ display: checkView == 3 ? "flex" : "none" }}>
                            <div className="col-6"></div>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end">
                                <label htmlFor="">Fecha:</label>
                                <input
                                    className="form-control form-control-solid w-auto"
                                    placeholder="Pick date rage"
                                    id="kt_daterangepicker_6"
                                />
                            </div>
                            <div className="col-12">
                                <ReactApexChart options={rentaA.options} series={rentaA.series} type="line" height={400} />
                            </div>
                            <div className="col-12">
                                <ReactApexChart options={rentaD.options} series={rentaD.series} type="bar" height={400} />
                            </div>
                        </div>
                        <div className="row mx-0 mb-5" style={{ display: checkView == 4 ? "flex" : "none" }}>
                            <div className="col-6"></div>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end">
                                <label htmlFor="">Fecha:</label>
                                <input
                                    className="form-control form-control-solid w-auto"
                                    placeholder="Pick date rage"
                                    id="kt_daterangepicker_7"
                                />
                            </div>

                            <div className="col-12">
                                <ReactApexChart options={capitalD.options} series={capitalD.series} type="bar" height={400} />
                            </div>
                        </div>
                        <div className="row mx-0 mb-5" style={{ display: checkView == 5 ? "flex" : "none" }}>
                            <div className="col-6"></div>
                            <div className="col-6 d-flex gap-2 align-items-center justify-content-end">
                                <label htmlFor="">Fecha:</label>
                                <input
                                    className="form-control form-control-solid w-auto"
                                    placeholder="Pick date rage"
                                    id="kt_daterangepicker_8"
                                />
                            </div>

                            <div className="col-12">
                                <ReactApexChart options={prodCostosP.options} series={prodCostosP.series} type="line" height={400} />
                            </div>
                            <div className="col-12">
                                <ReactApexChart options={prodCostosC.options} series={prodCostosC.series} type="bar" height={400} />
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Resumen;
