/* eslint-disable */

import UseService from "../UseServices";

const CuentasService = {
    //CUENTAS BANCARIAS
    async getCuentasBancarias(data) {
        var resp = await UseService.post("cuentasbancarias/list", data);
        return resp;
    },
    async selectCuentasBancarias(data) {
        var resp = await UseService.post("cuentasbancarias/select", data);
        return resp;
    },
    async addCuentasBancarias(data) {
        var resp = await UseService.post("cuentasbancarias/add", data);
        return resp;
    },
    async updateCuentasBancarias(data) {
        var resp = await UseService.put("cuentasbancarias/update", data);
        return resp;
    },
    async statusCuentasBancarias(data) {
        var resp = await UseService.put("cuentasbancarias/status", data);
        return resp;
    },

    //CASAS CUENTAS
    async getCasasCuentas(data) {
        var resp = await UseService.post("casascuentas/list", data);
        return resp;
    },
    async selectCasasCuentas(data) {
        var resp = await UseService.post("casascuentas/select", data);
        return resp;
    },
    async addCasasCuentas(data) {
        var resp = await UseService.post("casascuentas/add", data);
        return resp;
    },
    async updateCasasCuentas(data) {
        var resp = await UseService.put("casascuentas/update", data);
        return resp;
    },
    async statusCasasCuentas(data) {
        var resp = await UseService.put("casascuentas/status", data);
        return resp;
    },
};

export default CuentasService;