/* eslint-disable */

import UseService from "../UseServices";

const AuthService = {
    async login(data) {
        var resp = await UseService.post("auth", data);
        return resp;
    }
};
export default AuthService;