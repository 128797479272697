/* eslint-disable */
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import CuentasService from "../../../api/services/cuentasServices";
import ConstantesService from "../../../api/services/constantesService";
import { useForm } from "react-hook-form";
import { FaPencilAlt, FaSync } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Main from "../../../layout/main";
import { useUserContext } from "../../../Providers/UserProvider";
//data estatica
const estados = [
    {
        text: "Todos",
        value: "2",
    },
    {
        text: "Activo",
        value: "0",
    },
    { text: "Inactivo", value: "1" },
];
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipC = (
    <Tooltip id="tooltipC">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const tipoMoneda = [
    {
        text: "Dolares",
        value: "1",
    },
    {
        text: "Moneda Local",
        value: "2",
    },
];
// Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach((tooltipNode) => new Tooltip(tooltipNode));
const Bancarias = () => {
    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/cuentas", name: "Cuentas", active: false },
        { path: "/bancarias", name: "Bancarias", active: false },
    ];

    //COLUMNAS
    const columns1 = [
        {
            name: "id_cuentabancarias",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "nom_titular",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "banco",
            label: "BANCO",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "MONEDA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>
                            {value == 1 ? "DOLARES" : "Moneda local"}
                        </span>
                    );
                },
            },
        },
        {
            name: "nro_cuenta",
            label: "NRO CTA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "nro_cci",
            label: "CCI",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${value == 1 ? "badge-success" : "badge-warning"}`}
                        >
                            {value == 0 ? "Desactivado" : value == 1 ? "Activo" : "En Alerta"}
                        </span>
                    );
                },
            },
        },
        {
            name: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-icon btn-sm" onClick={() => editCuenta(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipC}>
                                <button className="btn btn-danger btn-icon btn-sm" onClick={() => handleMyState(tableMeta)}>
                                    <FaSync></FaSync>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    //Configurando hooks
    const { user } = useUserContext();
    const [bancos, setBancos] = useState([]);
    const [estadoSelect, setEstadoSelect] = useState("2");
    const [items, setItems] = useState([]);
    const [bancosList, setBancosList] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    //DATOS
    const refreshToken = localStorage.getItem("refreshToken");
    // //console.log('refreshToken',refreshToken)
    const body = {
        refreshToken: "Bearer " + refreshToken,
    };
    const bodyBank = {
        idagrupador: 1,
        refreshToken: "Bearer " + refreshToken,
    };

    //TRAER DATA
    const dataSelect = async () => {
        const bank = await ConstantesService.getConstXAgrupadores(bodyBank);
        // console.log("bank", bank);
        if (bank.status) {
            setBancosList(bank.data[0]);
        }
    };
    const showData = async () => {
        setLoading(true);
        const resp = await CuentasService.getCuentasBancarias(body);
        if (resp.status && resp.data[0] != null) {
            if (resp.data[0].length > 0) {
                setItems(resp.data[0]);
            } else {
                setItems([]);
            }
        }
        // console.log("resp", resp);
        setLoading(false);
    };
    const FilterData = () => {
        //console.log("estadoSelect", estadoSelect);
        //console.log("items", items);
        if (estadoSelect == "2") {
            setBancos(items);
        }
        if (estadoSelect == "1") {
            let filter = items.filter((e) => e.estado == 0);
            setBancos(filter);
        }
        if (estadoSelect == "0") {
            let filter = items.filter((e) => e.estado == 1);
            setBancos(filter);
        }
    };

    //MOUNTED
    useEffect(() => {
        showData();
        dataSelect();
        //init tooltip
    }, []);

    //OPTIONS DEL DATATABLE
    const options = {
        print: false,
        selectableRows: "none",
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedSelectColumn: false,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Eliminar",
                deleteAria: "Delete Selected Rows",
            },
        },
        // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        //     <div>
        //         <FaTrash className="mx-4 cursor-pointer" onClick={() => handleMyState(selectedRows)} />
        //     </div>
        // ),
    };
    //ELIMINAR
    const handleMyState = (data) => {
        let datosCB = {
            id: data.rowData[0],
            estado: data.rowData[6],
        };
        // console.log("datosCB", datosCB);
        Swal.fire({
            icon: "error",
            title: `¿Seguro que desea ${data.rowData[6] == 1 ? "desactivar" : "activar"} la cuenta seleccionada?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosCB);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idcuentabancaria: data.id,
            estado: data.estado == 1 ? 0 : 1,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEstado = await CuentasService.statusCuentasBancarias(body);
        // console.log("respEstado", respEstado);
        if (respEstado.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        showData();
    };

    //EDITAR CUENTA
    const editCuenta = (data) => {
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        // console.log("itemSelectE", itemSelectE);
        setIsOpe(false);
        setValue("nomtitular", itemSelectE.nom_titular);
        setValue("idcuentabancaria", itemSelectE.id_cuentabancarias);
        setValue("nrocuenta", itemSelectE.nro_cuenta);
        setValue("tipo", itemSelectE.tipo);
        setValue("nrocci", itemSelectE.nro_cci);
        $("#kt_modal_add_bancaria").modal("show");
    };

    //AGREGAR CUENTA
    const addCuenta = () => {
        setIsOpe(true);
        setValue("nomtitular", "");
        // let idBanco = bancosList.filter((key) => key.nombre === "BBVA");
        // setValue("idbanco", idBanco[0].id_constante);
        setValue("nrocuenta", "");
        setValue("nrocci", "");
        //console.log("");
        $("#kt_modal_add_bancaria").modal("show");
    };

    //SETEAR BANCO SELECCIONADO
    const resetForm = () => {
        reset({
            idbanco: "",
            nomtitular: {
                label: "",
                value: "",
            },
            nrocci: "",
            nrocuenta: "",
        });
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading2(true);
        // console.log(data);
        const bodyAdd = {
            idbanco: data.idbanco,
            nomtitular: data.nomtitular,
            nrocuenta: data.nrocuenta,
            nrocci: data.nrocci,
            tipo: parseInt(data.tipo),
            refreshToken: "Bearer " + refreshToken,
        };
        const bodyUpd = {
            idcuentabancaria: data.idcuentabancaria,
            nomtitular: data.nomtitular,
            nrocuenta: data.nrocuenta,
            nrocci: data.nrocci,
            tipo: parseInt(data.tipo),
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("bodyAdd", bodyAdd);
        if (isOpe) {
            const respAdd = await CuentasService.addCuentasBancarias(bodyAdd);
            // console.log("respAdd", respAdd);
            if (respAdd.status == 202) {
                Swal.fire({
                    title: "Registrado Fallido",
                    icon: "warning",
                    text: "Datos registrados con anterioridad.",
                });
            } else if (respAdd.status) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
        } else {
            const respUpd = await CuentasService.updateCuentasBancarias(bodyUpd);
            // console.log("respUpd", respUpd);
            if (respUpd.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
        setLoading2(false);
        showData();
        // // resetForm()
        $("#kt_modal_add_bancaria").modal("hide");
    };

    return (
        <Main>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 pt-6">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1"></div>
                                </div>
                                <div className="card-toolbar w-100">
                                    <div className="d-flex w-100 justify-content-between h-100">
                                        <div className="row mx-0 w-100">
                                            <div className="col-6">
                                                <div className="w-100 d-flex align-items-center align-items-md-start justify-content-center justify-content-md-start">
                                                    <div className="w-100 d-flex align-items-center gap-2">
                                                        <label htmlFor="casas-estado" className="form-label">
                                                            Estado
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid w-100 w-sm-50"
                                                            name="estados"
                                                            id="casas-estado"
                                                            onChange={(e) => setEstadoSelect(e.target.value)}
                                                        >
                                                            {/* <option value="">Seleccionar</option> */}
                                                            {estados.map((estado) => (
                                                                <option key={estado.value} value={estado.value}>
                                                                    {estado.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <button type="button" className="btn btn-primary mt-auto me-5" onClick={FilterData}>
                                                            Filtrar
                                                        </button>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div className="col-6 ">
                                                <div className="d-flex align-items-end justify-content-center justify-content-md-end w-100">
                                                    <button type="button" className="btn btn-primary mt-auto me-5" onClick={showData}>
                                                        <i className="bi bi-search" />
                                                        Buscar
                                                    </button>
                                                    <button type="button" className="btn btn-primary mt-auto" onClick={addCuenta}>
                                                        <i className="bi bi-plus-circle fs-4 me-2" /> Agregar Cuenta
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={<span className="fw-bolder fs-14">Lista de Cuentas Bancarias</span>}
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_add_bancaria" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_add_bancaria_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_add_bancaria_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Cuenta Bancaria</h2>
                                            <div
                                                id="kt_modal_add_bancaria_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_add_bancaria_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_add_bancaria_header"
                                                data-kt-scroll-wrappers="#kt_modal_add_bancaria_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                                    <label className="fs-6 fw-semibold mb-2" htmlFor="">
                                                        <span className="required">Nombre</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Nombres"
                                                        autocomplete="off"
                                                        name="nombre"
                                                        {...register("nomtitular", { required: true })}
                                                    />
                                                    {errors.nomtitular?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    {isOpe ? (
                                                        <>
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Tipo Moneda</span>
                                                            </label>
                                                            <select
                                                                className="select2-selection select2-selection--single form-select form-select-solid"
                                                                name="tipo"
                                                                {...register("tipo", {
                                                                    required: true,
                                                                })}
                                                            >
                                                                {tipoMoneda.map((moneda) => (
                                                                    <option key={moneda.value} value={moneda.value}>
                                                                        {moneda.text}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {errors.tipo?.type === "required" && (
                                                                <p className="text-danger">El campo es requerido</p>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {isOpe ? (
                                                        <>
                                                            {" "}
                                                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                                <span className="">Banco</span>
                                                            </label>
                                                            <select
                                                                className="select2-selection select2-selection--single form-select form-select-solid"
                                                                name="items"
                                                                {...register("idbanco", { required: true })}
                                                            >
                                                                <option value="">Seleccionar</option>
                                                                {bancosList.map((estado) => (
                                                                    <option key={estado.id_constante} value={estado.id_constante}>
                                                                        {estado.nombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {errors.idbanco?.type === "required" && (
                                                                <p className="text-danger">El campo es requerido</p>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Nro cuenta</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Nro cuenta"
                                                        autocomplete="off"
                                                        name="nro_cuenta"
                                                        {...register("nrocuenta", { required: true })}
                                                    />
                                                    {errors.nrocuenta?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">CCI</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="CCI"
                                                        autocomplete="off"
                                                        name="cci"
                                                        {...register("nrocci", { required: true })}
                                                    />
                                                    {errors.nrocci?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    <div className="fv-plugins-message-container invalid-feedback"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_add_bancaria_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_add_bancaria_submit" className="btn btn-primary">
                                                {loading2 ? (
                                                    <span className="indicator-label">
                                                        Cargando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Bancarias;
