/* eslint-disable */

import UseService from "../UseServices";

const CuponesService = {
    async getCupones(data) {
        var resp = await UseService.post("cupones/list", data);
        return resp;
    },
    async addCupon(data) {
        var resp = await UseService.post("cupones/create", data);
        return resp;
    },
    async updateCupon(data) {
        var resp = await UseService.put("cupones/update", data);
        return resp;
    },
    async statusCupon(data) {
        var resp = await UseService.put("cupones/status", data);
        return resp;
    },
};
export default CuponesService;