/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import OperadoresService from "../../api/services/operadoresService";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaSync, FaEye, FaEyeSlash } from "react-icons/fa";
const schemaRegistrar = yup.object({
    nombres: yup
        .string()
        .required("Este campo es requerido")
        .matches(/^[a-zA-Z\s]*$/, "No debe de contener numeros"),
    apellidos: yup
        .string()
        .required("Este campo es requerido")
        .matches(/^[aA-zZ\s]+$/, "No debe de contener numeros"),
    email: yup.string().required("Este campo es requerido").email("Debe de ser un email"),
    documento: yup
        .number()
        .required("Este campo es requerido")
        .typeError("No es un numero")
        .test("len", "Debe tener min 8 caracteres", (val) => {
            if (val == undefined) {
                return true;
            }
            return val.toString().length >= 8;
        }),
    password: yup.string().required("Ingrese una contraseña"),
});
//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const tipos = [
    {
        label: "Live",
        value: 1,
    },
    {
        label: "Prematch",
        value: 2,
    },
    {
        label: "Live y Prematch",
        value: 3,
    },
];
const Operadores = () => {
    /* USE FORM */
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaRegistrar),
    });
    /* USE FORM */

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/operadores", name: "Operadores", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_persona",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "nombres",
            label: "Nombres",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "apellidos",
            label: "Apellidos",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "correo",
            label: "Correo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "estado",
            label: "Estado",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${value == 1 ? "badge-success" : "badge-warning"}`}
                        >
                            {value == 0 ? "Inactivo" : value == 1 ? "Activo" : "En Alerta"}
                        </span>
                    );
                },
            },
        },
        {
            name: "Acciones",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-icon btn-sm" onClick={() => editPlan(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipP}>
                                <button className="btn btn-danger btn-icon btn-sm" onClick={() => handleMyState(tableMeta)}>
                                    <FaSync></FaSync>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [loadingC, setLoadingC] = useState(false);
    const [viewPass, setViewPass] = useState(false);
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");

    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respOpe = await OperadoresService.getOperadores(body);
        // console.log("respOpe", respOpe);
        if (respOpe.status) {
            setItems(respOpe.data[0]);
        }
        setLoading(false);
    };
    //EDITAR CUENTA
    const editPlan = (data) => {
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        // console.log("itemSelectE", itemSelectE);
        setIsOpe(false);
        setValue("idoperador", itemSelectE.id_persona);
        setValue("nombres", itemSelectE.nombres);
        setValue("apellidos", itemSelectE.apellidos);
        setValue("documento",12345678)
        setValue("email", itemSelectE.correo);
        $("#kt_modal_operador").modal("show");
    };
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosCB = {
            idoperador: data.rowData[0],
            estado: data.rowData[4],
        };
        // console.log("datosCB", datosCB);
        Swal.fire({
            icon: "warning",
            title: `¿Seguro que desea ${data.rowData[5] == 1 ? "desactivar" : "activar"} el operador seleccionado?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosCB);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idoperador: data.idoperador,
            estado: data.estado == 1 ? 0 : 1,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEstado = await OperadoresService.statusOperador(body);
        // console.log("respEstado", respEstado);
        if (respEstado.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    //AGREGAR CUENTA
    const addPlan = () => {
        setIsOpe(true);
        setValue("nombres", "");
        setValue("apellidos", "");
        setValue("email", "");
        setValue("documento", null);
        setValue("password", "");
        //console.log("");
        $("#kt_modal_operador").modal("show");
    };

    //SETEAR BANCO SELECCIONADO
    const resetForm = () => {
        reset({
            nombre: "",
            tipo: {
                label: "",
                value: "",
            },
            dias: null,
            monto: null,
            descripcion: "",
        });
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoadingC(true);
        // console.log(data);
        const bodyAdd = {
            nombres: data.nombres,
            apellidos: data.apellidos,
            correo: data.email,
            contra: data.password,
            documento: String(data.documento),
            refreshToken: "Bearer " + refreshToken,
        };
        const bodyUpd = {
            idoperador: data.idoperador,
            contra: data.password,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log('bodyUpd',bodyUpd)
        if (isOpe) {
            const respAdd = await OperadoresService.addOperador(bodyAdd);
            // console.log('respAdd',respAdd)
            if (respAdd.status == 202) {
                Swal.fire({
                    title: "Advertencia",
                    icon: "warning",
                    text: "Los datos digitados ya han sido registrado con anterioridad.",
                });
            } else if (respAdd.status) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
        } else {
            const respAdd = await OperadoresService.updateOperador(bodyUpd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
        setLoadingC(false);
        getData();
        $("#kt_modal_operador").modal("hide");
    };
    const onlyNumber = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card shadow p-0">
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex w-100 justify-content-start align-items-center h-100">
                                            <span className="fw-bolder fs-14 me-5">Lista de Operadores</span>
                                            <button type="button" className="btn btn-primary btn-sm mt-auto" onClick={addPlan}>
                                                <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                            </button>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_operador" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_operador_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_operador_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Operador</h2>
                                            <div
                                                id="kt_modal_operador_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_operador_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_operador_header"
                                                data-kt-scroll-wrappers="#kt_modal_operador_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mb-7 fv-plugins-icon-container">
                                                    {isOpe ? (
                                                        <>
                                                            <div className="col-12">
                                                                <label className="fs-6 fw-semibold mb-2" htmlFor="">
                                                                    <span className="">Nombres</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-solid"
                                                                    placeholder="Nombres"
                                                                    name="nombres"
                                                                    {...register("nombres", { required: true })}
                                                                />
                                                                <div className="fv-plugins-message-container invalid-feedback">
                                                                    {errors.nombres?.type === "required" && errors.nombres.message}
                                                                    {errors.nombres?.type === "matches" && errors.nombres.message}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="fs-6 fw-semibold mb-2">Apellidos</label>
                                                                <input
                                                                    className="form-control form-control-solid"
                                                                    type="text"
                                                                    placeholder="Apellidos"
                                                                    name="apellidos"
                                                                    autoComplete="off"
                                                                    {...register("apellidos")}
                                                                />
                                                                <div className="fv-plugins-message-container invalid-feedback">
                                                                    {errors.apellidos?.type === "required" && errors.apellidos.message}
                                                                    {errors.apellidos?.type === "matches" && errors.apellidos.message}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="fs-6 fw-semibold mb-2">Correo</label>
                                                                <input
                                                                    className="form-control form-control-solid"
                                                                    type="email"
                                                                    placeholder="Correo Electrónico"
                                                                    name="email"
                                                                    autoComplete="off"
                                                                    {...register("email")}
                                                                />
                                                                <div className="fv-plugins-message-container invalid-feedback">
                                                                    {errors.email?.type === "required" && errors.email.message}
                                                                    {errors.email?.type === "email" && errors.email.message}
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <label className="fs-6 fw-semibold mb-2">Documento</label>
                                                                <input
                                                                    className="form-control form-control-solid"
                                                                    type="telf"
                                                                    placeholder="Documento"
                                                                    name="documento"
                                                                    autoComplete="off"
                                                                    onKeyDown={(e) => onlyNumber(e)}
                                                                    minLength={8}
                                                                    {...register("documento")}
                                                                />
                                                                <div className="fv-plugins-message-container invalid-feedback">
                                                                    {errors.documento?.type === "len" && errors.documento.message}
                                                                    {errors.documento?.type === "required" && errors.documento.message}
                                                                    {errors.documento?.type === "typeError" && errors.documento.message}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold mb-2">Contraseña</label>
                                                        <div className="input-group input-group-sm mb-3">
                                                            <input
                                                                className="form-control form-control-solid"
                                                                type={viewPass ? "text" : "password"}
                                                                placeholder="Contraseña"
                                                                name="password"
                                                                autoComplete="off"
                                                                {...register("password")}
                                                            />
                                                            <span className="input-group-text icon_pass" id="basic-addon1">
                                                                {viewPass ? (
                                                                    <span onClick={() => setViewPass(false)} className="cursor-pointer">
                                                                        <FaEye></FaEye>
                                                                    </span>
                                                                ) : (
                                                                    <span onClick={() => setViewPass(true)} className="cursor-pointer">
                                                                        <FaEyeSlash></FaEyeSlash>
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="fv-plugins-message-container invalid-feedback">
                                                            {errors.password?.type === "required" && errors.password.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_operador_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_operador_submit" className="btn btn-primary">
                                                {loadingC ? (
                                                    <span className="indicator-label">
                                                        Registrando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Operadores;
