/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Navbar, Sidebar } from "../Components";
import { useUserContext } from "../Providers/UserProvider";
import { useLocation } from "react-router-dom";
const Main = ({ children, userStake, userTC }) => {
  let location = useLocation();
  const { user, setUser } = useUserContext();
  const [stake, setStake] = useState(user.stake)
  const [estilo, setEstilo] = useState("content")
  const [userTc, setUserTc] = useState(user.tipocambio)
  const dataStake = (data) => {
    setStake(data)
    // console.log('props',props)
    userStake(data)
  }
  useEffect(() => {
    // console.log('location', location)
    if (location.pathname == "/arbitraje") {
      setEstilo("content_scann")
    } else{
      setEstilo("content")
    }
  }, [location]);
  const dataTC = (data) => {
    setUserTc(data)
    userTC(data)
  }
  return (
    <>
      <div className="">
        {/* <Navbar userStake={(datos) => setStake(datos)} userTc={(datos) => setUserTc(datos)} /> */}
        <Navbar userStake={(datos) => dataStake(datos)} userTc={(datos) => dataTC(datos)} />
      </div>
      <div className="page d-flex flex-row flex-column-fluid">
        <Sidebar />
        <div id="kt_wrapper" className="wrapper d-flex flex-column flex-row-fluid">

          <div id="kt_content" className={`fs-6 d-flex flex-column flex-column-fluid pb-20 ${estilo}`} style={{ padding: "10px 0" }}>
            <div id="kt_post" className="post fs-6 d-flex flex-column-fluid ">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Main