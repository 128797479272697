/* eslint-disable */

import UseService from "../UseServices";

const PlanesService = {
    async getPlanes(data) {
        var resp = await UseService.post("planes/list", data);
        return resp;
    },
    async addPlan(data) {
        var resp = await UseService.post("planes/create", data);
        return resp;
    },
    async updatePlan(data) {
        var resp = await UseService.put("planes/update", data);
        return resp;
    },
    async statusPlan(data) {
        var resp = await UseService.put("planes/status", data);
        return resp;
    },
};
export default PlanesService;