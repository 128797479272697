/* eslint-disable */

import UseService from "../UseServices";

const FranquiciasService = {
    async getFranquicias(data) {
        var resp = await UseService.post("franquicias/list", data);
        return resp;
    },
    async addFranquicia(data) {
        var resp = await UseService.post("franquicias/add", data);
        return resp;
    },
    async updateFranquicia(data) {
        var resp = await UseService.put("franquicias/update", data);
        return resp;
    },
    async statusFranquicia(data) {
        var resp = await UseService.put("franquicias/status", data);
        return resp;
    },

    //ADMIN
    async getAdminFranquicias(data) {
        var resp = await UseService.post("adminfranquicias/list", data);
        return resp;
    },
    async addAdminFranquicia(data) {
        var resp = await UseService.post("adminfranquicias/add", data);
        return resp;
    },
    async statusAdminFranquicia(data) {
        var resp = await UseService.put("adminfranquicias/status", data);
        return resp;
    },
};
export default FranquiciasService;