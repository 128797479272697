/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import MovimientosService from "../../api/services/movimientosService";
import OperadoresService from "../../api/services/operadoresService";
import CuentasService from "../../api/services/cuentasServices";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheckCircle, FaHistory } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import Select from "react-select";
import moment from "moment/moment";
//TOOLTIPS
const tooltipR = (
  <Tooltip id="tooltipE">
      <strong>Reestablecer</strong>
  </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Confirmar</strong>
    </Tooltip>
);
/* DATA ESTATICA */
const today = new Date();
const tipos = [
    {
        text: "Todos",
        value: "Todos",
    },
    {
        text: "Recargas",
        value: "Recarga",
    },
    {
        text: "Retiros",
        value: "Retiro",
    },
];
/* FIN */
const Movimientos = () => {
    /* -----USEFORM----*/
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        reset: reset2,
        setValue: setValue2,
        formState: { errors: errors2 },
    } = useForm();

    /* -----FIN USEFORM----*/

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/confirmados", name: "Movimientos Confirmados", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_movimiento",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "titular",
            label: "Titular",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "casa",
            label: "Casa",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "MONEDA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>
                            {value == 1 ? "DOLARES" : "Moneda local"}
                        </span>
                    );
                },
            },
        },
        {
            name: "operador",
            label: "Operador",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "monto",
            label: "DOLARES/MONEDA LOCAL",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>$ {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "cambio_monto",
            label: "CONVERTIDO (DOLARES)",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>$ {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "fecha",
            label: "Fecha",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{moment(value).format("DD-MM-yyyy")}</span>;
                },
            },
        },
        {
            name: "fechaconfirmacion",
            label: "Fecha Confirmado",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return value != null ? (
                        <span>{moment(value).format("DD-MM-yyyy")}</span>
                    ) : (
                        <span className="badge badge-square badge-lg badge-warning p-4"> Sin Confirmar</span>
                    );
                },
            },
        },
        {
          name: "confirmacion",
          label: "",
          options: {
              display: false,
          },
      },
        {
            name: "Acciones",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            {tableMeta.rowData[9] == 2 ? (
                                <div className="d-flex gap-3 flex-wrap justify-content-center">
                                    <OverlayTrigger placement="top" overlay={tooltipP}>
                                        <button className="btn btn-primary btn-sm btn-icon" onClick={() => handleMyState(tableMeta)}>
                                            <FaCheckCircle></FaCheckCircle>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            ) : (
                                <div className="d-flex gap-3 flex-wrap justify-content-center">
                                    <OverlayTrigger placement="top" overlay={tooltipR}>
                                        <button className="btn btn-warning btn-sm btn-icon" onClick={() => restMyState(tableMeta)}>
                                            <FaHistory></FaHistory>
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const { user } = useUserContext();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [operadores, setOperadores] = useState([]);
    const [idOpe, setIdOpe] = useState([]);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [cuentasUsuario, setcuentasUsuario] = useState([]);
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        f_fin: moment(today).format("yyyy-MM-DD"),
        tipo: "Todos",
    });
    const [isOpe, setIsOpe] = useState(false);
    const [fecha, setFecha] = useState(moment(today).format("yyyy-MM-DD"));
    const [fechaM, setFechaM] = useState(moment(today).format("yyyy-MM-DD"));
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
            border: "0px",
            fontWeight: "500",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "#2B2B3D"
                        : "#D0D2D7"
                    : isFocused
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "#2B2B3D"
                        : "#F4F6FA"
                    : localStorage.getItem("userTheme") === "dark"
                    ? "#1b1b29"
                    : "#f9f9f9",
                color: isSelected
                    ? localStorage.getItem("userTheme") === "dark"
                        ? "white"
                        : "black"
                    : localStorage.getItem("userTheme") === "dark"
                    ? "white"
                    : "black",
                fontWeight: "500",
                cursor: "pointer",
            };
        },
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles) => ({
            ...styles,
            color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
            fontWeight: "500",
        }),
        valueContainer: (styles) => ({ ...styles, padding: "0.85rem" }),
        menu: (styles) => ({
            ...styles,
            backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
        }),
    };
    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fechainicio: filtros.f_inicio,
            fechafin: filtros.f_fin,
        };
        const resMovimientos = await MovimientosService.getConfirm(body);
        if (resMovimientos.status) {
            let tot = 0;
            setItems(resMovimientos.data[0].lista);
            for (let index = 0; index < resMovimientos.data[0].lista.length; index++) {
                const element = resMovimientos.data[0].lista[index];
                tot += parseFloat(element.monto);
            }
            setTotal(tot);
        }
        // console.log("resMovimientos", resMovimientos);
        setLoading(false);
    };
    //EDITAR DATA
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosMov = {
            idmovimiento: data.rowData[0],
        };
        // console.log("datosMov", datosMov);
        Swal.fire({
            icon: "warning",
            title: `¿Desea confirmar este movimiento?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosMov);
            }
        });
    };
    const restMyState = (data) => {
        // console.log("data", data);
        let datosMov = {
            idmovimiento: data.rowData[0],
        };
        // console.log("datosMov", datosMov);
        Swal.fire({
            icon: "warning",
            title: `¿Desea reestablecer este movimiento?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmRest(datosMov);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idmovimiento: data.idmovimiento,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resMov = await MovimientosService.saveConfirm(body);
        // console.log("resMov", resMov);
        if (resMov.status) {
            Swal.fire("Confirmado!", "", "success");
        } else if (resMov.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: resMov.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    const confirmRest = async (data) => {
        const body = {
            idmovimiento: data.idmovimiento,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resMov = await MovimientosService.restConfirm(body);
        // console.log("resMov", resMov);
        if (resMov.status) {
            Swal.fire("Reestablecido!", "", "success");
        } else if (resMov.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: resMov.data.message,
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    //EDITAR CUENTA
    const editMovimiento = (data) => {
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        setValue2("id_movimiento", itemSelectE.id_movimiento);
        if (itemSelectE.monto < 0) {
            setValue2("montoM", itemSelectE.monto * -1);
        } else {
            setValue2("montoM", itemSelectE.monto);
        }
        setValue2("observacion", itemSelectE.observacion);
        $("#modal_movs_edit_mov").modal("show");
    };
    //AGREGAR CUENTA
    const addMovimiento = () => {
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        setValue("fechaM", moment(today).format("yyyy-MM-DD"));
        setValue("casaCuenta", "");
        setValue("monto", "");
        setValue("observacion", "");
        $("#modal_movs_add_mov").modal("show");
    };
    //RESETEAR FORMS
    const resetForm = () => {
        reset({
            fechaM: moment(today).format("yyyy-MM-DD"),
            casaCuenta: "",
            monto: "",
            observacion: "",
        });
    };
    const resetForm2 = () => {
        reset2({
            montoM: "",
            observacion: "",
        });
    };
    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading2(true);
        const datos = data;
        const bodyAdd = {
            idoperador: idOpe,
            idcasacuenta: parseInt(datos.casaCuenta),
            monto: parseFloat(datos.monto),
            fecha: datos.fechaM,
            tipo: user.frqpoint == 0 ? datos.tipo : "recarga",
            observacion: datos.observacion,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("bodyAdd", bodyAdd);
        const respAdd = await MovimientosService.addMovimiento(bodyAdd);
        $("#modal_movs_add_mov").modal("hide");
        // console.log("respAdd", respAdd);
        if (respAdd.status == true) {
            Swal.fire({
                title: "Registrado",
                icon: "success",
                text: "Datos registrados con éxito.",
            });
        } else if (respAdd.status == 202) {
            Swal.fire({
                title: "Error!",
                icon: "warning",
                text: "¡El registro existe previamente o datos incorrectos!",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al registrar los datos.",
            });
        }
        getData();
        setLoading2(false);

        // resetForm();
    };
    const onSubmit2 = async (data) => {
        setLoading3(true);
        const datos = data;

        const bodyUpd = {
            idmovimiento: parseInt(datos.id_movimiento),
            monto: parseFloat(datos.montoM),
            observacion: datos.observacion,
            refreshToken: "Bearer " + refreshToken,
        };
        const respAdd = await MovimientosService.updateMovimiento(bodyUpd);
        $("#modal_movs_edit_mov").modal("hide");
        if (respAdd.status == true) {
            Swal.fire({
                title: "Actualizado!",
                icon: "success",
                text: "Datos actualizados con éxito.",
            });
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
        setLoading3(false);
        // resetForm();
    };

    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
        resetForm();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // onChange: function (selectedDates, dateStr, instance) {
            //     setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
            // },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_2").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // onChange: function (selectedDates, dateStr, instance) {
            //     setFiltros({ ...filtros, f_fin: moment(dateStr).format("yyyy-MM-DD") });
            // },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_8").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setFechaM(moment(dateStr).format("yyyy-MM-DD"));
                setValue("fechaM", moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-3 ">
                                                <label htmlFor="" className="form-label">
                                                    Inicio
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                    onInput={(e) => setFiltros({ ...filtros, f_inicio: moment(e.target.value).format("yyyy-MM-DD") })}
                                                />
                                            </div>
                                            <div className="col-md-3 ">
                                                <label htmlFor="" className="form-label">
                                                    Fin
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Fin"
                                                    id="kt_datepicker_2"
                                                    onInput={(e) => setFiltros({ ...filtros, f_fin: moment(e.target.value).format("yyyy-MM-DD") })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="d-flex align-content-end justify-content-xl-start justify-content-start h-100  w-100">
                                                    <button
                                                        disabled={loading}
                                                        type="button"
                                                        className="btn btn-primary mt-auto me-3"
                                                        onClick={getData}
                                                    >
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span className="indicator-label ">
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex gap-3 px-4">
                                            <span className="fw-bolder fs-14">Lista de Movimientos Confirmados</span> |{" "}
                                            <span className="fw-bolder">Total: {parseFloat(total).toFixed(2)}</span>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Movimientos;
