/* eslint-disable */

import UseService from "../UseServices";

const MontosService = {
    async getMontos(data) {
        var resp = await UseService.post("montoscuentas/list", data);
        return resp;
    },
    async addMonto(data) {
        var resp = await UseService.post("montoscuentas/add", data);
        return resp;
    },
    async updateMonto(data) {
        var resp = await UseService.put("montoscuentas/update", data);
        return resp;
    },
    async statusMonto(data) {
        var resp = await UseService.put("montoscuentas/status", data);
        return resp;
    },
    async reportMonto(data) {
      var resp = await UseService.put("montoscuentas/report", data);
      return resp;
    },
    async autoMonto(data) {
        var resp = await UseService.post("montoscuentas/autogenerar", data);
      return resp;
    },
};
export default MontosService;
