/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import ConexionesService from "../../../api/services/conexionesService";

import moment from "moment/moment";

/* DATA ESTATICA */
const today = new Date();

//TOOLTIPS

const ConexionesUsuarios = () => {

  /* -----BREADCRUMBS----*/
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/conexiones-usuarios", name: "Conexiones", active: false },
    { path: "/conexiones-usuarios", name: "Usuarios", active: true },
  ];
  /* -----FIN BREADCRUMBS----*/

  /* -----MUIDATATABLE----*/
  //COLUMNAS
  const columns1 = [
    {
      name: "id_persona",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "organizacion",
      label: "Organización",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "ciudad",
      label: "Ciudad",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "region",
      label: "Region",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "pais",
      label: "Pais",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "svg",
      label: "Avatar",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <img src={value} alt="value" className=" h-25px" />;
        },
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{moment(value).format("yyyy-MM-DD")}</span>;
        },
      },
    },
  ];
  const options = {
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: false,
    download: true,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Elementos por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscador",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Eliminar fila seleccionada",
      },
      centeredTableHead: {
        "& > span": {
          justifyContent: "center",
        },
      },
    },
  };

  /* -----FIN MUIDATATABLE----*/

  /* -----HOOKS----*/
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [isOpe, setIsOpe] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [filtros, setFiltros] = useState({
    f_inicio: moment(today).format("yyyy-MM-DD"),
    f_fin: moment(today).format("yyyy-MM-DD"),
  });
  /* -----FIN HOOKS----*/

  /* -----DATOS----*/
  const refreshToken = localStorage.getItem("refreshToken");

  /* -----FIN DATOS----*/

  /* -----METODOS----*/
  //LISTAR DATA
  const getData = async () => {
    setLoading(true);
    const body = {
      refreshToken: "Bearer " + refreshToken,
      fechainicio: filtros.f_inicio,
      fechafin: filtros.f_fin,
    };
    const resPlanes = await ConexionesService.getListOperadores(body);
    if (resPlanes.status) {
      setItems(resPlanes.data[0]);
    }
    // console.log("resPlanes", resPlanes);
    setLoading(false);
  };
  /* -----FIN METODOS----*/

  /* -----MOUNTED----*/
  useEffect(() => {
    $("#kt_datepicker_1").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    $("#kt_datepicker_2").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFiltros({ ...filtros, f_fin: moment(dateStr).format("yyyy-MM-DD") });
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, [])
  useEffect(() => {
    getData();
  }, []);

  /* -----FIN MOUNTED----*/
  return (
    <Main>
      <div className="container-xxl mw-100">
        <div className="toolbar" id="kt_toolbar">
          <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
          </div>
        </div>
        <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
          <div className="overlay-wrapper">
            <div className="card shadow p-0">
              <div className="card-header border-0 p-6">
                <div className="card-toolbar w-100">
                  <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                    <div className="me-3 d-flex gap-10 flex-column flex-md-row w-100">
                      <div className="w-100">
                        <label htmlFor="" className="form-label">
                          Inicio
                        </label>
                        <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_1" />
                      </div>
                      <div className="w-100">
                        <label htmlFor="" className="form-label">
                          Fin
                        </label>
                        <input className="form-control form-control-solid" placeholder="Fecha Fin" id="kt_datepicker_2" />
                      </div>
                      <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                        <button disabled={loading} type="button" className="btn btn-primary mt-auto me-3" onClick={getData}>
                          {loading ? (
                            <span className="indicator-label">
                              Buscando...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          ) : (
                            <span className="indicator-label "><i className="bi bi-search" /> Buscar</span>
                          )}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <MUIDataTable
                  title={
                    <div className="d-flex w-100 justify-content-start align-items-center h-100">
                      <span className="fw-bolder fs-14 me-5">Conexiones de Usuarios</span>
                    </div>
                  }
                  data={items}
                  columns={columns1}
                  options={options}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="overlay-custom card-rounded bg-black bg-opacity-50">
              <div className="d-flex flex-column align-items-center mt-10">
                <div className="spinner-border text-white" role="status"></div>
                <span>Buscando datos...</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Main>
  );
};
export default ConexionesUsuarios;
