/* eslint-disable */
const { REACT_APP_URL_API } = process.env;
const { REACT_APP_URL_API_2 } = process.env;
import axios from "./axios";

const UseService = {
    async post(url, data) {
        var request = {
            headers: this.getHeaders(),
            method: "POST",
            url: REACT_APP_URL_API + url,
            data: data,
        };
        const resp = await axios(request);
        return resp;
    },
    async postEvent(url, data) {
        var request = {
            headers: this.getHeaders(),
            method: "POST",
            url: "https://servicesurebets.com:4007/api/" + url,
            data: data,
        };
        const resp = await axios(request);
        return resp;
    },
    async postRest(url, data) {
        var request = {
            headers: data.header,
            method: "POST",
            url: REACT_APP_URL_API + url,
            data: data.body,
        };
        // console.log('request', request)
        const resp = await axios(request);
        return resp;
    },
    //DEL SERVICIO 4001
    async put(url, data) {
        var request = {
            headers: this.getHeaders(),
            method: "PUT",
            url: REACT_APP_URL_API + url,
            data: data,
        };
        const resp = await axios(request);
        return resp;
    },
    getHeaders() {
        return {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
    },
    // getHeaders() {
    //     return {
    //         'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTIsInByaXZpbGVnaW8iOjUsImVzdGFkbyI6MSwiZnJhbnF1aWNpYSI6MSwiaWF0IjoxNjc0MjIwNzA3LCJleHAiOjE2NzQyNjM5MDd9.-GMBgQ9qKeyZNgVZ5ddMhsyuVj8xEkxpGDL0ro-cGRc`
    //     }
    // },
};
export default UseService;