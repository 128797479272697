/* eslint-disable */

import UseService from "../UseServices";

const JugadasService = {
    //APUESTAS
    async getApuestas(data) {
        var resp = await UseService.post("apuestas/list", data);
        return resp;
    },
    async getGraficoApuestas(data) {
        var resp = await UseService.post("apuestas/graph", data);
        return resp;
    },

    //SUREBETS
    async getSurebets(data) {
        var resp = await UseService.post("surebets/list", data);
        return resp;
    },
    async getGraficoSurebets(data) {
        var resp = await UseService.post("surebets/graph", data);
        return resp;
    },
};

export default JugadasService;