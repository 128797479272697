/* eslint-disable */

import UseService from "../UseServices";

const ReportsService = {
    async getCuadre(data) {
        var resp = await UseService.post("reports/cuadre", data);
        return resp;
    },
};
export default ReportsService;