/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import PlanesService from "../../api/services/planesService";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaSync } from "react-icons/fa";
//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const tipos = [
    {
        label: "Live",
        value: 2,
    },
    {
        label: "Prematch",
        value: 1,
    },
    {
        label: "Live y Prematch",
        value: 3,
    },
];
const Planes = () => {
    /* USE FORM */
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();
    /* USE FORM */

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/planes", name: "Planes", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_plan",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "nombre",
            label: "Plan",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "descripcion",
            label: "Descripción",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "dias",
            label: "Dias",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "monto",
            label: "Monto",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>$ {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "tipo",
            label: "Tipo",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${
                                value == 1 ? "badge-secondary" : value == 2 ? "badge-light-dark" : "badge-light-dark"
                            } `}
                        >
                            {value == 2 ? "Live" : value == 1 ? "Prematch" : "Live y Pre."}
                        </span>
                    );
                },
            },
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${value == 1 ? "badge-success" : "badge-warning"}`}
                        >
                            {value == 0 ? "Inactivo" : value == 1 ? "Activo" : "En Alerta"}
                        </span>
                    );
                },
            },
        },
        {
            name: "id_plan",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-icon btn-sm" onClick={() => editPlan(value)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipP}>
                                <button className="btn btn-danger btn-icon btn-sm" onClick={() => handleMyState(value)}>
                                    <FaSync></FaSync>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [loadingC, setLoadingC] = useState(false);
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");

    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const resPlanes = await PlanesService.getPlanes(body);
        if (resPlanes.status) {
            setItems(resPlanes.data[0]);
        }
        // console.log("resPlanes", resPlanes);
        setLoading(false);
    };
    //EDITAR CUENTA
    const editPlan = (data) => {
        let itemSelectE = {};
        itemSelectE = items.find(e => e.id_plan == data)
        // console.log("itemSelectE", items);
        setIsOpe(false);
        let tipo = tipos.filter((key) => key.value === itemSelectE.tipo);
        setValue("idplan", itemSelectE.id_plan);
        setValue("nombre", itemSelectE.nombre);
        setValue("dias", itemSelectE.dias);
        setValue("monto", itemSelectE.monto);
        // if (tipo.length != 0) {
        //   setValue("tipo", {
        //       label: tipo[0].label,
        //       value: tipo[0].value,
        //   });
        // }
        setValue("tipo", itemSelectE.tipo);
        setValue("descripcion", itemSelectE.descripcion);
        $("#kt_modal_add_plan").modal("show");
    };
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        let itemSelectE = {};
        itemSelectE = items.find(e => e.id_plan == data)
        let datosCB = {
            id: itemSelectE.id_plan,
            estado: itemSelectE.estado,
        };
        // console.log("datosCB", datosCB);
        Swal.fire({
            icon: "warning",
            title: `¿Seguro que desea ${itemSelectE.estado == 1 ? "desactivar" : "activar"} el plan seleccionada?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosCB);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idplan: data.id,
            estado: data.estado == 1 ? 0 : 1,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEstado = await PlanesService.statusPlan(body);
        // console.log("respEstado", respEstado);
        if (respEstado.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    //AGREGAR CUENTA
    const addPlan = () => {
        setIsOpe(true);
        setValue("nombre", "");
        setValue("dias", null);
        setValue("monto", null);
        setValue("tipo", 1);
        setValue("descripcion", "");
        //console.log("");
        $("#kt_modal_add_plan").modal("show");
    };

    //SETEAR BANCO SELECCIONADO
    const resetForm = () => {
        reset({
            nombre: "",
            tipo: {
                label: "",
                value: "",
            },
            dias: null,
            monto: null,
            descripcion: ""
        });
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
      setLoadingC(true)
        // console.log(data);
        const bodyAdd = {
            nombre: data.nombre,
            tipo: data.tipo,
            dias: data.dias,
            monto: data.monto,
            descripcion: data.descripcion,
            refreshToken: "Bearer " + refreshToken,
        };
        const bodyUpd = {
            idplan: data.idplan,
            nombre: data.nombre,
            tipo: data.tipo,
            dias: data.dias,
            monto: data.monto,
            descripcion: data.descripcion,
            refreshToken: "Bearer " + refreshToken,
        };
        if (isOpe) {
            const respAdd = await PlanesService.addPlan(bodyAdd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
            //enviar a agregar la cuenta
        } else {
            const respAdd = await PlanesService.updatePlan(bodyUpd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
        setLoadingC(false)
        getData();
        $("#kt_modal_add_plan").modal("hide");
    };
    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
    }, []);

    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card shadow p-0">
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex w-100 justify-content-start align-items-center h-100">
                                            <span className="fw-bolder fs-14 me-5">Lista de Planes</span>
                                            <button type="button" className="btn btn-primary btn-sm mt-auto" onClick={addPlan}>
                                                <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                            </button>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_add_plan" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_add_plan_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_add_plan_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Plan</h2>
                                            <div
                                                id="kt_modal_add_plan_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="kt_modal_add_plan_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#kt_modal_add_plan_header"
                                                data-kt-scroll-wrappers="#kt_modal_add_plan_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="fv-row mb-7 fv-plugins-icon-container">
                                                    <label className="fs-6 fw-semibold mb-2" htmlFor="">
                                                        <span className="required">Nombre</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Nombres"
                                                        name="nombre"
                                                        {...register("nombre", { required: true })}
                                                    />
                                                    {errors.nombre?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}

                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="">Dias</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Dias"
                                                        name="dias"
                                                        onKeyDown={(e) => montoValidate(e)}
                                                        {...register("dias", {
                                                            required: true,
                                                            valueAsNumber: true,
                                                            validate: (value, formValues) => parseFloat(value) > 0,
                                                        })}
                                                    />
                                                    {errors.dias?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    {errors.dias?.type === "validate" && <p className="text-danger">Dias mayor a 0</p>}
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="required">Monto</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Monto"
                                                        name="monto"
                                                        onKeyDown={(e) => montoValidate(e)}
                                                        {...register("monto", {
                                                            required: true,
                                                            valueAsNumber: true,
                                                            validate: (value, formValues) => parseFloat(value) > 0,
                                                        })}
                                                    />
                                                    {errors.monto?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    {errors.monto?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>}
                                                    <label htmlFor="" className="form-label">
                                                        Tipo
                                                    </label>
                                                    <select
                                                        className="select2-selection select2-selection--single form-select form-select-solid"
                                                        name="filtrotipo"
                                                        {...register("tipo", {
                                                            required: true,
                                                        })}
                                                    >
                                                        {tipos.map((tipo) => (
                                                            <option key={tipo.value} value={tipo.value}>
                                                                {tipo.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.tipo?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                    <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                        <span className="required">Descripción</span>
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control form-control-solid"
                                                        placeholder="Descripción"
                                                        name="descripcion"
                                                        rows={4}
                                                        {...register("descripcion", { required: true })}
                                                    />
                                                    {errors.descripcion?.type === "required" && (
                                                        <p className="text-danger">El campo es requerido</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_add_plan_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_add_plan_submit" className="btn btn-primary">
                                                {loadingC ? (
                                                    <span className="indicator-label">
                                                        Registrando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Planes;
