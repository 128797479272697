/* eslint-disable */

import UseService from "../UseServices";

const UtilidadesService = {
    async getUtilidades(data) {
        var resp = await UseService.post("utilidades/", data);
        return resp;
    },
};
export default UtilidadesService;