/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import FiltrosService from "../../api/services/filtrosService";
import ReactApexChart from "react-apexcharts";
const today = new Date();
const tipos = [
    {
        id: "live",
        name: "Live",
    },
    {
        id: "prematch",
        name: "Prematch",
    },
];
const Filtros = () => {
    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/filtros", name: "Filtros", active: true },
    ];
    /* -----HOOKS----*/
    const [loading, setLoading] = useState(false);
    const [filtros, setFiltros] = useState({
        tipo: "live",
    });
    const [state, setState] = useState({
        options: {
            chart: {
                type: "bar",
                height: 550,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "50%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: "Cantidad",
                    color: "#fff",
                },
                tickAmount: 10
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
        series: [],
    });
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");

    const getData = async () => {
        setLoading(true);
        const body = {
            tipo: filtros.tipo,
            refreshToken: "Bearer " + refreshToken,
        };
        let arrayNombres = [];
        let arrayCantidad = [];
        const respApuestas = await FiltrosService.getFiltros(body);
        if (respApuestas.status) {
            if (respApuestas.data != null) {
                for (let index = 0; index < respApuestas.data.length; index++) {
                    const element = respApuestas.data[index];
                    arrayNombres.push(element.casa);
                    arrayCantidad.push(element.cant);
                }
                setState({
                    ...state,
                    options: {
                        xaxis: {
                            categories: arrayNombres,
                        },
                    },
                    series: [
                        {
                            name: "cantidad",
                            data: arrayCantidad,
                        },
                    ],
                });
            } else{
                setState({
                    ...state,
                    options: {
                        xaxis: {
                            categories: arrayNombres,
                        },
                    },
                    series: [
                        {
                            name: "cantidad",
                            data: arrayCantidad,
                        },
                    ],
                });
            }

        }
        setLoading(false);
    };

    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card shadow p-0">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 h-100 justify-content-start">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-2 col-lg-2">
                                                <label htmlFor="status" className="form-label">
                                                    Tipo
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                    name="status"
                                                    id="status"
                                                    onChange={(e) => setFiltros({ ...filtros, tipo: e.target.value })}
                                                >
                                                    {tipos.map((tipo) => (
                                                        <option key={tipo.id} value={tipo.id}>
                                                            {tipo.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-lg-4 mt-5 mt-lg-0">
                                                <div className="d-flex align-content-end justify-content-lg-start justify-content-center h-100  w-100">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mt-auto"
                                                        onClick={() => getData()}
                                                    >
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-2">
                                <div className="row mx-0">
                                    <ReactApexChart options={state.options} series={state.series} type="bar" height={550} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Filtros;
