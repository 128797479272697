/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import { FaMoon, FaSun, FaExpand, FaCompress } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../Providers/UserProvider";

const Navbar = ({ userStake, userTc }) => {
    const navigate = useNavigate();
    const { user, setUser, theme, setTheme, setLocalStorage } = useUserContext();
    const [fullScreen, setFullScreen] = useState(true);
    const [toggle, setToggle] = useState(false);
    const [config, setConfig] = useState({
        stake: user.stake,
        tc: user.tipocambio,
    });

    function changeTheme(tema) {
        if (tema !== theme) {
            document.querySelector("html").dataset.theme = tema;
            setTheme(tema);
            localStorage.userTheme = tema;
        }
    }
    function onFullScreen() {
        setFullScreen(!fullScreen);
        var elem = document.documentElement;
        if (fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
        }
    }
    function handleLogOut() {
        setUser({});
        navigate("/");
        localStorage.removeItem("userCca");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("filterData");
        localStorage.removeItem("pretium");
        localStorage.removeItem("consilium");
        localStorage.removeItem("precio");
        localStorage.removeItem("tempestas");
    }

    useEffect(() => {
        window.KTComponents.init();
    }, []);
    const canToogle = () => {
        var buttonR = document.getElementById("content_logo");
        buttonR.classList.toggle("content_logo");
    };
    const configuration = () => {
        $("#kt_modal_config").modal("show");
    };
    const submit = () => {
        user.stake = parseFloat(config.stake);
        userStake(parseFloat(config.stake));
        userTc(parseFloat(config.tc));
        user.tipocambio = parseFloat(config.tc);
        setLocalStorage("userCca", user);
        $("#kt_modal_config").modal("hide");
        Swal.fire({
            title: "Actualizado",
            icon: "success",
            text: "Datos actualizados con éxito.",
        });
    };
    const resetForm = () => {
        const data = JSON.parse(localStorage.getItem("userCca"));
        setConfig({ ...config, stake: data.stake, tc: data.tipocambio });
    };
    const onlyNumber = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 190 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    const changeThemeT = (val) => {
        if (val == "light") {
            changeTheme("dark");
        } else {
            changeTheme("light");
        }
    };
    const avatarImage = window.location.origin + "/assets/media/avatars/" + user.avatar;
    return (
        <div style={{zIndex: '99'}}>
            <div
                id="kt_header"
                className="header_content pt-2"
            // style={{paddingLeft: toggle? "6rem": "22rem"}}
            // data-kt-sticky="true"
            // data-kt-sticky-name="header"
            // data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    <div className="d-flex align-items-center justify-content-start flex-grow-1 flex-lg-grow-0 w-100">
                        <div className="d-flex align-items-center d-lg-none">
                            <div className="btn btn-icon btn-color-success ms-n2 me-1" id="kt_aside_toggle">
                                <span className="svg-icon svg-icon-1">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            opacity="0.3"
                                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <Link to="/perfil" className="d-lg-none">
                            <img alt="Logo" src="/assets/media/logos/logo_light.png" className="max-h-40px theme-light-show" />
                            <img alt="Logo" src="/assets/media/logos/logo_dark.png" className="max-h-40px theme-dark-show" />
                        </Link>
                        <div
                            className="btn btn-icon w-auto ps-0 btn-color-success d-none d-lg-inline-flex me-2 me-lg-5 active align-items-center"
                            data-kt-toggle="true"
                            data-kt-toggle-state="active"
                            data-kt-toggle-target="body"
                            data-kt-toggle-name="aside-minimize"
                        >
                            <span className="svg-icon svg-icon-2 rotate-180" onClick={canToogle}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="currentColor" />
                                    <path
                                        d="M6.2238 13.2561C5.54282 12.5572 5.54281 11.4429 6.22379 10.7439L10.377 6.48107C10.8779 5.96697 11.75 6.32158 11.75 7.03934V16.9607C11.75 17.6785 10.8779 18.0331 10.377 17.519L6.2238 13.2561Z"
                                        fill="currentColor"
                                    />
                                    <rect opacity="0.3" x="2" y="4" width="2" height="16" rx="1" fill="currentColor" />
                                </svg>
                            </span>
                            <div id="content_logo" className="logo_nav">
                                <div className="aside-logo flex-column-auto px-2" id="kt_aside_logo">
                                    <img alt="Logo" src="/assets/media/logos/logo_light.png" className="max-h-40px theme-light-show" />
                                    <img alt="Logo" src="/assets/media/logos/logo_dark.png" className="max-h-40px theme-dark-show" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100">
                        <div className="d-flex align-items-stretch me-1"></div>
                        <div className="d-flex align-items-stretch flex-shrink-0">
                            <div className="d-flex align-items-center ms-1 ms-lg-2">
                                <button type="button" className="btn btn-icon btn-active-light-primary py-4" onClick={onFullScreen}>
                                    {fullScreen ? (
                                        <FaExpand className="svg-icon"></FaExpand>
                                    ) : (
                                        <FaCompress className="svg-icon"></FaCompress>
                                    )}
                                </button>
                            </div>
                            <div className="d-flex align-items-center ms-1 ms-lg-2">
                                <div
                                    className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                                    data-kt-element="mode"
                                    data-kt-value={localStorage.getItem("userTheme")}
                                    onClick={() => changeThemeT(localStorage.getItem("userTheme"))}
                                // data-kt-menu-placement="bottom-end"
                                >
                                    <span className="svg-icon theme-light-show svg-icon-2">
                                        <FaMoon style={{ height: "1.5rem !important" }} />
                                    </span>
                                    <span className="svg-icon theme-dark-show svg-icon-2">
                                        <FaSun style={{ height: "1.5rem !important" }} />
                                    </span>
                                </div>
                                
                            </div>
                            <div className="d-flex align-items-center ms-2 ms-lg-3" id="kt_header_user_menu_toggle">
                                <div
                                    className="cursor-pointer symbol symbol-35px symbol-lg-35px"
                                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-attach="parent"
                                    data-kt-menu-placement="bottom-end"
                                >
                                    <img alt="Pic" src={avatarImage} />
                                </div>
                                <div
                                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                                    data-kt-menu="true"
                                    style={{ zIndex: "1040 !important" }}
                                >
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                <img alt="Logo" src={avatarImage} />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-block align-items-center text-break fs-5">
                                                    {user.nombres} {user.apellidos}
                                                </div>
                                                <div className="fw-semibold text-muted text-hover-primary d-block fs-7 text-break">
                                                    {user.correo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5">
                                        <Link to="/perfil" className="menu-link px-5">
                                            Mi Perfil
                                        </Link>
                                    </div>                                    
                                    <div className="separator my-2"></div>
                                    <div
                                        className="menu-item px-5"
                                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                        data-kt-menu-placement="left-start"
                                        data-kt-menu-offset="-15px, 0"
                                    >
                                        <div className="menu-link px-5">
                                            <span className="menu-title position-relative">
                                                Lenguaje
                                                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                                                    Español
                                                    <img
                                                        className="w-15px h-15px rounded-1 ms-2"
                                                        src="assets/media/flags/spain.svg"
                                                        alt=""
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            <div className="menu-item px-3">
                                                <a href="account/settings.html" className="menu-link d-flex px-5">
                                                    <span className="symbol symbol-20px me-4">
                                                        <img className="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                                                    </span>
                                                    Español
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="menu-item px-5 my-1">
                                        <div className="menu-link px-5" onClick={() => configuration()}>
                                            Configuracion
                                        </div>
                                    </div> */}
                                    <div className="menu-item px-5">
                                        <div
                                            className="menu-link px-5"
                                            onClick={() => {
                                                handleLogOut();
                                            }}
                                        >
                                            Salir
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="kt_modal_config" tabIndex="-1" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_config_form">
                            <div className="modal-header" id="kt_modal_config_header">
                                <h2 className="fw-bold">Configuración</h2>
                                <div
                                    id="kt_modal_config_close"
                                    className="btn btn-icon btn-sm btn-active-icon-primary"
                                    data-bs-dismiss="modal"
                                    onClick={resetForm}
                                >
                                    <span className="svg-icon svg-icon-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect
                                                opacity="0.5"
                                                x="6"
                                                y="17.3137"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-45 6 17.3137)"
                                                fill="currentColor"
                                            ></rect>
                                            <rect
                                                x="7.41422"
                                                y="6"
                                                width="16"
                                                height="2"
                                                rx="1"
                                                transform="rotate(45 7.41422 6)"
                                                fill="currentColor"
                                            ></rect>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-2 px-lg-17">
                                <div
                                    className="scroll-y me-n7 pe-7"
                                    id="kt_modal_config_scroll"
                                    data-kt-scroll="true"
                                    data-kt-scroll-activate="{default: false, lg: true}"
                                    data-kt-scroll-max-height="auto"
                                    data-kt-scroll-dependencies="#kt_modal_config_header"
                                    data-kt-scroll-wrappers="#kt_modal_config_scroll"
                                    data-kt-scroll-offset="300px"
                                >
                                    <div className="fv-row mb-7 fv-plugins-icon-container">
                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                            <span className="">Stake Base</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder="Stake"
                                            name="stake"
                                            onKeyDown={(e) => onlyNumber(e)}
                                            value={config.stake}
                                            onChange={(e) => setConfig({ ...config, stake: e.target.value })}
                                        />
                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                            <span className="">Tipo de cambio</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control form-control-solid"
                                            placeholder="TC"
                                            value={config.tc}
                                            onKeyDown={(e) => onlyNumber(e)}
                                            name="tc"
                                            onChange={(e) => setConfig({ ...config, tc: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer flex-center">
                                <button
                                    type="reset"
                                    id="kt_modal_config_cancel"
                                    className="btn btn-light me-3"
                                    data-bs-dismiss="modal"
                                    onClick={resetForm}
                                >
                                    Cerrar
                                </button>
                                <button type="button" onClick={submit} id="kt_modal_config_submit" className="btn btn-primary">
                                    <span className="indicator-label">Actualizar</span>
                                    <span className="indicator-progress">
                                        Cargando....
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
