/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import "../auth.css";
import AuthService from "../../../api/services/authService";
import { FaEye, FaEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";

const schema = yup.object({
    email: yup.string().email().required("Este campo es requerido"),
    password: yup.string().required("Este campo es requerido"),
});

const Login = ({ userUpLog }) => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [viewPass, setViewPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const loginSend = async (datos) => {
        setLoading(true);
        // const url = 'https://bullbet.com.pe:4001/api/auth/'
        const body = {
            correo: datos.email,
            contra: datos.password,
        };
        const resp = await AuthService.login(body);
        //console.log("resp", resp);
        if (resp.status == true) {
            resp.data.stake = 100
            window.localStorage.userCca = JSON.stringify(resp.data);
            window.localStorage.accessToken = resp.data.token.toString();
            window.localStorage.refreshToken = resp.data.refreshToken.toString();
            userUpLog(resp.data);
            navigate("/perfil");
        } else {
            //console.log("entra aqui en error");
            setError("password", {
                type: "noLogin",
                message: "Correo o Contraseña incorrecta",
            });
            Swal.fire({
                title: "Error",
                icon: "error",
                text: "Credenciales incorrectas",
            });
        }
        setLoading(false);
    };
    return (
        <div className="d-flex flex-column flex-root h-100">
            <div className="row h-100 w-100 gx-0">
                {/* <div className="d-none d-lg-block col-lg-6 login_aside h-100"></div> */}
                <div className="col-12 h-100">
                    <div className="h-100 d-flex flex-center w-100 content_login position-relative">
                        <div id="login_form" className="login_form d-flex w-100 w-lg-500px mh-lg-600px py-10 px-6 px-sm-15 py-sm-15  justify-content-start align-items-center mx-10 mx-lg-4 flex-column gap-4">
                            <div className="login_form_logo py-2">
                                <img alt="Logo" className="logo_login " />
                                
                            </div>
                            <div className="text-center">
                                <h2 className="text-dark mb-3">Administrativo</h2>
                            </div>
                            <form
                                className="form w-100 px-4 d-flex flex-column gap-1 py-2"
                                noValidate="noValidate"
                                id="kt_sign_in_form"
                                onSubmit={handleSubmit(loginSend)}
                            >
                                <div className="input-group mb-2 text-center text-sm-start">
                                    {/* <label className="form-label fs-6 fw-bold text-dark">Correo Electrónico</label> */}
                                    <span className="input-group-text" id="correo_electronico"><FaEnvelope></FaEnvelope></span>
                                    <input
                                        className="form-control form-control-lg rounded-end"
                                        type="text"
                                        name="email"
                                        placeholder="Correo Electrónico"
                                        aria-describedby="correo_electronico"
                                        autoFocus
                                        tabIndex={1}
                                        // autoComplete="off"
                                        {...register("email")}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors.email?.type === "required" && (
                                            <div data-field="email" data-validator="regexp">
                                                No ha ingresado un correo valido
                                            </div>
                                        )}
                                        {errors.email?.type === "email" && (
                                            <div data-field="email" data-validator="regexp">
                                                Debe de ingresar un correo
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="fv-row mb-2">
                                    <div className="input-group mb-5" id="currentpassword">
                                        <span className="input-group-text"><FaLock></FaLock></span>
                                        <input
                                            type={viewPass ? "text" : "password"}
                                            className="form-control form-control-lg"
                                            placeholder="Contraseña"
                                            aria-label="Contraseña"
                                            name="password"
                                            aria-describedby="basic-addon2"
                                            id="password"
                                            {...register("password")}
                                            tabIndex={3}
                                        />
                                        <span className="input-group-text icon_pass" id="basic-addon1">
                                            {viewPass ? (
                                                <span onClick={() => setViewPass(false)} className="cursor-pointer">
                                                    <FaEye></FaEye>
                                                </span>
                                            ) : (
                                                <span onClick={() => setViewPass(true)} className="cursor-pointer">
                                                    <FaEyeSlash></FaEyeSlash>
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                    <div className="fv-plugins-message-container invalid-feedback">
                                        {errors.password?.type === "required" && (
                                            <div data-field="password" data-validator="notEmpty">
                                                La contraseña no es correcta
                                            </div>
                                        )}
                                        {errors.password?.type === "noLogin" && (
                                            <div data-field="password" data-validator="notEmpty">
                                                {errors.password.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-center mb-2 text-center w-100">
                                    <Link to="/restaurar" className="link-primary fw-bold ms-1">
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </div> */}
                                <div className="text-center">
                                    <button disabled={loading} type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
                                        {loading ? (
                                            <span className="indicator-label">
                                                Cargando...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        ) : (
                                            <span className="indicator-label ">Ingresar</span>
                                        )}
                                    </button>
                                </div>
                                {/* <div className="text-center mb-10">
                                    <div className="text-gray-400 fw-semibold fs-4">
                                        ¿Nuevo?
                                        <Link to="/registrarse" className="link-primary fw-bold ms-1">
                                            Create una cuenta
                                        </Link>
                                    </div>
                                </div> */}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
