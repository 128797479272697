/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import PlanesService from "../../api/services/planesService";
import CuponesService from "../../api/services/cuponesService";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaSync } from "react-icons/fa";
import moment from "moment/moment";
const today = new Date();

const estados = [
    {
        text: "Inactivo",
        value: "0",
    },
    {
        text: "Activo",
        value: "1",
    },
    { text: "Alerta", value: "2" },
];

//TOOLTIPS
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const Cupones = () => {
    /* USE FORM */
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();
    /* USE FORM */

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/cupones", name: "Cupones", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_plan",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "id_cupon",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "codigo",
            label: "Codigo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "descripcion",
            label: "Descripción",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "f_registro",
            label: "F. Registro",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{moment(value).format("yyyy-MM-DD")}</span>;
                },
            },
        },
        {
            name: "f_vencimiento",
            label: "F. Vencimiento",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{moment(value).format("yyyy-MM-DD")}</span>;
                },
            },
        },
        {
            name: "monto",
            label: "Monto",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>S/. {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${value == 1 ? "badge-success" : "badge-warning"}`}
                        >
                            {value == 0 ? "Inactivo" : value == 1 ? "Activo" : "En Alerta"}
                        </span>
                    );
                },
            },
        },
        {
            name: "Acciones",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-icon btn-sm" onClick={() => editPlan(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipP}>
                                <button className="btn btn-danger btn-icon btn-sm" onClick={() => handleMyState(tableMeta)}>
                                    <FaSync></FaSync>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [items, setItems] = useState([]);
    const [itemsPlanes, setItemsPlanes] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [dataStatus, setDataStatus] = useState({
        idcupon: null,
        estado: null
    })
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");

    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const selectData = async () => {
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const resPlanes = await PlanesService.getPlanes(body);
        if (resPlanes.status) {
            setItemsPlanes(resPlanes.data[0]);
        }
    };
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respCupon = await CuponesService.getCupones(body);
        if (respCupon.status) {
            setItems(respCupon.data[0]);
        }
        // console.log("respCupon", respCupon);
        setLoading(false);
    };
    //EDITAR CUENTA
    const editPlan = (data) => {
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        // console.log("itemSelectE", itemSelectE);
        setValue("idcupon", itemSelectE.id_cupon);
        setValue("idplan", itemSelectE.id_plan);
        setValue("codigo", itemSelectE.codigo);
        setValue("limite", itemSelectE.limite);
        setValue("monto", itemSelectE.monto);
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            // minDate: "today",
            onChange: function (selectedDates, dateStr, instance) {
                setValue("fechavencimiento", moment(itemSelectE.f_vencimiento).format("yyyy-MM-DD") );
            },
            defaultDate: moment(itemSelectE.f_vencimiento).format("yyyy-MM-DD"),
        });
        setValue("descripcion", itemSelectE.descripcion);
        setIsOpe(false);
        $("#modal_cupones").modal("show");
    };
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosCB = {
            idcupon: data.rowData[1],
            estado: data.rowData[7],
        };
        setDataStatus(datosCB)
        // console.log("datosCB", datosCB);
        $("#modal_cupones_status").modal("show");
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        setLoading3(true)
        const body = data
        body.refreshToken = "Bearer " + refreshToken;
        // console.log('body',body)

        const respStatus = await CuponesService.statusCupon(body);
        // console.log("respStatus", respStatus);
        if (respStatus.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        setLoading3(false)
        getData();
        $("#modal_cupones_status").modal("hide");
    };
    //AGREGAR CUENTA
    const addPlan = () => {
        setIsOpe(true);
        setValue("codigo", "");
        setValue("limite", null);
        setValue("monto", null);
        setValue("fechavencimiento", moment(today).format("yyyy-MM-DD"));
        setValue("descripcion", "");
        $("#modal_cupones").modal("show");
    };
    const montoValidate = (e) => {
        if (
            (e.keyCode < 48 || e.keyCode > 57) &&
            e.keyCode != 110 &&
            e.keyCode != 190 &&
            e.keyCode != 8 &&
            e.keyCode != 9 &&
            e.keyCode != 46 &&
            (e.keyCode < 96 || e.keyCode > 105)
        ) {
            e.preventDefault();
        }
    };
    //SETEAR BANCO SELECCIONADO
    const resetForm = () => {
        reset({
            codigo: "",
            fechavencimiento: moment(today).format("yyyy-MM-DD"),
            descripcion: "",
            limite: null,
            monto: null,
        });
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading2(true)
        // console.log(data);
        const bodyAdd = {
            codigo: data.codigo,
            limite: data.limite,
            monto: data.monto,
            descripcion: data.descripcion,
            idplan: data.idplan,
            fechavencimiento: data.fechavencimiento,
            refreshToken: "Bearer " + refreshToken,
        };
        const bodyUpd = {
            idcupon: data.idcupon,
            codigo: data.codigo,
            limite: data.limite,
            monto: data.monto,
            descripcion: data.descripcion,
            idplan: data.idplan,
            fechavencimiento: data.fechavencimiento,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log('bodyUpd',bodyUpd)
        if (isOpe) {
            const respAdd = await CuponesService.addCupon(bodyAdd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
            //enviar a agregar la cuenta
        } else {
            const respAdd = await CuponesService.updateCupon(bodyUpd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
        setLoading2(false)
        getData();
        $("#modal_cupones").modal("hide");
    };

    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setValue("fechavencimiento", moment(dateStr).format("yyyy-MM-DD"));
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        selectData();
    }, []);
    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card shadow p-0">
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex w-100 justify-content-start align-items-center h-100">
                                            <span className="fw-bolder fs-14 me-5">Lista de Cupones</span>
                                            <button type="button" className="btn btn-primary btn-sm mt-auto" onClick={addPlan}>
                                                <i className="bi bi-plus-circle fs-4 me-2" /> Agregar
                                            </button>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="modal_cupones" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="modal_cupones_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="modal_cupones_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Cupon</h2>
                                            <div
                                                id="modal_cupones_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="modal_cupones_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#modal_cupones_header"
                                                data-kt-scroll-wrappers="#modal_cupones_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mx-0 gap-2">
                                                    <div className="col-12">
                                                        <label htmlFor="planes" className="form-label">
                                                            Plan
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="planes"
                                                            id="planes"
                                                            onChange={(e) => setValue("idplan", e.target.value)}
                                                            // onChange={(e) => setEstadoSelect(e.target.value)}
                                                        >
                                                            {/* <option value="">Seleccionar</option> */}
                                                            {itemsPlanes.map((plan) => (
                                                                <option key={plan.id_plan} value={plan.id_plan}>
                                                                    {plan.nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold mb-2" htmlFor="">
                                                            <span className="required">Nombre</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Codigo"
                                                            name="codigo"
                                                            {...register("codigo", { required: true })}
                                                        />
                                                        {errors.codigo?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Nro de Usos</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Nro. Usos"
                                                            name="limite"
                                                            onKeyDown={(e) => montoValidate(e)}
                                                            {...register("limite", {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                validate: (value, formValues) => parseFloat(value) > 0,
                                                            })}
                                                        />
                                                        {errors.limite?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                        {errors.limite?.type === "validate" && (
                                                            <p className="text-danger">Dias mayor a 0</p>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="required">Monto</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Monto"
                                                            name="monto"
                                                            onKeyDown={(e) => montoValidate(e)}
                                                            {...register("monto", {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                validate: (value, formValues) => parseFloat(value) > 0,
                                                            })}
                                                        />
                                                        {errors.monto?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="" className="form-label">
                                                            Fecha
                                                        </label>
                                                        <input
                                                            className="form-control form-control-solid"
                                                            placeholder="Fecha"
                                                            id="kt_datepicker_1"
                                                            {...register("fechavencimiento", { required: true })}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="required">Descripción</span>
                                                        </label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control form-control-solid"
                                                            placeholder="Descripción"
                                                            name="descripcion"
                                                            rows={4}
                                                            {...register("descripcion", { required: true })}
                                                        />
                                                        {errors.descripcion?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="modal_cupones_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="modal_cupones_submit" className="btn btn-primary">
                                                {loading2 ? (
                                                    <span className="indicator-label">
                                                        Cargando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="modal_cupones_status" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="modal_cupones_status_form"
                                    >
                                        <div className="modal-header" id="modal_cupones_status_header">
                                            <h2 className="fw-bold">Actualizar Estado Cupon</h2>
                                            <div
                                                id="modal_cupones_status_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                className="scroll-y me-n7 pe-7"
                                                id="modal_cupones_status_scroll"
                                                data-kt-scroll="true"
                                                data-kt-scroll-activate="{default: false, lg: true}"
                                                data-kt-scroll-max-height="auto"
                                                data-kt-scroll-dependencies="#modal_cupones_header"
                                                data-kt-scroll-wrappers="#modal_cupones_scroll"
                                                data-kt-scroll-offset="300px"
                                                style={{ maxHeight: "1035px" }}
                                            >
                                                <div className="row mx-0 gap-2">
                                                    <div className="col-12">
                                                        <label htmlFor="status" className="form-label">
                                                            Estado
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid "
                                                            name="status"
                                                            id="status"
                                                            onChange={(e) => setDataStatus({...dataStatus, estado: e.target.value})}
                                                        >
                                                            {estados.map((plan) => (
                                                                <option key={plan.value} value={plan.value}>
                                                                    {plan.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="modal_cupones_status_cancel"
                                                className="btn btn-light me-3"
                                                data-bs-dismiss="modal"
                                            >
                                                Cerrar
                                            </button>
                                            <button type="button" onClick={()=>confirmChange(dataStatus)} id="modal_cupones_status_submit" className="btn btn-primary">
                                                {loading3 ? (
                                                    <span className="indicator-label">
                                                        Cargando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">Actualizar</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Cupones;
