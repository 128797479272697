/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../layout/main";
import Breadcrumbs from "../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import combinadasService from "../../api/services/combinadasService";
import { useForm, Controller } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { useUserContext } from "../../Providers/UserProvider";
import moment from "moment/moment";
import CombinadasService from "../../api/services/combinadasService";

/* DATA ESTATICA */
const today = new Date();

const Combinadas = () => {

    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/combinadas", name: "Combinadas", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    //COLUMNAS
    const columns1 = [
        {
            name: "id_combinada",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "solicita",
            label: "Solicita",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "apoya",
            label: "Apoya",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "Tipo",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{value == 1 ? "A favor" : "En contra"}</span>;
                },
            },
        },
        {
            name: "tipomoneda",
            label: "Tipo moneda",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{value == 1 ? "Dolares" : "Moneda local"}</span>;
                },
            },
        },
        {
            name: "monto",
            label: "DOLARES/MONEDA LOCAL",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span> {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "monto_cambio",
            label: "CONVERTIDO(DOLARES)",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>$ {parseFloat(value).toFixed(2)}</span>;
                },
            },
        },
        {
            name: "fecha",
            label: "Fecha",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span> {moment(value).format("DD-MM-yyyy")}</span>;
                },
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    const { user } = useUserContext();
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        f_fin: moment(today).format("yyyy-MM-DD"),
    });
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fechainicio: filtros.f_inicio,
            fechafin: filtros.f_fin,
        };
        // console.log("body", body);
        const respCombinadas = await CombinadasService.getCombinadas(body);
        if (respCombinadas.status) {
            let tot = 0;
            setItems(respCombinadas.data[0]);
            for (let index = 0; index < respCombinadas.data[0].length; index++) {
                const element = respCombinadas.data[0][index];
                tot += parseFloat(element.monto_cambio);
            }
            setTotal(tot);
        }
        // console.log("respCombinadas", respCombinadas);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);
    
    useEffect(() => {
        $("#kt_datepicker_12").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_13").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                        <div className="row mx-0 w-100">
                                            <div className="col-12 col-sm-3">
                                                <div className="w-100">
                                                    <label htmlFor="" className="form-label">
                                                        Inicio
                                                    </label>
                                                    <input className="form-control form-control-solid" placeholder="Fecha Inicio" id="kt_datepicker_12"
                                                        onInput={(e) => setFiltros({ ...filtros, f_inicio: moment(e.target.value).format("yyyy-MM-DD") })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-3">
                                                <div className="w-100">
                                                    <label htmlFor="" className="form-label">
                                                        Fin
                                                    </label>
                                                    <input className="form-control form-control-solid" placeholder="Fecha Fin" id="kt_datepicker_13"
                                                        onInput={(e) => setFiltros({ ...filtros, f_fin: moment(e.target.value).format("yyyy-MM-DD") })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="d-flex align-content-end justify-content-xl-end justify-content-center h-100  w-100">
                                                    <button
                                                        disabled={loading}
                                                        type="button"
                                                        className="btn btn-primary mt-auto me-3"
                                                        onClick={getData}
                                                    >
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span className="indicator-label ">
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={
                                        <div className="d-flex gap-3 px-4">
                                            <span className="fw-bolder fs-14">Lista de Combinadas</span> |{" "}
                                            <span className="fw-bolder">Total: {parseFloat(total).toFixed(2)}</span>
                                        </div>
                                    }
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default Combinadas;