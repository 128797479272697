/* eslint-disable */

import UseService from "../UseServices";

const ConstantesService = {
    async getAgrupadores(data) {
        var resp = await UseService.post("constante/agrupadores", data);
        return resp;
    },
    async getConstXAgrupadores(data) {
        var resp = await UseService.post("constante/list", data);
        return resp;
    },
};
export default ConstantesService;
