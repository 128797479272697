/* eslint-disable */
import React from "react";
import {
    FaClipboardList,
    FaDonate,
    FaUsers,
    FaMoneyCheckAlt,
    FaPowerOff,
    FaBoxOpen,
    FaUserCheck,
    FaUserShield,
    FaCalendarCheck,
    FaShoppingCart,
    FaUser,
    FaUserCog,
    FaUsersCog,
    FaRegChartBar,
    FaChalkboard,
    FaHistory,
    FaCalendarAlt,
    FaTicketAlt,
    FaStore,
    FaPiggyBank,
    MdCompareArrows,
    FaFilter 
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../../Providers/UserProvider";
const Sidebar = () => {
    const { user } = useUserContext();
    return (
        <div
            id="kt_aside"
            className="aside aside-default aside-hoverable"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="false"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >

            <div className="aside-menu flex-column-fluid">
                <div
                    className="menu menu-sub-indention menu-column menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-active-bg menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 my-5 mt-lg-2 mb-lg-0"
                    id="kt_aside_menu"
                    data-kt-menu="true"
                >
                    <div
                        className="hover-scroll-y me-lg-n5 pe-lg-5"
                        id="kt_aside_menu_wrapper"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-wrappers="#kt_aside_menu"
                        data-kt-scroll-offset="20px"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    >
                        {user.pvgxd > 3 ?
                            <>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUsers />
                                            </span>
                                        </span>
                                        <span className="menu-title">Usuarios</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/usuarios">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Listar</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaPowerOff />
                                            </span>
                                        </span>
                                        <span className="menu-title">Activaciones</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/activaciones-programadas">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Programadas</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/activaciones-suspendidas">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Suspendidas</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaHistory />
                                            </span>
                                        </span>
                                        <span className="menu-title">Conexiones</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/conexiones-operadores">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Operadores</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/conexiones-usuarios">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Usuarios</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/cupones">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaTicketAlt />
                                            </span>
                                        </span>
                                        <span className="menu-title">Cupones</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/planes">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaShoppingCart />
                                            </span>
                                        </span>
                                        <span className="menu-title">Planes</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/filtros">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaFilter />
                                            </span>
                                        </span>
                                        <span className="menu-title">Filtros</span>
                                    </NavLink>
                                </div>
                            </> : <></>}

                        {user.pvgxd == 2 ?
                            <>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUserCog />
                                            </span>
                                        </span>
                                        <span className="menu-title">Operadores</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/operadores">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">lista</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/conexiones-operadores">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Conexiones</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUserShield />
                                            </span>
                                        </span>
                                        <span className="menu-title">Surebets</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/surebets">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Listar</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/surebets-grafico">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Graficos</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUserCheck />
                                            </span>
                                        </span>
                                        <span className="menu-title">Apuestas</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/apuestas">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Listar</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/apuestas-grafico">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Graficos</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaClipboardList />
                                            </span>
                                        </span>
                                        <span className="menu-title">Cuentas</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/bancarias">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Bancarias</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/casas">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Casas</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaClipboardList />
                                            </span>
                                        </span>
                                        <span className="menu-title">Movimientos</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/movimientos">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Lista</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/combinadas">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Combinadas</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/confirmados">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Retiros por confirmar</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div data-kt-menu-trigger="click" className="menu-item  menu-accordion">
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaMoneyCheckAlt />
                                            </span>
                                        </span>
                                        <span className="menu-title">Saldos en Cuentas</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/montos">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Registro de Saldo</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/reporte-montos">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Reporte</span>
                                            </NavLink>
                                        </div>
                                        <div className="menu-item">
                                            <NavLink className="menu-link" to="/transacciones">
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Transacciones</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/bankdiario">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaPiggyBank />
                                            </span>
                                        </span>
                                        <span className="menu-title">Resumen del Dia</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/gastos">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaPiggyBank />
                                            </span>
                                        </span>
                                        <span className="menu-title">Gastos</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/resumen">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaPiggyBank />
                                            </span>
                                        </span>
                                        <span className="menu-title">Reporte Resumen</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/utilidades">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaPiggyBank />
                                            </span>
                                        </span>
                                        <span className="menu-title">Utilidades</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/caja-cuenta">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaBoxOpen />
                                            </span>
                                        </span>
                                        <span className="menu-title">Caja Diaria</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/consolidado">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUsers />
                                            </span>
                                        </span>
                                        <span className="menu-title">Capital Global</span>
                                    </NavLink>
                                </div>
                            </> : <></>}

                        {user.pvgxd >= 3 ?
                            <>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/admin-franquicias">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaUsersCog />
                                            </span>
                                        </span>
                                        <span className="menu-title">Admin Franquicias</span>
                                    </NavLink>
                                </div>
                                <div className="menu-item">
                                    <NavLink className="menu-link" to="/franquicias">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <FaStore />
                                            </span>
                                        </span>
                                        <span className="menu-title">Franquicias</span>
                                    </NavLink>
                                </div>
                            </> : <></>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
