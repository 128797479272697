/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";
import Breadcrumbs from "../../../Components/utils/BreadCrumb"
import MUIDataTable from "mui-datatables";
import UsersService from "../../../api/services/usersService";
import PlanesService from "../../../api/services/planesService";
import ActivacionesService from "../../../api/services/activacionesService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import paises from "../../../Assets/paises.json";
import moment from "moment/moment";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaRegCalendarAlt, FaDollyFlatbed, FaUserEdit, FaHeartBroken, FaUndoAlt  } from "react-icons/fa";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './index.css'
const schemaRegistrar = yup.object({
  nombres: yup
    .string()
    .required("Este campo es requerido")
    .matches(/^[a-zA-Z\s]*$/, "No debe de contener numeros"),
  apellidos: yup
    .string()
    .required("Este campo es requerido")
    .matches(/^[aA-zZ\s]+$/, "No debe de contener numeros"),
  email: yup.string().required("Este campo es requerido").email("Debe de ser un email"),
  documento: yup
    .number()
    .required("Este campo es requerido")
    .typeError("No es un numero")
    .test("len", "Debe tener min 8 caracteres", (val) => {
      if (val == undefined) {
        return true;
      }
      return val.toString().length >= 8;
    }),
  pais: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required("Seleccione un pais"),
  celular: yup.number().required("Ingrese un número").typeError("Ingrese un número celular"),
  ciudad: yup.string().required("Ingrese una ciudad")
});
const colourStyles = {
  control: (styles) => ({
    ...styles, backgroundColor: "#1b1b29", color: "#fff", boxShadow: "none", borderColor: "#323248", "&:hover": {
      borderColor: "#323248"
    }
  }),
  option: (styles, { data, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : "#181825",
      color: "#fff",
      cursor: isDisabled ? "not-allowed" : "pointer",
      borderColor: "#1b1b29",
      "&:hover": {
        backgroundColor: "#92929f",
      },
    };
  },
  menu: (base) => ({
    ...base,
    background: "#1b1b29",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#92929f",
  }),
};
const defaultStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    borderColor: "#e1e3ea",
    "&:hover": {
      borderColor: "#e1e3ea"
    }
  }),
  menu: (base) => ({
    ...base,
  }),
  menuList: (base) => ({
    ...base,
    minHeight: "100px", // your desired height
  }),
};
//TOOLTIPS
const tooltipE = (
  <Tooltip id="tooltipE">
    <strong>Editar usuario</strong>
  </Tooltip>
);
const tooltipD = (
  <Tooltip id="tooltipD">
    <strong>Detalles de Planes</strong>
  </Tooltip>
);
const tooltipP = (
  <Tooltip id="tooltipP">
    <strong>Asignar Plan</strong>
  </Tooltip>
);
const tooltipSus = (
  <Tooltip id="tooltipSus">
    <strong>Suspender Plan</strong>
  </Tooltip>
);
const tooltipIna = (
  <Tooltip id="tooltipIna">
    <strong>Desactivar usuario</strong>
  </Tooltip>
);
const activaciones = [
  {
    id: 1,
    val: 1,
    text: "Inmediata"
  },
  {
    id: 2,
    val: 2,
    text: "Programada"
  }
]
const today = new Date();
const ListarUsuarios = () => {
  //USE FORM
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaRegistrar),
  });


  /* -----BREADCRUMBS----*/
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/usuarios", name: "Usuarios", active: true },
  ];
  /* -----FIN BREADCRUMBS----*/

  /* -----MUIDATATABLE----*/
  //COLUMNAS
  const columns1 = [
    {
      name: "id_persona",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "nombres",
      label: "Nombre",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "correo",
      label: "Correo Electrónico",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "cel",
      label: "Celular",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "nomplan",
      label: "Plan",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "fecha_inicio",
      label: "F. Inicio",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "fecha_fin",
      label: "F. Fin",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "estado",
      label: "ESTADO",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span className={`badge badge-square badge-lg p-2 text-uppercase ${value == 1 ? "badge-success" : value == 2 ? "badge-warning" : "badge-danger"}`}>
              {value == 0 ? "Inactivo" : value == 1 ? "Activo" : "Suspendido"}
            </span>
          );
        },
      },
    },
    {
      name: "estado",
      label: "ACCIONES",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <ButtonGroup>
              <DropdownButton size="sm" as={ButtonGroup} title="Opciones" id="bg-nested-dropdown" variant="secondary" className="dropdown-button">
                <Dropdown.Item eventKey="1" onClick={() => editCuenta(id, tableMeta)}>Editar usuario</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => detailPlan(id)}>Detalles activaciones</Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={() => confirmPlan(id, tableMeta)}>Asignar plan</Dropdown.Item>
                {value == 1 ?<Dropdown.Item eventKey="4" onClick={() => suspenderPlan(id, tableMeta)}>Suspender plan</Dropdown.Item>:''}
                <Dropdown.Item eventKey="5" onClick={() => estadoPlan(id, tableMeta)}>Cambiar estado</Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          );
        },
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
  ];
  const options = {
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: false,
    download: true,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Elementos por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscador",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Eliminar fila seleccionada",
      },
      centeredTableHead: {
        "& > span": {
          justifyContent: "center",
        },
      },
    },
  };
  const columnsDP = [
    {
      name: "fecha_compra",
      label: "F. Compra",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span className="text-uppercase">{moment(value).format("yyyy-MM-DD HH:mm:ss")}</span>;
        },
      },
    },
    {
      name: "formapago",
      label: "Forma de Pago",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "monto",
      label: "Monto",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "nomplan",
      label: "Plan",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "dias",
      label: "Dias",
      options: {
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
      },
    },
    {
      name: "fecha_fin",
      label: "F. fin",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          align: "center",
        }),
        setCellHeaderProps: (value) => ({
          className: "centeredHeaderCell",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span className="text-uppercase">{moment(value).format("yyyy-MM-DD HH:mm:ss")}</span>;
        },
      },
    },
  ];
  const optionsDP = {
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    fixedSelectColumn: false,
    download: false,
    pagination: false,
    search: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Elementos por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscador",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Eliminar fila seleccionada",
      },
      centeredTableHead: {
        "& > span": {
          justifyContent: "center",
        },
      },
    },
  };
  //DETALLE PLAN


  /* -----FIN MUIDATATABLE----*/

  /* -----HOOKS----*/
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(false);
  const [loadingS, setLoadingS] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsDP, setItemsDP] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [activePlan, setActivePlan] = useState({
    idclient: null,
    idplan: 1,
    monto: "",
  });
  const [fechaPro, setFechaPro] = useState(moment(today).format("yyyy-MM-DD"));
  const [fechaSuspen, setFechaSuspen] = useState(moment(today).format("yyyy-MM-DD"));
  const [suspenPlan, setSuspenPlan] = useState({
    idclient: null
  });
  const [selectTypeP, setSelectTypeP] = useState(1)
  /* -----FIN HOOKS----*/

  /* -----DATOS----*/
  const refreshToken = localStorage.getItem("refreshToken");

  /* -----FIN DATOS----*/

  /* -----METODOS----*/
  //LISTAR DATA
  const getData = async () => {
    setLoading(true)
    const body = {
      refreshToken: "Bearer " + refreshToken,
    };
    const respUsers = await UsersService.getClientes(body)
    if (respUsers.status) {
      setItems(respUsers.data[0])
    }
    const resPlanes = await PlanesService.getPlanes(body)
    if (resPlanes.status) {
      let planes = (resPlanes.data[0]).filter(e => e.estado == 1)
      setPlanes(planes)
    }
    setLoading(false)

  };
  //EDITAR DATA
  const editCuenta = (index, data) => {
    let itemSelectE = {}
    itemSelectE = items.find(e => e.id_persona == index)
    setValue("idclient", itemSelectE.id_persona)
    setValue("nombres", itemSelectE.nom)
    setValue("apellidos", itemSelectE.ape)
    setValue("celular", itemSelectE.cel)
    setValue("email", itemSelectE.correo)
    setValue("documento", itemSelectE.documento)
    setValue("ciudad", itemSelectE.ciudad)
    setValue("pais", {
      value: itemSelectE.pais,
      label: itemSelectE.pais
    })
    $("#modal_update_user").modal("show");
  };
  const detailPlan = async (id) => {
    const body = {
      refreshToken: "Bearer " + refreshToken,
      idclient: id
    }
    const respPago = await UsersService.getDetallesPago(body)
    if (respPago.status) {
      setItemsDP(respPago.data[0])
      $("#modal_detail_pay").modal("show");
    }
  }
  const confirmPlan = (index, data) => {
    let itemSelectE = {}
    itemSelectE = items.find(e => e.id_persona == index)
    setActivePlan({ ...activePlan, idclient: itemSelectE.id_persona })
    $("#modal_confirm_plan").modal("show");
  }
  const suspenderPlan = (index, data) => {
    let itemSelectE = {}
    itemSelectE = items.find(e => e.id_persona == index)
    setSuspenPlan({ ...suspenPlan, idclient: itemSelectE.id_persona })
    $("#modal_suspen_plan").modal("show");
  }
  const estadoPlan = (index, data) => {
    let itemSelectE = {}
    itemSelectE = items.find(e => e.id_persona == index)
    let estado = itemSelectE.estado;
    let idUser = itemSelectE.id_persona;
    Swal.fire({
      title: `¿Quieres ${estado == 1 ? 'desactivar':'activar'} la cuenta?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No"
    }).then(async (result) => {
      if (result.isConfirmed) {
        let body = {
          idclient: idUser,
          estado: estado == 1 ? 0 : 1
        }
        body.refreshToken = "Bearer " + refreshToken;
        const respConfirm = await ActivacionesService.estadoPlan(body)
        if (respConfirm.status == 200) {
          Swal.fire({
            title: "Actualizado",
            icon: "success",
            text: "Datos actualizados con éxito.",
          });
        } else {
          Swal.fire({
            title: "Error!",
            icon: "error",
            text: "Ocurrió un error al actualizar los datos.",
          });
        }
        getData()
      }
    });
  }
  const resetForm = () => {
    reset({
      nombres: "",
      apellidos: "",
      correo: "",
      celular: "",
      documento: "",
      pais: "",
      ciudad: "",
    });
  };
  const changePass = (e) => {
    if (e) {
      setValue("password", "")
      setShowPass(false)
    } else {
      setShowPass(true)
    }
  }
  const onlyNumber = (e) => {
    if (
      (e.keyCode < 48 || e.keyCode > 57) &&
      e.keyCode != 110 &&
      e.keyCode != 190 &&
      e.keyCode != 8 &&
      e.keyCode != 9 &&
      e.keyCode != 46 &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };
  const sendConfirm = async () => {
    setLoadingC(true)
    $("#kt_datepicker_1").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFechaPro(moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    activePlan.monto = parseFloat(activePlan.monto)
    activePlan.idplan = parseInt(activePlan.idplan)
    const body = activePlan
    body.refreshToken = "Bearer " + refreshToken
    if (selectTypeP == 1) {
      const respConfirm = await ActivacionesService.updatePlanCliente(body)
      if (respConfirm.status) {
        Swal.fire({
          title: "Actualizado",
          icon: "success",
          text: "Datos registrados con éxito.",
        });
      } else {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: "Ocurrió un error al actualizar los datos.",
        });
      }
    } else {
      body.fecha = fechaPro
      const respProgra = await ActivacionesService.programarPlan(body)
      if (respProgra.status) {
        Swal.fire({
          title: "Programado",
          icon: "success",
          text: "Datos registrados con éxito.",
        });
      } else {
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: "Ocurrió un error al actualizar los datos.",
        });
      }
    }

    setLoadingC(false)
    getData()
    $("#modal_confirm_plan").modal("hide");
  }
  const suspendConfirm = async () => {
    setLoadingS(true)
    const body = suspenPlan
    body.fechareconexion = fechaSuspen
    body.refreshToken = "Bearer " + refreshToken
    
    const respConfirm = await ActivacionesService.suspenderPlan(body)
    if (respConfirm.status) {
      Swal.fire({
        title: "Suspendido",
        icon: "success",
        text: "Datos registrados con éxito.",
      });
    } else {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: "Ocurrió un error al suspender su plan.",
      });
    }
    setLoadingS(false)
    getData()
    $("#modal_suspen_plan").modal("hide");
  }
  const resetActivacion = () => {
    const defaul = {
      idclient: null,
      idplan: 1,
      monto: ""
    }
    setActivePlan(defaul)
  }
  const resetSuspen = () => {
    setSuspenPlan({ ...suspenPlan, fechareconexion: moment(today).format("yyyy-MM-DD") })
  }
  /* -----FIN METODOS----*/

  /* -----MOUNTED----*/
  useEffect(() => {
    getData();
    $("#kt_datepicker_1").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFechaPro(moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
    $("#kt_datepicker_2").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        setFechaSuspen(moment(dateStr).format("yyyy-MM-DD"))
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, []);

  /* -----FIN MOUNTED----*/

  /* ENVIAR DATA */
  const onSubmit = async (data) => {
    data.pais = data.pais.value
  }
  return (
    <Main>
      <div className="container-xxl mw-100">
        <div className="toolbar" id="kt_toolbar">
          <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
          </div>
        </div>
        <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
          <div className="overlay-wrapper">
            <div className="card shadow p-0">
              <div className="card-body p-0">
                <MUIDataTable
                  title={<span className="fw-bolder fs-14">Lista de Usuarios</span>}
                  data={items}
                  columns={columns1}
                  options={options}
                />
              </div>
            </div>
            <div className="modal fade" id="modal_update_user" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                  <form
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    id="modal_update_user_form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="modal-header" id="modal_update_user_header">
                      <h2 className="fw-bold">Editar Usuario</h2>
                      <div
                        id="modal_update_user_close"
                        className="btn btn-icon btn-sm btn-active-icon-primary"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                      >
                        <span className="svg-icon svg-icon-1">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect
                              opacity="0.5"
                              x="6"
                              y="17.3137"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(-45 6 17.3137)"
                              fill="currentColor"
                            ></rect>
                            <rect
                              x="7.41422"
                              y="6"
                              width="16"
                              height="2"
                              rx="1"
                              transform="rotate(45 7.41422 6)"
                              fill="currentColor"
                            ></rect>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div className="modal-body py-10 px-lg-17">
                      <div
                        id="modal_update_user_scroll"
                        data-kt-scroll-dependencies="#modal_update_user_header"
                        data-kt-scroll-wrappers="#modal_update_user_scroll"
                      >
                        <div className="row mb-7 fv-plugins-icon-container">
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Nombres</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Nombres"
                              type="text"
                              id="nombres"
                              {...register("nombres", {
                                required: true,
                              })}
                            />
                            {errors.nombres?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Apellidos</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Apellidos"
                              type="text"
                              id="apellidos"
                              {...register("apellidos", {
                                required: true,
                              })}
                            />
                            {errors.apellidos?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Celular</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Celular"
                              type="text"
                              id="celular"
                              {...register("celular", {
                                required: true,
                              })}
                            />
                            {errors.celular?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Documento</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Documento"
                              type="text"
                              id="documento"
                              {...register("documento", {
                                required: true,
                              })}
                            />
                            {errors.documento?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>

                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Pais</span>
                            </label>
                            <Controller
                              control={control}
                              name="pais"
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={paises.map((cu) => ({
                                    label: cu.nombre,
                                    value: cu.nombre,
                                  }))}
                                  styles={localStorage.getItem("userTheme") === "dark" ? colourStyles : defaultStyles}
                                  defaultValue=""
                                  onChange={(e) => [field.onChange(e)]}
                                  // onChange={(e) => searchCountry(e.value)}
                                  maxMenuHeight={125}
                                  placeholder={"Seleccionar"}
                                  noOptionsMessage={() => "Sin resultados"}
                                />
                              )}
                            // defaultValue={{ label: "0", value: "0" }}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                              {errors.pais?.type === "required" && errors.pais.message}
                            </div>
                          </div>
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Ciudad</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Ciudad"
                              type="text"
                              id="ciudad"
                              {...register("ciudad", {
                                required: true,

                              })}
                            />
                            {errors.ciudad?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>
                          <div className="col-12">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Correo</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Email"
                              type="email"
                              id="email"
                              {...register("email", {
                                required: true,
                              })}
                            />
                            {errors.email?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          </div>
                          <div className="col-6 align-items-center d-flex ">
                            <label className="form-check form-check-custom form-check-inline d-flex align-items-center ">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="termconds"
                                onChange={(e) => changePass(e.target.checked)}
                              />
                              <span className="form-check-label fw-semibold text-gray-700 fs-6">
                                Cambiar Contraseña
                              </span>
                            </label>
                          </div>
                          <div className="col-6">
                            <label className="fs-6 fw-semibold my-2" htmlFor="">
                              <span className="">Nueva Contraseña</span>
                            </label>
                            <input
                              className="form-control form-control-solid"
                              placeholder="Nueva Contraseña"
                              type="text"
                              id="newpassword"
                              disabled={showPass}
                              {...register("password")}
                            />
                          </div>
                          {/* <label className="fs-6 fw-semibold my-2" htmlFor="">
                            <span className="required"></span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Monto"
                            name="monto"
                            onKeyDown={(e) => montoValidate(e)}
                            {...register("monto", {
                              required: true,
                              valueAsNumber: true,
                              validate: (value, formValues) => parseFloat(value) > 0,
                            })}
                          />
                          {errors.monto?.type === "required" && <p className="text-danger">El campo es requerido</p>}
                          {errors.monto?.type === "validate" && <p className="text-danger">Debe ser mayor a 0</p>} */}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer flex-center">
                      <button
                        type="reset"
                        id="modal_update_user_cancel"
                        className="btn btn-light me-3"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                      >
                        Cerrar
                      </button>
                      <button type="submit" id="modal_update_user_submit" className="btn btn-primary">
                        <span className="indicator-label">Editar</span>
                        <span className="indicator-label">
                          Cargando...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal fade" id="modal_detail_pay" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-dialog-centered mw-1000px">
                <div className="modal-content">
                  <div className="modal-header" id="modal_detail_pay_header">
                    <h2 className="fw-bold">Detalles de activaciones</h2>
                    <div
                      id="modal_detail_pay_close"
                      className="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal"
                    >
                      <span className="svg-icon svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect
                            opacity="0.5"
                            x="6"
                            y="17.3137"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(-45 6 17.3137)"
                            fill="currentColor"
                          ></rect>
                          <rect
                            x="7.41422"
                            y="6"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(45 7.41422 6)"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="modal-body py-2 px-lg-6">
                    <div
                      id="modal_detail_pay_scroll"
                      data-kt-scroll-dependencies="#modal_detail_pay_header"
                      data-kt-scroll-wrappers="#modal_detail_pay_scroll"
                    >
                      <div className="p-0">
                        <MUIDataTable
                          data={itemsDP}
                          columns={columnsDP}
                          options={optionsDP}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer flex-center">
                    <button
                      type="reset"
                      id="modal_detail_pay_cancel"
                      className="btn btn-danger me-3"
                      data-bs-dismiss="modal"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="modal_confirm_plan" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header" id="modal_confirm_plan_header">
                    <h2 className="fw-bold">Confirmar Plan</h2>
                    <div
                      id="modal_confirm_plan_close"
                      className="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal"
                      onClick={resetActivacion}
                    >
                      <span className="svg-icon svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect
                            opacity="0.5"
                            x="6"
                            y="17.3137"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(-45 6 17.3137)"
                            fill="currentColor"
                          ></rect>
                          <rect
                            x="7.41422"
                            y="6"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(45 7.41422 6)"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="modal-body py-5 px-8">
                    <div
                      id="modal_confirm_plan_scroll"
                      data-kt-scroll-dependencies="#modal_confirm_plan_header"
                      data-kt-scroll-wrappers="#modal_confirm_plan_scroll"
                    >
                      <div className="row mx-0 gap-2">
                        <div className="col-12">
                          <label htmlFor="planes" className="form-label">
                            Plan
                          </label>
                          <select
                            className="select2-selection select2-selection--single form-select form-select-solid "
                            name="planes"
                            id="planes"
                            onChange={(e) => setActivePlan({ ...activePlan, idplan: parseInt(e.target.value) })}
                          // onChange={(e) => setEstadoSelect(e.target.value)}
                          >
                            {/* <option value="">Seleccionar</option> */}
                            {planes.map((plan) => (
                              <option key={plan.id_plan} value={plan.id_plan}>
                                {plan.nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12">
                          <label htmlFor="activaciones" className="form-label">
                            Activación
                          </label>
                          <select
                            className="select2-selection select2-selection--single form-select form-select-solid "
                            name="activaciones"
                            id="activaciones"
                            onChange={(e) => setSelectTypeP(e.target.value)}
                          >
                            {activaciones.map((acti) => (
                              <option key={acti.id} value={acti.val}>
                                {acti.text}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12">
                          <label className="fs-6 fw-semibold my-2" htmlFor="">
                            <span className="">Monto</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Monto"
                            name="monto"
                            onKeyDown={(e) => onlyNumber(e)}
                            value={activePlan.monto}
                            onChange={(e) => setActivePlan({ ...activePlan, monto: e.target.value })}
                          />
                        </div>
                        <div className="col-12" style={{ display: (selectTypeP == 2 ? 'block' : 'none') }}>
                          <label htmlFor="" className="form-label">
                            Fecha
                          </label>
                          <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer flex-center">
                    <button
                      type="reset"
                      id="modal_confirm_plan_cancel"
                      className="btn btn-light me-3"
                      data-bs-dismiss="modal"
                      onClick={resetActivacion}
                    >
                      Cerrar
                    </button>
                    <button type="button" onClick={() => sendConfirm()} id="modal_update_user_submit" className="btn btn-primary">
                      {loadingC ? (
                        <span className="indicator-label">
                          Cargando...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-label">Confirmar</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="modal_suspen_plan" tabIndex="-1" aria-modal="true" role="dialog">
              <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header" id="modal_suspen_plan_header">
                    <h2 className="fw-bold">Suspender Plan</h2>
                    <div
                      id="modal_suspen_plan_close"
                      className="btn btn-icon btn-sm btn-active-icon-primary"
                      data-bs-dismiss="modal"
                      onClick={resetSuspen}
                    >
                      <span className="svg-icon svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect
                            opacity="0.5"
                            x="6"
                            y="17.3137"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(-45 6 17.3137)"
                            fill="currentColor"
                          ></rect>
                          <rect
                            x="7.41422"
                            y="6"
                            width="16"
                            height="2"
                            rx="1"
                            transform="rotate(45 7.41422 6)"
                            fill="currentColor"
                          ></rect>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div className="modal-body py-5 px-8">
                    <div
                      id="modal_suspen_plan_scroll"
                      data-kt-scroll-dependencies="#modal_suspen_plan_header"
                      data-kt-scroll-wrappers="#modal_suspen_plan_scroll"
                    >
                      <div className="row mx-0 gap-2">
                        <div className="col-12">
                          <label htmlFor="" className="form-label">
                            Fecha de Reactivación
                          </label>
                          <input className="form-control form-control-solid" placeholder="Fecha" id="kt_datepicker_2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer flex-center">
                    <button
                      type="reset"
                      id="modal_suspen_plan_cancel"
                      className="btn btn-danger me-3"
                      data-bs-dismiss="modal"
                      onClick={resetSuspen}
                    >
                      Cerrar
                    </button>
                    <button type="button" onClick={() => suspendConfirm()} id="modal_update_user_submit" className="btn btn-primary">
                      {loadingS ? (
                        <span className="indicator-label">
                          Cargando...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-label">Confirmar</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="overlay-custom card-rounded bg-black bg-opacity-50">
              <div className="d-flex flex-column align-items-center mt-10">
                <div className="spinner-border text-white" role="status"></div>
                <span>Buscando datos...</span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Main>
  );
};

export default ListarUsuarios;
