/* eslint-disable */

import UseService from "../UseServices";

const OperadoresService = {
    async getOperadores(data) {
        var resp = await UseService.post("operadores/list", data);
        return resp;
    },
    async addOperador(data) {
        var resp = await UseService.post("operadores/add", data);
        return resp;
    },
    async updateOperador(data) {
        var resp = await UseService.put("operadores/update", data);
        return resp;
    },
    async statusOperador(data) {
        var resp = await UseService.put("operadores/status", data);
        return resp;
    },
};
export default OperadoresService;