
/* eslint-disable */
import React, { useEffect, useState } from "react"
import Main from "../../layout/main"
import Breadcrumbs from "../../Components/utils/BreadCrumb"
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment/moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './blog.css'
const today = new Date();
const schemaRegistrar = yup.object({
  titulo: yup
    .string()
    .required("Este campo es requerido")
    .matches(/^[a-zA-Z\s]*$/, "No debe de contener numeros"),
});
const categorias = [
  {
    label: "Categoria 1",
    value: 1,
  },
  {
    label: "Categoria 2",
    value: 2,
  },
  {
    label: "Categoria 3",
    value: 3,
  },
  {
    label: "Categoria 4",
    value: 4,
  },
];
const Blog = () => {
  /* USE FORM */
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaRegistrar),
  });
  /* USE FORM */

  const [loading, setLoading] = useState(false);


  /* -----BREADCRUMBS----*/
  const crumbs = [
    { path: "/perfil", name: "Home", active: true },
    { path: "/blog", name: "Blog", active: true },
  ];
  /* -----FIN BREADCRUMBS----*/

  const onSubmit = (data) => {
    console.log('dataaa', data)
  }
  useEffect(() => {
    $("#kt_datepicker_1").flatpickr({
      altFormat: "Y-m-d",
      dateFormat: "Y-m-d",
      onChange: function (selectedDates, dateStr, instance) {
        //console.log("sipe aq");
        setValue("fecha", moment(dateStr).format("yyyy-MM-DD"));
      },
      defaultDate: moment(today).format("yyyy-MM-DD"),
    });
  }, []);
  return (
    <Main>
      <div className="container-xxl mw-100">
        <div className="toolbar" id="kt_toolbar">
          <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
            <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
          </div>
        </div>
        <div className="card shadow p-0">
          <div className="card-body">
            <form
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row mb-7 fv-plugins-icon-container">
                <div className="col-sm-6 col-lg-4 mb-2">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Categoria</span>
                  </label>
                  <select
                    className="select2-selection select2-selection--single form-select form-select-solid "
                    name="status"
                    id="status"
                    {...register("categoria", { required: true })}
                  >
                    {categorias.map((cate) => (
                      <option key={cate.value} value={cate.value}>
                        {cate.label}
                      </option>
                    ))}
                  </select>
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.nombres?.type === "required" && errors.nombres.message}
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-2">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Titulo</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Titulo"
                    name="titulo"
                    maxLength={51}
                    {...register("titulo", { required: true })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.titulo?.type === "required" && errors.titulo.message}
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4 mb-2">
                  <label htmlFor="" className="form-label">
                    Fecha Publicación
                  </label>
                  <input
                    className="form-control form-control-solid"
                    placeholder="Fecha"
                    id="kt_datepicker_1"
                  />
                </div>
                <div className="col-sm-6 mb-2">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Autor</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Autor"
                    name="autor"
                    {...register("autor", { required: true })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.autor?.type === "required" && errors.autor.message}
                  </div>
                </div>
                <div className="col-sm-6 mb-2">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Profesion (Autor)</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Profesion"
                    name="profesion"
                    {...register("profesion", { required: true })}
                  />
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.profesion?.type === "required" && errors.profesion.message}
                  </div>
                </div>
                <div className="col-sm-4 mb-2 d-flex flex-column">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="avata_photo">
                    <span className="">Foto (Autor)</span>
                  </label>
                  <div className="d-flex justify-content-center">
                    <div id="avata_photo" className="image-input image-input-circle" data-kt-image-input="true" style={{ backgroundImage: "url(/assets/media/svg/avatars/blank.svg)" }}>
                      <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: "url(/assets/media/avatars/300-20.jpg)" }}></div>
                      <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar">
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input type="file" {...register("avatar")} name="avatar" accept=".png, .jpg, .jpeg" />

                      </label>

                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Remove avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 mb-2 d-flex flex-column">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Imagen (Cuadro)</span>
                  </label>
                  <div className="d-flex justify-content-center w-100">
                    <div className="image-input image-input-empty w-100" data-kt-image-input="true">
                      <div className="image-input-wrapper w-100 h-200px"></div>
                      <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar">
                        <i className="bi bi-pencil-fill fs-7"></i>
                        <input type="file" onChange={(e)=> setValue("imagen",e.target.files ? e.target.files[0] : null)} name="avatar" accept=".png, .jpg, .jpeg" />
                        <input type="hidden" name="avatar_remove" />
                      </label>
                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Remove avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 mb-2 d-flex flex-column">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Layout (Articulo)</span>
                  </label>
                  <div className="d-flex justify-content-center w-100">
                    <div className="image-input image-input-empty w-100" data-kt-image-input="true">
                      <div className="image-input-wrapper w-100 h-200px"></div>
                      <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar">
                        <i className="bi bi-pencil-fill fs-7"></i>
                        <input type="file" onChange={(e)=> setValue("layout",e.target.files ? e.target.files[0] : null)} name="avatar" accept=".png, .jpg, .jpeg" />
                        <input type="hidden" name="avatar_remove" />
                      </label>
                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Remove avatar">
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <label className="fs-6 fw-semibold mb-2" htmlFor="">
                    <span className="">Descripción (Breve)</span>
                  </label>
                  <textarea
                    name="descripcion"
                    className="form-control form-control-solid"
                    aria-label="With textarea"
                    placeholder="Descripción (Max. 272 caracteres)"
                    rows={6}
                    {...register("descripcion", { required: true })}
                  ></textarea>
                  <div className="fv-plugins-message-container invalid-feedback">
                    {errors.descripcion?.type === "required" && errors.descripcion.message}
                  </div>
                </div>
                <div className="col-12 mb-2 ck-content">
                  <CKEditor
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                    onReady={editor => {
                      // console.log('editor',editor)
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setValue("texto", data)
                      // console.log({ event, editor, data });
                    }}
                  // onBlur={(event, editor) => {
                  //   console.log('Blur.', editor);
                  // }}
                  // onFocus={(event, editor) => {
                  //   console.log('Focus.', editor);
                  // }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex gap-2 justify-content-center align-items-center">
                  <button
                    type="reset"
                    id="kt_modal_operador_cancel"
                    className="btn btn-danger me-3"
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <span className="indicator-label">
                        Gaurdando...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">Guardar</span>
                    )}
                  </button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Blog