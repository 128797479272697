/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import moment from "moment/moment";
import JugadasService from "../../../api/services/jugadasService";
import OperadoresService from "../../../api/services/operadoresService";
import ReactApexChart from "react-apexcharts";
const today = new Date();
const tipos = [
    {
        id: "0",
        name: "Individual",
    },
    {
        id: "1",
        name: "Grupal",
    },
];
const GraficosSurebets = () => {
    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/surebets-grafico", name: "Surebets Grafico", active: true },
    ];
    /* -----HOOKS----*/
    const [loading, setLoading] = useState(false);
    const [operadores, setOperadores] = useState([]);
    const [opeSelect, setOpeSelect] = useState("");
    const [filtros, setFiltros] = useState({
        fechainicio: moment(today).format("yyyy-MM-DD"),
        fechafin: moment(today).format("yyyy-MM-DD"),
        idoperador: "",
        tipo: "0",
    });
    const [state, setState] = useState({
        options: {
            chart: {
                type: "bar",
                height: 450,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "35%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: "$",
                    color: "#fff",
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val;
                    },
                },
            },
        },
        series: [],
    });
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");

    const selectData = async () => {
        var datos = []
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respOpe = await OperadoresService.getOperadores(body);
        // console.log("respOpe", respOpe);
        if (respOpe.status) {
            datos = respOpe.data[0]
            datos.unshift({
                nombres: "Todos",
                apellidos: "",
                id_persona: 0
            })
            setOperadores(respOpe.data[0]);
            setOpeSelect("0")
            getData("0");
        }
    };
    const getData = async (idOpe) => {
        // console.log('idOpe', idOpe)
        setLoading(true);
        const body = {
            fechainicio: filtros.fechainicio,
            fechafin: filtros.fechafin,
            idoperador: idOpe ? idOpe : opeSelect,
            tipo: filtros.tipo,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("body", body);
        let arrayCategories = [];
        let arraySeriesE = [];
        let arraySeriesR1 = [];
        let arraySeriesR2 = [];
        let arraySeriesG = [];
        const respApuestas = await JugadasService.getGraficoSurebets(body);
        if (respApuestas.status) {
            // console.log("respApuestas", respApuestas);
            if (respApuestas.data[0] != null) {
                for (let index = 0; index < respApuestas.data[0].length; index++) {
                    const element = respApuestas.data[0][index];
                    arrayCategories.push(element.label);
                    arraySeriesE.push(element.pendiente);
                    arraySeriesR1.push(element.ganado);
                    arraySeriesR2.push(element.perdido);
                    arraySeriesG.push(element.total);
                }
                setState({
                    ...state,
                    options: {
                        xaxis: {
                            categories: arrayCategories,
                        },
                    },
                    series: [
                        {
                            name: "Jugado",
                            data: arraySeriesE,
                        },
                        {
                            name: "R+",
                            data: arraySeriesR1,
                        },
                        {
                            name: "R-",
                            data: arraySeriesR2,
                        },
                        {
                            name: "Ganancia",
                            data: arraySeriesG,
                        },
                    ],
                });
            } else {
                setState({
                    ...state,
                    options: {
                        xaxis: {
                            categories: arrayCategories,
                        },
                    },
                    series: [
                        {
                            name: "Jugado",
                            data: arraySeriesE,
                        },
                        {
                            name: "R+",
                            data: arraySeriesR1,
                        },
                        {
                            name: "R-",
                            data: arraySeriesR2,
                        },
                        {
                            name: "Ganancia",
                            data: arraySeriesG,
                        },
                    ],
                });
            }

        }
        setLoading(false);
    };

    //MOUNTED
    useEffect(() => {
        selectData();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setFiltros({ ...filtros, fechainicio: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_2").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setFiltros({ ...filtros, fechafin: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);
    const filtro = (id) => {
        // console.log('id', id)
    }
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card shadow p-0">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 h-100 justify-content-start">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="" className="form-label">
                                                    Fecha Inicio
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="" className="form-label">
                                                    Fecha Fin
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_2"
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="status" className="form-label">
                                                    Operador
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                    name="status"
                                                    id="status"
                                                    value={opeSelect}
                                                    onChange={(e) => setOpeSelect(e.target.value)}
                                                >
                                                    {operadores.map((operador) => (
                                                        <option key={operador.id_persona} value={operador.id_persona}>
                                                            {operador.nombres} {operador.apellidos}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="status" className="form-label">
                                                    Tipo
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                    name="status"
                                                    id="status"
                                                    onChange={(e) => setFiltros({ ...filtros, tipo: e.target.value })}
                                                >
                                                    {tipos.map((tipo) => (
                                                        <option key={tipo.id} value={tipo.id}>
                                                            {tipo.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-12 col-lg-4 mt-5 mt-lg-0">
                                                <div className="d-flex align-content-end justify-content-lg-start justify-content-center h-100  w-100">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mt-auto"
                                                        onClick={() => getData(opeSelect)}
                                                    >
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-2">
                                <div className="row mx-0">
                                    <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default GraficosSurebets;
