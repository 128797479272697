/* eslint-disable */
import React, { useState, useEffect } from "react";
import Main from "../../../layout/main";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import MUIDataTable from "mui-datatables";
import ActivacionesService from "../../../api/services/activacionesService";
import { FaCheckCircle, FaTimesCircle, FaHistory } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment/moment";

/* DATA ESTATICA */
const today = new Date();
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const Programadas = () => {
    /* -----BREADCRUMBS----*/
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/activaciones-programadas", name: "Activaciones", active: false },
        { path: "/activaciones-programadas", name: "Programadas", active: true },
    ];
    /* -----FIN BREADCRUMBS----*/

    /* -----MUIDATATABLE----*/
    //COLUMNAS
    const columns1 = [
        {
            name: "id_actprogramada",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "responsable",
            label: "Responsable",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
          name: "usuario",
          label: "Usuario",
          options: {
              filter: true,
              sort: true,
              setCellProps: () => ({
                  align: "center",
              }),
              setCellHeaderProps: (value) => ({
                  className: "centeredHeaderCell",
              }),
          },
      },
        {
            name: "plan",
            label: "PAQUETE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "fecha_registro",
            label: "F. REGISTRO",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>;
                },
            },
        },
        {
            name: "fecha_activacion",
            label: "F. ACTIVACIÓN",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value) => {
                    return <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>;
                },
            },
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={`badge badge-square badge-lg p-4 text-uppercase ${value == 2 ? "badge-success" : "badge-danger"}`}>
                            {/* {value == 0 ? <FaTimesCircle></FaTimesCircle> : <FaCheckCircle></FaCheckCircle>} */}
                            <span className="ms-1">{value == 0 ? <><FaTimesCircle></FaTimesCircle> Anulado</> : <><FaCheckCircle></FaCheckCircle> Activo</> }</span>
                        </div>
                    );
                },
            },
        },
        {
            name: "Acciones",
            label: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipP}>
                                <button className="btn btn-warning btn-sm btn-icon" onClick={() => handleMyState(tableMeta)}>
                                    <FaHistory></FaHistory>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    const options = {
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "standard",
        fixedSelectColumn: false,
        download: true,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
    };

    /* -----FIN MUIDATATABLE----*/

    /* -----HOOKS----*/
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [cuentasUsuario, setcuentasUsuario] = useState([]);
    const [filtros, setFiltros] = useState({
        f_inicio: moment(today).format("yyyy-MM-DD"),
        f_fin: moment(today).format("yyyy-MM-DD"),
        tipo: "Todos",
    });
    /* -----FIN HOOKS----*/

    /* -----DATOS----*/
    const refreshToken = localStorage.getItem("refreshToken");
    /* -----FIN DATOS----*/

    /* -----METODOS----*/
    //LISTAR DATA
    const getData = async () => {
        setLoading(true);
        const body = {
            refreshToken: "Bearer " + refreshToken,
            fechainicio: filtros.f_inicio,
            fechafin: filtros.f_fin,
        };
        const resProgramadas = await ActivacionesService.getProgramadas(body);
        if (resProgramadas.status) {
            setItems(resProgramadas.data[0]);
        }
        // console.log("resProgramadas", resProgramadas);
        setLoading(false);
    };
    //CAMBIAR ESTADO
    const handleMyState = (data) => {
        // console.log("data", data);
        let datosMov = {
            idplanprogramado: data.rowData[0],
        };
        // console.log("datosMov", datosMov);
        Swal.fire({
            icon: "warning",
            title: `¿Seguro que desea`+ (data.rowData[6] == 2 ? ` anular` : ``)+` esta activación?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosMov);
            }
        });
    };
    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idplanprogramado: data.idplanprogramado,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await ActivacionesService.anularPlanProgramago(body);
        // console.log("resp", resp);
        if (resp.status) {
            Swal.fire("Eliminado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        getData();
    };
    /* -----FIN METODOS----*/

    /* -----MOUNTED----*/
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, f_inicio: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
        $("#kt_datepicker_2").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                setFiltros({ ...filtros, f_fin: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);
    /* -----FIN MOUNTED----*/
    return (
        <Main>
            <div className="container-xxl mw-100">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                        <div className="me-3 d-flex gap-10 flex-column flex-md-row w-100">
                                            <div className="w-100">
                                                <label htmlFor="" className="form-label">
                                                    Inicio
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                />
                                            </div>
                                            <div className="w-100">
                                                <label htmlFor="" className="form-label">
                                                    Fin
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Fin"
                                                    id="kt_datepicker_2"
                                                />
                                            </div>
                                            <div className="d-flex align-content-end justify-content-lg-end justify-content-center h-100  w-100">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary mt-auto me-3"
                                                    onClick={getData}
                                                >
                                                    {loading ? (
                                                        <span className="indicator-label">
                                                            Buscando...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    ) : (
                                                        <span className="indicator-label ">
                                                            <i className="bi bi-search" /> Buscar
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={<span className="fw-bolder fs-14">Lista de Activaciones Programadas</span>}
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};
export default Programadas;
