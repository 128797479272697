/* eslint-disable */

import UseService from "../UseServices";

const FiltrosService = {
    async getFiltros(data) {
        var resp = await UseService.post("filtros/list", data);
        return resp;
    },
};
export default FiltrosService;