/* eslint-disable */

import UseService from '../UseServices'

const ActivacionesService = {
    async getProgramadas(data) {
        var resp = await UseService.post('activaciones/listprograplan', data)
        return resp
    },
    async getSuspendidas(data) {
        var resp = await UseService.post('activaciones/listsuspenplan', data)
        return resp
    },
    async updatePlanCliente(data) {
        var resp = await UseService.post('activaciones/updateplan', data)
        return resp
    },
    async programarPlan(data) {
        var resp = await UseService.post('activaciones/prograplan', data)
        return resp
    },
    async suspenderPlan(data) {
        var resp = await UseService.post('activaciones/suspenplan', data)
        return resp
    },
    async estadoPlan(data) {
        var resp = await UseService.post('activaciones/estadoplan', data)
        return resp
    },
    async anularPlanProgramago(data) {
        var resp = await UseService.put('activaciones/anularprograplan', data)
        return resp
    },
}
export default ActivacionesService