/* eslint-disable */
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import moment from "moment/moment";
import JugadasService from "../../../api/services/jugadasService";
import OperadoresService from "../../../api/services/operadoresService";
import { useForm } from "react-hook-form";
import { FaRegEdit, FaTrash, FaDonate, FaUndo, FaBan, FaExclamationCircle, FaCheckCircle, FaHistory } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Main from "../../../layout/main";
const today = new Date();
const tooltipEd = (
    <Tooltip id="tooltipEd">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Eliminar</strong>
    </Tooltip>
);
const tooltipG = (
    <Tooltip id="tooltipG">
        <strong>Ganado</strong>
    </Tooltip>
);
const tooltipD = (
    <Tooltip id="tooltipD">
        <strong>Devuelto</strong>
    </Tooltip>
);
const tooltipP = (
    <Tooltip id="tooltipP">
        <strong>Perdido</strong>
    </Tooltip>
);

const tooltipRes = (
    <Tooltip id="tooltipRes">
        <strong>Restablecer</strong>
    </Tooltip>
);
const Apuestas = () => {
    //hooks
    const [filtros, setFiltros] = useState({
        fecha: moment(today).format("yyyy-MM-DD"),
        idoperador: "",
    });
    const [items, setItems] = useState([]);
    const [operadores, setOperadores] = useState([]);
    const [opeSelect, setOpeSelect] = useState("");
    const [loading, setLoading] = useState(true);
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { name: "Jugadas", active: false },
        { path: "/jugadas-apuestas", name: "Apuestas", active: false },
    ];
    //DATOS LOCALSTORAGE
    const refreshToken = localStorage.getItem("refreshToken");
    //TRAER DATA
    const selectData = async () => {
        const body = {
            refreshToken: "Bearer " + refreshToken,
        };
        const respOpe = await OperadoresService.getOperadores(body);
        // console.log("respOpe", respOpe);
        if (respOpe.status) {
            setOperadores(respOpe.data[0]);
            setOpeSelect(respOpe.data[0][0].id_persona)
            getData(respOpe.data[0][0].id_persona)
        }
    };
    const getData = async (idOpe) => {
        setLoading(true);
        // console.log("idOpe", idOpe);
        // console.log("opeSelect", opeSelect);
        const body = {
            fecha: filtros.fecha,
            idoperador: idOpe ? idOpe : opeSelect,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("body", body);
        const respApuestas = await JugadasService.getApuestas(body);
        if (respApuestas.status) {
            // console.log('respApuestas',respApuestas)
            setItems(respApuestas.data[0])
        }
        setLoading(false);
    };

    //MOUNTED
    useEffect(() => {
        selectData();
    }, []);
    useEffect(() => {
        $("#kt_datepicker_1").flatpickr({
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
            onChange: function (selectedDates, dateStr, instance) {
                //console.log("sipe aq");
                setFiltros({ ...filtros, fecha: moment(dateStr).format("yyyy-MM-DD") });
            },
            defaultDate: moment(today).format("yyyy-MM-DD"),
        });
    }, []);

    //METODOS
    const estadoFinal = async (id, estado) => {
        const body = {
            idapuesta: id,
            estado: estado,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const resp = await JugadasService.stateFinal(body);
        //console.log("resp estado", resp);
        if (resp.status) {
            toast.success("Apuesta actualizado", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "B",
            });
            getData();
        } else {
            toast.error("rror al actualizar la apuesta", {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "B",
            });
        }
        setShow(true);
    };
    //ELIMINAR
    const handleMyDelete = (data) => {
        Swal.fire({
            icon: "error",
            title: "¿Seguro que desea eliminar la apuesta seleccionada?",
            showCancelButton: true,
            confirmButtonText: "Eliminar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmDelete = async (data) => {
        //console.log("daaataaa", data);
        const body = {
            idapuesta: data.id_apuesta,
            refreshToken: "Bearer " + refreshToken,
        };
        const resp = await JugadasService.deleteBet(body);
        if (resp.status) {
            Swal.fire({
                title: "Eliminado",
                icon: "success",
                text: "La apuesta ha sido eliminada con éxito.",
            });
            getData();
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Hubo un error al eliminar la apuesta.",
            });
        }
    };
    return (
        <Main>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card">
                            <div className="card-header border-0 p-6">
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 h-100 justify-content-start">
                                        <div className="row mx-0 w-100">
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="" className="form-label">
                                                    Fecha
                                                </label>
                                                <input
                                                    className="form-control form-control-solid"
                                                    placeholder="Fecha Inicio"
                                                    id="kt_datepicker_1"
                                                />
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <label htmlFor="status" className="form-label">
                                                    Operador
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid "
                                                    name="status"
                                                    id="status"
                                                    value={opeSelect}
                                                    onChange={(e) => setOpeSelect(e.target.value)}
                                                >
                                                    {operadores.map((operador) => (
                                                        <option key={operador.id_persona} value={operador.id_persona}>
                                                            {operador.nombres} {operador.apellidos}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
                                                <div className="d-flex align-content-end justify-content-lg-start justify-content-center h-100  w-100">
                                                    <button type="button" className="btn btn-primary mt-auto" onClick={()=>getData(filtros.idoperador)}>
                                                        {loading ? (
                                                            <span className="indicator-label">
                                                                Buscando...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <i className="bi bi-search" /> Buscar
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-column flex-md-row ">
                                    <div className="accordion accordion-icon-toggle w-100" id="kt_accordion_2">
                                        {items.map((bet, index) => {
                                            return (
                                                <div className="card_acordion mb-5 w-100 border-1" key={index}>
                                                    <div
                                                        className={`accordion-header content py-3 d-flex px-3 border-2 border border-active active ${
                                                            bet.estado == 1
                                                                ? "bg-light-success  border-success"
                                                                : bet.estado == 3
                                                                ? "bg-light-warning border-warning"
                                                                : bet.estado == 0
                                                                ? "bg-light-danger border-danger"
                                                                : ""
                                                        }`}
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={"#kt_accordion_2_item_" + index}
                                                    >
                                                        {/* Header del acordion */}
                                                        <div className="d-flex gap-2">
                                                            <span className="">{bet.deporte}</span> /
                                                            <span className="">{bet.evento}</span> /
                                                            <span className="">{bet.fecha_evento}</span>
                                                        </div>
                                                    </div>
                                                    {/* Body del acordion */}
                                                    <div
                                                        id={"kt_accordion_2_item_" + index}
                                                        className="fs-6 collapse px-6 py-2"
                                                        data-bs-parent="#kt_accordion_2"
                                                    >
                                                        <div className="d-flex flex-column ">
                                                            <div
                                                                className={`row justify-content-md-center mt-2 text-center ${
                                                                    bet.estado == 1
                                                                        ? "bg-light-success "
                                                                        : bet.estado == 3
                                                                        ? "bg-light-warning"
                                                                        : bet.estado == 0
                                                                        ? "bg-light-danger"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <div className="col-6 col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Casa</div>
                                                                    <div className="column_content p-4">{bet.casa}</div>
                                                                </div>
                                                                {/* <div className="col-6  col-xl-4 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                        <div className="column_head p-2 text-uppercase fw-semibold">Estado</div>
                                                        <div className="column_content p-4 d-flex justify-content-evenly">
                                                            <div className="form-check form-check-custom form-check-success form-check-solid">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value={1}
                                                                    defaultChecked={bet.winner == 1}
                                                                    onClick={() => estadoCasa(bet.id_apuesta, 1)}
                                                                    id="flexCheckboxLg"
                                                                    name={"radioEstado" + index}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckboxLg">
                                                                    Ganador
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-custom form-check-danger form-check-solid">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value={0}
                                                                    defaultChecked={bet.winner == 0}
                                                                    id="flexCheckboxSm"
                                                                    name={"radioEstado" + index}
                                                                    onClick={() => estadoCasa(bet.id_apuesta, 0)}
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckboxSm">
                                                                    Perdida
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                                <div className="col-6  col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Apuesta
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.mercado}</div>
                                                                </div>
                                                                <div className="col-6   col-xl-4 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Cuotas</div>
                                                                    <div className="column_content p-4">{bet.cuota}</div>
                                                                </div>
                                                                <div className="col-6  col-xl-6 col-xxl-2 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">Stake</div>
                                                                    <div className="column_content p-4">{bet.stake}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-3 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Ganancia
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.yield}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Observación
                                                                    </div>
                                                                    <div className="column_content p-4">{bet.observacion}</div>
                                                                </div>
                                                                <div className="col-12 col-xl-6 col-xxl-6 p-0 d-flex flex-column border border-active-info">
                                                                    <div className="column_head p-2 text-uppercase fw-semibold">
                                                                        Acciones
                                                                    </div>
                                                                    <div className="column_content p-4 gap-5 d-flex flex-column flex-xl-row justify-content-center justify-content-xxl-evenly">
                                                                        {bet.estado != 2 ? (
                                                                            <div className="d-flex justify-content-center">
                                                                                <OverlayTrigger placement="top" overlay={tooltipRes}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        title="Restablecer"
                                                                                        onClick={() => estadoFinal(bet.id_apuesta, 2)}
                                                                                    >
                                                                                        <FaHistory />
                                                                                    </button>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {/* <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipEd}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Editar"
                                                                                            onClick={() => editApuesta(bet)}
                                                                                        >
                                                                                            <FaRegEdit />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipE}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Eliminar"
                                                                                            onClick={() => handleMyDelete(bet)}
                                                                                        >
                                                                                            <FaTrash />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div> */}
                                                                                <div className="d-flex gap-2 gap-xxl-10 justify-content-center">
                                                                                    <OverlayTrigger placement="top" overlay={tooltipG}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-success btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Ganado"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 1)}
                                                                                        >
                                                                                            <FaDonate />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipD}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-warning btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Devuelto"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 3)}
                                                                                        >
                                                                                            <FaUndo />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                    <OverlayTrigger placement="top" overlay={tooltipP}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger btn-sm"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-bs-placement="top"
                                                                                            title="Perdido"
                                                                                            onClick={() => estadoFinal(bet.id_apuesta, 0)}
                                                                                        >
                                                                                            <FaBan />
                                                                                        </button>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ToastContainer
                            theme={localStorage.getItem("userTheme") === "dark" ? "dark" : "light"}
                            className="container_bets "
                            enableMultiContainer
                            containerId={"B"}
                            autoClose={1000}
                            position={toast.POSITION.BOTTOM_RIGHT}
                        />
                        
                    </div>
                    {loading ? (
                        <div className="overlay-custom card-rounded bg-black bg-opacity-50">
                            <div className="d-flex flex-column align-items-center mt-10">
                                <div className="spinner-border text-white" role="status"></div>
                                <span>Buscando datos...</span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Apuestas;
