/* eslint-disable */

import UseService from "../UseServices";

const UsersService = {
    async getClientes(data) {
        var resp = await UseService.post("client/list", data);
        return resp;
    },
    async getDetallesPago(data) {
        var resp = await UseService.post("client/detailspay", data);
        return resp;
    },
    async updateInfo(data) {
        var resp = await UseService.post("client/updateinfo", data);
        return resp;
    }
};
export default UsersService;