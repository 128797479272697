/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import MUIDataTable from "mui-datatables";
import Breadcrumbs from "../../../Components/utils/BreadCrumb";
import CuentasService from "../../../api/services/cuentasServices";
import ConstantesService from "../../../api/services/constantesService";
import { useForm, Controller } from "react-hook-form";
import { FaPencilAlt, FaSync } from "react-icons/fa";
import Select from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Main from "../../../layout/main";
import userEvent from "@testing-library/user-event";
import { useUserContext } from "../../../Providers/UserProvider";
//data estatica
const estados = [
    {
        text: "Todos",
        value: "2",
    },
    {
        text: "Activo",
        value: "1",
    },
    { text: "Inactivo", value: "0" },
];

//botones actions (buscar mejor forma)
const tooltipE = (
    <Tooltip id="tooltipE">
        <strong>Editar</strong>
    </Tooltip>
);
const tooltipC = (
    <Tooltip id="tooltipC">
        <strong>Cambiar Estado</strong>
    </Tooltip>
);
const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
        border: "0px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                ? localStorage.getItem("userTheme") === "dark"
                    ? "#2B2B3D"
                    : "#D0D2D7"
                : isFocused
                ? localStorage.getItem("userTheme") === "dark"
                    ? "#2B2B3D"
                    : "#F4F6FA"
                : localStorage.getItem("userTheme") === "dark"
                ? "#1b1b29"
                : "#f9f9f9",
            color: isSelected
                ? localStorage.getItem("userTheme") === "dark"
                    ? "white"
                    : "black"
                : localStorage.getItem("userTheme") === "dark"
                ? "white"
                : "black",
            fontWeight: "500",
            cursor: "pointer",
        };
    },
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
        ...styles,
        color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
        fontWeight: "500",
    }),
    input: (styles) => ({
        ...styles,
        color: localStorage.getItem("userTheme") === "dark" ? "white" : "black",
        fontWeight: "500",
    }),
    valueContainer: (styles) => ({ ...styles, padding: "0.85rem" }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: localStorage.getItem("userTheme") === "dark" ? "#1b1b29" : "#f9f9f9",
    }),
};

const defaultStyles = {
    control: (base, state) => ({
        ...base,
    }),
    menu: (base) => ({
        ...base,
    }),
    menuList: (base) => ({
        ...base,
        minHeight: "100px", // your desired height
    }),
};
const Bancarias = () => {
    //USE FORM
    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm();

    //COLUMNAS
    const columns1 = [
        {
            name: "id_casacuenta",
            label: "",
            options: {
                display: false,
            },
        },
        {
            name: "nom_titular",
            label: "CUENTA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "banco",
            label: "banco",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "casaapuesta",
            label: "CASA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
        {
            name: "tipo",
            label: "MONEDA",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span>
                            {value == 1 ? "DOLARES" : "Moneda local"}
                        </span>
                    );
                },
            },
        },
        {
            name: "fecha_registro",
            label: "F. REGISTRO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span className="text-uppercase">{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>;
                },
            },
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span
                            className={`badge badge-square badge-lg p-4 text-uppercase ${
                                value == 0 ? "badge-danger" : value == 1 ? "badge-success" : "badge-warning"
                            } `}
                        >
                            {value == 0 ? "Desactivado" : value == 1 ? "Activo" : "En Alerta"}
                        </span>
                    );
                },
            },
        },
        {
            name: "ACCIONES",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="d-flex gap-3 flex-wrap justify-content-center">
                            <OverlayTrigger placement="top" overlay={tooltipE}>
                                <button className="btn btn-success btn-icon btn-sm" onClick={() => editCuenta(tableMeta)}>
                                    <FaPencilAlt></FaPencilAlt>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={tooltipC}>
                                <button className="btn btn-danger btn-icon btn-sm" onClick={() => handleMyState(tableMeta)}>
                                    <FaSync></FaSync>
                                </button>
                            </OverlayTrigger>
                        </div>
                    );
                },
                setCellProps: () => ({
                    align: "center",
                }),
                setCellHeaderProps: (value) => ({
                    className: "centeredHeaderCell",
                }),
            },
        },
    ];
    //BreadCrumb
    const crumbs = [
        { path: "/perfil", name: "Home", active: true },
        { path: "/cuentas", name: "Cuentas", active: false },
        { path: "/casas", name: "Casas", active: false },
    ];
    //Configurando hooks
    const { user } = useUserContext();
    const [bancos, setBancos] = useState([]);
    const [estadoSelect, setEstadoSelect] = useState("2");
    const [items, setItems] = useState([]);
    const [casasCuentas, setCasasCuentas] = useState([]);
    const [casaSelect, setCasaSelect] = useState("0");
    const [casasList, setcasasList] = useState([]);
    const [cuentasBancarias, setCuentasBancarias] = useState([]);
    const [isOpe, setIsOpe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    //DATOS
    const refreshToken = localStorage.getItem("refreshToken");
    const body = {
        refreshToken: "Bearer " + refreshToken,
    };
    const bodyCasas = {
        idagrupador: 2,
        refreshToken: "Bearer " + refreshToken,
    };
    //TRAER DATA
    const dataSelect = async () => {
        const casasResp = await ConstantesService.getConstXAgrupadores(bodyCasas);
        const respCBancarias = await CuentasService.selectCuentasBancarias({
            refreshToken: "Bearer " + refreshToken,
        });
        if (casasResp.status) {
            setcasasList(casasResp.data[0]);
        }
        // console.log("respCBancarias", respCBancarias);
        if (respCBancarias.status) {
            setCuentasBancarias(respCBancarias.data[0]);
            // setValue("cuentabancaria", {
            //     label: respCBancarias.data[0].cuenta,
            //     value: respCBancarias.data[0].id_cuentabancarias,
            // });
        }
    };
    const showData = async () => {
        setLoading(true);
        const resp = await CuentasService.getCasasCuentas(body);
        // console.log("resp", resp);
        if (resp.status && resp.data[0] != null) {
            if (resp.data[0].length > 0) {
                setItems(resp.data[0]);
                setCasasCuentas(resp.data[0]);
            } else {
                setItems([]);
                setCasasCuentas([]);
            }
        }
        setLoading(false);
    };
    const FilterData = () => {
        let estado = parseInt(estadoSelect);
        let filter = [];
        if (casaSelect == "0") {
            if (estado == 2) {
                filter = casasCuentas;
                setItems(filter);
            } else {
                filter = casasCuentas.filter((e) => e.estado == estado);
                setItems(filter);
            }
        } else {
            if (estado == 2) {
                filter = casasCuentas.filter((e) => e.casaapuesta == casaSelect);
                setItems(filter);
            } else {
                filter = casasCuentas.filter((e) => e.casaapuesta == casaSelect && e.estado == estado);
                setItems(filter);
            }
        }
    };

    //MOUNTED
    useEffect(() => {
        dataSelect();
        showData();
    }, []);

    //OPTIONS DEL DATATABLE
    const options = {
        print: false,
        viewColumns: false,
        selectableRows: "none",
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedSelectColumn: false,
        textLabels: {
            body: {
                noMatch: "No se encontraron datos",
                toolTip: "Sort",
                columnHeaderTooltip: (column) => `Ordenador por ${column.label}`,
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Elementos por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Buscador",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Resetear",
            },
            viewColumns: {
                title: "Mostrar Columnas",
                titleAria: "Mostrar/Ocultar Columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada",
            },
            centeredTableHead: {
                "& > span": {
                    justifyContent: "center",
                },
            },
        },
        // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        //     <div>
        //         <FaTrash className="mx-4 cursor-pointer" onClick={() => handleMyDelete(selectedRows)} />
        //     </div>
        // ),
    };
    //ELIMINAR
    const handleMyState = (data) => {
        //console.log("data", data);
        let datosCC = {
            id: data.rowData[0],
            estado: data.rowData[4],
        };
        Swal.fire({
            icon: "error",
            title: `¿Seguro que desea ${data.rowData[5] == 1 ? "desactivar" : "activar"} la cuenta seleccionada?`,
            showCancelButton: true,
            confirmButtonText: "Confirmar",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmChange(datosCC);
            }
        });
    };

    //CONFIRMACIÓN DE ELIMINACIÓN
    const confirmChange = async (data) => {
        const body = {
            idcasacuenta: data.id,
            estado: data.estado == 1 ? 0 : 1,
            refreshToken: "Bearer " + refreshToken,
        };
        //console.log("body", body);
        const respEstado = await CuentasService.statusCasasCuentas(body);
        // console.log("respEstado", respEstado);
        if (respEstado.status) {
            Swal.fire("Actualizado!", "", "success");
        } else {
            Swal.fire({
                title: "Error!",
                icon: "error",
                text: "Ocurrió un error al actualizar los datos.",
            });
        }
        showData();
    };

    //EDITAR CUENTA
    const editCuenta = (data) => {
        let itemSelectE = {};
        itemSelectE = items[data.rowIndex];
        // console.log("itemSelectE", itemSelectE);
        setIsOpe(false);
        setValue("cuentabancaria",itemSelectE.id_cuentabancaria);
        setValue("id_casacuenta", itemSelectE.id_casacuenta);
        setValue("tipo",itemSelectE.tipo);
        let casaApuesta = casasList.filter((key) => key.nombre === itemSelectE.casaapuesta);
        if (casaApuesta.length != 0) {
            setValue("casas", casaApuesta[0].id_constante);
        }

        $("#kt_modal_add_customer").modal("show");
    };

    //AGREGAR CUENTA
    const addCuenta = () => {
        setIsOpe(true);
        let idcasa = casasList.filter((key) => key.nombre === "BET365");
        setValue("casas", "");
        setValue("cuentabancaria", "");
        // setValue("cuentabancaria", {
        //     label: "",
        //     value: "",
        // });
        $("#kt_modal_add_customer").modal("show");
    };

    //SETEAR BANCO Y CASAS SELECCIONADOS
    const selectType = (e) => {
        setEstadoSelect(e);
    };
    const selectTypeC = (e) => {
        setCasaSelect(e);
    };
    const resetForm = () => {
        reset({
            cuentabancaria: {
                label: "",
                value: "",
            },
            casas: "",
        });
    };
    //ENVIAR DATA
    const onSubmit = async (data) => {
        setLoading2(true);
        // console.log('DATA',data);
        const bodyAdd = {
            idcuentabancaria: parseInt(data.cuentabancaria),
            idcasaapuesta: parseInt(data.casas),
            refreshToken: "Bearer " + refreshToken,
        };
        const bodyUpd = {
            idcasacuenta: parseInt(data.id_casacuenta),
            idcuentabancaria: parseInt(data.cuentabancaria),
            idcasaapuesta: parseInt(data.casas),
            tipo: parseInt(data.tipo),
            tcambio: user.tipocambio,
            refreshToken: "Bearer " + refreshToken,
        };
        // console.log("bodyAdd", bodyAdd);
        if (isOpe) {
            const respAdd = await CuentasService.addCasasCuentas(bodyAdd);
            // console.log("respAdd", respAdd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Registrado",
                    icon: "success",
                    text: "Datos registrados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al registrar los datos.",
                });
            }
        } else {
            const respAdd = await CuentasService.updateCasasCuentas(bodyUpd);
            if (respAdd.status) {
                Swal.fire({
                    title: "Actualizado!",
                    icon: "success",
                    text: "Datos actualizados con éxito.",
                });
            } else {
                Swal.fire({
                    title: "Error!",
                    icon: "error",
                    text: "Ocurrió un error al actualizar los datos.",
                });
            }
        }
        setLoading2(false);
        showData();
        // resetForm();
        $("#kt_modal_add_customer").modal("hide");
    };

    return (
        <Main>
            <div className="container-xxl mw-100 ">
                <div className="toolbar" id="kt_toolbar">
                    <div className="w-100 px-2 d-flex flex-stack flex-wrap flex-sm-nowrap">
                        <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
                    </div>
                </div>
                <div className={`overlay card-rounded bg-active-dark ${loading ? "overlay-block" : ""}`}>
                    <div className="overlay-wrapper">
                        <div className="card p-0 shadow">
                            <div className="card-header border-0 pt-6">
                                <div className="card-title">
                                    <div className="d-flex align-items-center position-relative my-1"></div>
                                </div>
                                <div className="card-toolbar w-100">
                                    <div className="d-flex flex-column flex-lg-row  w-100 justify-content-between h-100">
                                        <div className="me-3 d-flex gap-10 flex-column flex-md-row w-100">
                                            <div className=" w-100">
                                                <label htmlFor="casas-estado" className="form-label">
                                                    Casa
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid w-100"
                                                    name="casas-estados"
                                                    id="casas-estado"
                                                    onChange={(e) => selectTypeC(e.target.value)}
                                                    value={casaSelect}
                                                >
                                                    <option value="0">Todos</option>
                                                    {casasList.map((estado) => (
                                                        <option key={estado.id_constante} value={estado.nombre}>
                                                            {estado.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className=" w-100">
                                                <label htmlFor="casas-estado" className="form-label">
                                                    Estado
                                                </label>
                                                <select
                                                    className="select2-selection select2-selection--single form-select form-select-solid w-100"
                                                    name="estados"
                                                    id="casas-estado"
                                                    onChange={(e) => selectType(e.target.value)}
                                                    value={estadoSelect}
                                                >
                                                    {/* <option value="">Seleccionar</option> */}
                                                    {estados.map((estado) => (
                                                        <option key={estado.value} value={estado.value}>
                                                            {estado.text}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="d-flex align-content-end justify-content-lg-start justify-content-center h-100  w-100">
                                                <button type="button" className="btn btn-primary mt-auto" onClick={FilterData}>
                                                    Filtrar
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex align-content-end justify-content-center mt-10 mt-lg-0 justify-content-lg-end h-100 w-100 gap-3">
                                            <button type="button" className="btn btn-primary mt-auto" onClick={showData}>
                                                <i className="bi bi-search" /> Buscar
                                            </button>
                                            <button type="button" className="btn btn-primary mt-auto" onClick={addCuenta}>
                                                <i className="bi bi-plus-circle fs-4 me-2" /> Casa Cuenta
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                                        <div className="fw-bold me-5">
                                            <span className="me-2" data-kt-customer-table-select="selected_count"></span>
                                            Selected
                                        </div>
                                        <button type="button" className="btn btn-danger" data-kt-customer-table-select="delete_selected">
                                            Delete Selected
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <MUIDataTable
                                    title={<span className="fw-bolder fs-14">Lista de Casas Cuentas</span>}
                                    data={items}
                                    columns={columns1}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className="modal fade" id="kt_modal_add_customer" tabIndex="-1" aria-modal="true" role="dialog">
                            <div className="modal-dialog modal-dialog-centered mw-650px">
                                <div className="modal-content">
                                    <form
                                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                                        id="kt_modal_add_customer_form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        <div className="modal-header" id="kt_modal_add_customer_header">
                                            <h2 className="fw-bold">{isOpe ? "Agregar" : "Editar"} Cuenta de Casa</h2>
                                            <div
                                                id="kt_modal_add_customer_close"
                                                className="btn btn-icon btn-sm btn-active-icon-primary"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <rect
                                                            opacity="0.5"
                                                            x="6"
                                                            y="17.3137"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(-45 6 17.3137)"
                                                            fill="currentColor"
                                                        ></rect>
                                                        <rect
                                                            x="7.41422"
                                                            y="6"
                                                            width="16"
                                                            height="2"
                                                            rx="1"
                                                            transform="rotate(45 7.41422 6)"
                                                            fill="currentColor"
                                                        ></rect>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="modal-body py-10 px-lg-17">
                                            <div
                                                id="kt_modal_add_customer_scroll"
                                                data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                                                data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                                            >
                                                <div className="row mb-7 fv-plugins-icon-container">
                                                    {isOpe ? <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Cuenta Bancaria</span>
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="casas"
                                                            {...register("cuentabancaria", {
                                                                required: true,
                                                            })}
                                                        >
                                                            <option value="">Seleccionar</option>
                                                            {cuentasBancarias.map((cuenta) => (
                                                                <option key={cuenta.id_cuentabancarias} value={cuenta.id_cuentabancarias}>
                                                                    {cuenta.cuenta}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.cuentabancaria?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>: <></>}
                                                    <div className="col-12">
                                                        <label className="fs-6 fw-semibold my-2" htmlFor="">
                                                            <span className="">Casa</span>
                                                        </label>
                                                        <select
                                                            className="select2-selection select2-selection--single form-select form-select-solid"
                                                            name="casas"
                                                            {...register("casas", {
                                                                required: true,
                                                            })}
                                                        >
                                                            <option value="">Seleccionar</option>
                                                            {casasList.map((estado) => (
                                                                <option key={estado.id_constante} value={estado.id_constante}>
                                                                    {estado.nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.casas?.type === "required" && (
                                                            <p className="text-danger">El campo es requerido</p>
                                                        )}
                                                    </div>
                                                    {/* <Controller
                                                        name="cuentabancaria"
                                                        rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={cuentasBancarias.map((cu) => ({
                                                                    label: cu.cuenta,
                                                                    value: cu.id_cuentabancarias,
                                                                }))}
                                                                styles={
                                                                    localStorage.getItem("userTheme") === "dark"
                                                                        ? colourStyles
                                                                        : defaultStyles
                                                                }
                                                                maxMenuHeight={125}
                                                                placeholder={"Seleccionar"}
                                                                noOptionsMessage={() => "Sin resultados"}
                                                            />
                                                        )}
                                                        control={control}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            <button
                                                type="reset"
                                                id="kt_modal_add_customer_cancel"
                                                className="btn btn-danger me-3"
                                                data-bs-dismiss="modal"
                                                onClick={resetForm}
                                            >
                                                Cerrar
                                            </button>
                                            <button type="submit" id="kt_modal_add_customer_submit" className="btn btn-primary">
                                                {loading2 ? (
                                                    <span className="indicator-label">
                                                        Cargando...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                ) : (
                                                    <span className="indicator-label">{isOpe ? "Agregar" : "Editar"}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="overlay-layer card-rounded bg-black bg-opacity-50">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </Main>
    );
};

export default Bancarias;
